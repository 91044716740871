// A list of all components required by this app that can be rendered from the DOM
import { Footer, Breadcrumbs } from '@lux/components';
import BusinessHeader from './components/BusinessHeader';
import FeaturedBusinessDeals from './components/FeaturedBusinessDeals';
import PricePlanCalculator from './components/PricePlanCalculator';
import FlashDeals from './components/FlashDeals';
import RelatedProductList from './components/RelatedProductList';
import RelatedProductsWidget from './components/RelatedProductsWidget';
import TestimonialWidget from './components/TestimonialWidget';
import SolutionsCategories from './components/SolutionsCategories';
import IDDWidget from './components/IDDWidget';
import ArticleDetailBanner from './components/ArticleDetailBanner';
import ArticleList from './components/ArticleList';
import RoamingRatesSearchTool from './components/RoamingRatesSearchTool';
import GlobalMaps from './components/GlobalMaps';
import GECheckUsageWidget from './components/GECheckUsageWidget';
import RecommendedProducts from './components/RecommendedProducts';
import FilterSettings from './components/FilterSettings';
import SpringBoardIframe from './components/SpringBoardIframe';
import SpringBoardButton from './components/SpringBoardButton';
import SpringBoardModal from './components/SpringBoardModal';
import ProfilingFilter from './components/ProfilingFilter';
import SpringboardHeadingText from './components/SpringboardHeadingText';
import SpringboardAudioPlayer from './components/SpringboardAudioPlayer';

export default {
  IDDWidget: IDDWidget,
  FlashDeals: FlashDeals,
  PricePlanCalculator: PricePlanCalculator,
  FooterLinks: Footer,
  FooterSingtel: Footer,
  BusinessHeader: BusinessHeader,
  FeaturedBusinessDeals: FeaturedBusinessDeals,
  RelatedProductList: RelatedProductList,
  RelatedProductsWidget: RelatedProductsWidget,
  SolutionsCategories: SolutionsCategories,
  TestimonialWidget: TestimonialWidget,
  Breadcrumbs: Breadcrumbs,
  ArticleDetailBanner: ArticleDetailBanner,
  ArticleList: ArticleList,
  RoamingRatesSearchTool: RoamingRatesSearchTool,
  GlobalMaps: GlobalMaps,
  GECheckUsageWidget: GECheckUsageWidget,
  RecommendedProducts: RecommendedProducts,
  FilterSettings: FilterSettings,
  SpringBoardIframe: SpringBoardIframe,
  SpringBoardButton: SpringBoardButton,
  SpringBoardModal: SpringBoardModal,
  ProfilingFilter: ProfilingFilter,
  SpringboardHeadingText,
  SpringboardAudioPlayer
};
