import React, { Component } from 'react';
import {
  Column,
  Row
} from '@lux/components';

import SC from './styles';
import PropTypes from 'prop-types';

class IDDCard extends Component {

  render() {
    const { serviceName, availableRates } = this.props;

    return (
      <SC.Card__IDD innerRef={node => (this.cardRef = node)}>
        <Row>
          <Column md={12}>
            <SC.CardHeader__Wrapper>
              <SC.CardHeader__IDD>{serviceName}</SC.CardHeader__IDD>
            </SC.CardHeader__Wrapper>
          </Column>
        </Row>
        <Row>
          {
            availableRates.map((rate, index) => {
              return (
                <SC.CardBody__IDD key={index} numCols={availableRates.length} >
                  <SC.RateTier>{rate.rateTier}</SC.RateTier>
                  <SC.RatePrice>{rate.ratePrice}</SC.RatePrice>
                  <SC.Rate>{rate.rate}</SC.Rate>
                  {
                    rate.ratePeriod ? <SC.RatePeriodLabel>Time Period: </SC.RatePeriodLabel> : ""
                  }
                  {
                    rate.ratePeriod
                    ?
                      rate.ratePeriod.split(",").map((ratePeriod, index) => {
                        return (
                          <SC.TimePeriod key={index}>{ratePeriod.trim()}</SC.TimePeriod>
                        )
                      })
                    :
                    <React.Fragment/>
                  }
                </SC.CardBody__IDD>
              );
            })
          }
        </Row>
      </SC.Card__IDD>
    );
  }
}

IDDCard.propTypes = {
  serviceName: PropTypes.string,
  availableRates: PropTypes.array,
};

export default IDDCard;
