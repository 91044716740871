import React, { Component } from 'react';
import { Button, Row, Column, Grid, Anchor } from '@lux/components';
import Modal from '../SpringBoardModal';
import PropTypes from 'prop-types';

class SpringBoardButton extends Component {
  constructor(props) {
    super(props);
    this.state = { openModal: false };
  }

  buttonClick = () => {
    const { buttonActionType, targetUrl } = this.props;
    switch (buttonActionType) {
      case 'redirect':
        window.location.href = targetUrl;
        break;
      case '_blank':
        window.open(targetUrl);
        break;
      case 'openPModal':
        this.setState({ openModal: true });
        break;
    }
  };

  render() {
    const {
      buttonLabel,
      buttonType,
      modalTitle,
      modalInnerComponent,
      targetUrl
    } = this.props;
    return (
      <Grid>
        <Row center="sm">
          <Column sm={12} md={12} lg={12}>
            {buttonType === 'link' ? (
              <Anchor ariaLabel={targetUrl} onClick={this.buttonClick.bind()}>{buttonLabel} </Anchor>
            ) : (
              <Button
                ariaLabel={targetUrl}
                primary={buttonType === 'primary' || !buttonType}
                secondary={buttonType === 'secondary'}
                onClick={this.buttonClick.bind()}
              >
              {buttonLabel}
              </Button>

            )}

            <Modal
              open={this.state.openModal}
              onClose={() => {
                this.setState({ openModal: false });
              }}
              modalTitle={modalTitle}
              modalInnerComponent={modalInnerComponent}
            />
          </Column>
        </Row>
      </Grid>
    );
  }
}

SpringBoardButton.propTypes = {
  /**button label */
  buttonLabel: PropTypes.string,
  /**button type */
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'link']),
  /**indicate the target url if action type is redirect or _blank */
  targetUrl: PropTypes.string,
  /**button actions */
  buttonActionType: PropTypes.oneOf(['redirect', '_blank', 'openPModal'])
    .isRequired,
  /**modal title */
  modalTitle: PropTypes.string,
  /**PModal inner component */
  modalInnerComponent: PropTypes.arrayOf(
    PropTypes.shape({
      /**component name */
      component: PropTypes.string,
      /**component props */
      props: PropTypes.object
    })
  )
};

export default SpringBoardButton;
