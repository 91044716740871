import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Equalizer from "react-equalizer";

import { isNarrowViewport, noop } from "@lux/components/src/helpers";
import ArticleCard from "../ArticleCard";
import { Grid, Row, Column, DropDown } from "@lux/components";
import { getUrlParams } from "@lux/helpers";
import SC from "./styles";

export const MSG = {
  WENTWRONG:
    "Sorry we’re experiencing an error with the results. Please try again later.",
  NOTMATCH: "Sorry, no article found.",
};
const sortOptions = (optionsArr, excludeSort) => {
  return optionsArr
    .filter((e) => e.text === excludeSort)
    .concat(
      optionsArr
        .filter((e) => e.text !== excludeSort)
        .sort((a, b) => a.text.localeCompare(b.text))
    );
};
const findArticles = (articleArr, filterProp) => {
  return articleArr.filter((articleData) => {
    if (articleData.categories != null) {
      return (
        articleData.categories
          .map((category) => category.toLowerCase())
          .indexOf(filterProp) > -1
      );
    }
  });
};
class ArticleList extends Component {
  refCards = [];

  state = {
    showQuantity: this.props.showQuantity,
    collection: [],
    filters: { primary: "", secondary: "" },
    filteredCollection: [],
    sizeCollection: [],
  };

  componentDidMount() {
    const { articles, enableArticleApi, categoryParameter } = this.props;

    if (enableArticleApi) {
      const hasParams = getUrlParams();
      const category =
        hasParams && hasParams[categoryParameter]
          ? hasParams[categoryParameter]
          : "";
      this.fetchArticles(category);
    } else {
      this.setState(
        {
          collection: articles.sort((a, b) => {
            return Date.parse(b.publishedDate) - Date.parse(a.publishedDate);
          }),
        },
        this.filterCollection
      );
    }
  }

  /**
   * Fetch articles based on query string
   */
  fetchArticles = (category) => {
    const { allArticlesApi } = this.props,
      qString = window.btoa("category=" + category),
      apiParams = `${allArticlesApi}.${qString}.articles.json`;
    return fetch(apiParams, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(MSG.WENTWRONG);
        }
      })
      .then((allArticles) => {
        //We have products
        if (allArticles.length > 0) {
          this.setState(
            {
              collection: this.sortFeatured(allArticles),
            },
            this.filterCollection
          );
        } else {
          //Response is empty
          throw new Error(MSG.NOTMATCH);
        }
      })
      .catch((err) => {
        this.setState({
          error: {
            msg: err.message,
            show: true,
          },
        });
      });
  };

  sortFeatured(allArticles) {
    let sortedArticles = [];
    let sortedData = allArticles.sort((a, b) => {
      return Date.parse(b.publishedDate) - Date.parse(a.publishedDate);
    });
    const hasFeatured = sortedData.filter((item) => item.isFeatured);
    const allProducts = sortedData.filter((item) => !item.isFeatured);

    if (hasFeatured && hasFeatured.length) {
      sortedArticles = hasFeatured.concat(allProducts);
    } else {
      sortedArticles = sortedData;
    }

    return sortedArticles;
  }

  filterCollection() {
    let { filters, collection } = this.state;
    let updateCollection =
      filters["primary"] !== ""
        ? findArticles(collection, filters["primary"])
        : collection;

    if (filters.secondary !== "") {
      updateCollection = findArticles(updateCollection, filters["secondary"]);
    }
    this.setState({
      filteredCollection: updateCollection,
    });
  }

  handleFilterChange(fieldName, key, item) {
    this.setState(
      {
        filters: { ...this.state.filters, [key]: item.value.toLowerCase() },
        showQuantity: this.props.showQuantity,
      },
      this.filterCollection
    );
  }

  handleSortChange(item) {
    this.props.onSort(item.value);
  }

  handleShowMore(e) {
    e.preventDefault();
    this.setState({
      showQuantity: this.state.showQuantity + this.props.showQuantityIncrement,
    });
  }

  // Used by Equalizer to determine which child components to equalise
  // ArticleCard has a refTitle ref that we want to equalise height
  getCardTitleNodes() {
    return this.refCards.filter((card) => card).map((card) => card.refTitle);
  }

  getCardDescriptionNodes() {
    return this.refCards
      .filter((card) => card)
      .map((card) => card.refDescription);
  }

  enableEqualizer() {
    return !isNarrowViewport();
  }

  render() {
    const {
      className,
      ctaType,
      filterCriteriaText,
      filterLists,
      sortList,
      sortByText,
      viewMoreText,
      showFilter,
    } = this.props;
    const { filteredCollection, error } = this.state;
    const filteredCards =
      filteredCollection.length > 0 ? (
        filteredCollection
          .slice(0, this.state.showQuantity)
          .map((item, key) => {
            return (
              <Column sm={12} md={4} lg={4} key={key}>
                <Equalizer
                  enabled={this.enableEqualizer}
                  nodes={this.getCardTitleNodes.bind(this)}
                >
                  <Equalizer
                    enabled={this.enableEqualizer}
                    nodes={this.getCardDescriptionNodes.bind(this)}
                  >
                    <ArticleCard
                      index={key}
                      {...item}
                      ref={(node) => {
                        this.refCards[key] = node;
                      }}
                      ctaType={ctaType}
                      ctaSecondary={ctaType === "button"}
                    />
                  </Equalizer>
                </Equalizer>
              </Column>
            );
          })
      ) : (
        <Column sm={12} md={4} lg={4}>
          {MSG.NOTMATCH}
        </Column>
      );

    const ShowMoreButton = () => {
      if (filteredCollection.length > this.state.showQuantity) {
        return (
          <SC.ShowMoreRow center="sm">
            <Column sm={12}>
              <SC.ShowMoreButton
                secondary
                onClick={this.handleShowMore.bind(this)}
              >
                {viewMoreText}
              </SC.ShowMoreButton>
            </Column>
          </SC.ShowMoreRow>
        );
      } else return false;
    };

    return (
      <Grid className={className}>
        <Fragment>
          {showFilter !== false && !error && (
            <SC.StyledRow>
              <SC.Column sm={12} md={4} lg={4}>
                {filterLists && (
                  <SC.FilterLabel>{filterCriteriaText}</SC.FilterLabel>
                )}
              </SC.Column>
              {filterLists.map((item, key) => {
                return (
                  <Fragment key={key}>
                    <SC.StyledColumn sm={12} md={4} lg={4}>
                      <DropDown
                        placeholder={item.filterName}
                        items={sortOptions(
                          item.filterOptions,
                          item.filterOptions[0].text
                        )}
                        selectedIndex={0}
                        onChange={this.handleFilterChange.bind(
                          this,
                          item.filterName,
                          "primary"
                        )}
                      />
                    </SC.StyledColumn>
                    {item.secondFilterOptions.length > 0 && (
                      <SC.StyledColumn sm={12} md={4} lg={4}>
                        <DropDown
                          placeholder={item.filterName}
                          items={sortOptions(
                            item.secondFilterOptions,
                            item.secondFilterOptions[0].text
                          )}
                          selectedIndex={0}
                          onChange={this.handleFilterChange.bind(
                            this,
                            item.filterName,
                            "secondary"
                          )}
                        />
                      </SC.StyledColumn>
                    )}
                  </Fragment>
                );
              })}
            </SC.StyledRow>
          )}

          {sortList && (
            <Column sm={12} md={3} lg={3}>
              <SC.FilterLabel>{sortByText}</SC.FilterLabel>
              <Row>
                <SC.FilterColumn sm={12} lg={12}>
                  <DropDown
                    items={sortList}
                    selectedIndex={0}
                    onChange={this.handleSortChange.bind(this)}
                  />
                </SC.FilterColumn>
              </Row>
            </Column>
          )}
        </Fragment>
        <Row>
          {!error ? filteredCards : <SC.ErrorMsg>{error.msg}</SC.ErrorMsg>}
        </Row>
        <ShowMoreButton />
      </Grid>
    );
  }
}

ArticleList.defaultProps = {
  filterLists: [],
  collection: [],
  showQuantity: 9,
  showQuantityIncrement: 9,
  onSort: noop,
  onFilter: noop,
  onPostHeadingTextClick: noop,
};

ArticleList.propTypes = {
  /** Additional class name */
  className: PropTypes.string,
  /** Articles */
  articles: PropTypes.array,
  /** Description text as children */
  children: PropTypes.node,
  /** Filter criteria label */
  filterCriteriaText: PropTypes.string,
  /** List of filters to display in dropdowns (takes the shape of the Dropdown items props) */
  filterLists: PropTypes.arrayOf(
    PropTypes.shape({
      filterName: PropTypes.string.isRequired,
      filterOptions: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  /** Number of cards to show on load */
  showQuantity: PropTypes.number,
  /** Number of cards to increment on show more */
  showQuantityIncrement: PropTypes.number,
  /** Sort by label */
  sortByText: PropTypes.string,
  /** On Sort handler */
  onSort: PropTypes.func,
  /** On Filter handler */
  onFilter: PropTypes.func,
  /** View More text */
  viewMoreText: PropTypes.string,
  /** Show Filter Flag */
  showFilter: PropTypes.bool,
  /** Enable Article Fetching from API by url params */
  enableArticleApi: PropTypes.bool,
  /** Article API URL */
  allArticlesApi: PropTypes.string,
};

export default ArticleList;
