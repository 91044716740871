import styled, { css } from 'styled-components';
import Theme from '@lux/components/src/theme';
import { Row, Column, Button } from '@lux/components';

export default {
  Filters: styled(Row)`
    padding: 0;
  `,
  FilterColumn: styled(Column)`
    margin-bottom: 0.5rem;
    padding: 0;
  `,
  Column: styled(Column)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  `,
  List: styled(Row)`
    padding: 0;
  `,
  Heading: styled.h2`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${Theme.fonts.sizes.xlarge};
    margin: 0;
    margin-bottom: ${Theme.spacing.small};
    flex: 0 0 auto;
  `,
  Description: styled.div`
    margin-bottom: ${Theme.spacing.small};
  `,
  FilterLabel: styled.div`
    ${Theme.fonts.styles.Header7};
    margin-bottom: ${Theme.spacing.xsmall};
    text-align: left;

    ${Theme.media.md`
      text-align: right;
    `};
  `,
  ShowMoreRow: styled(Row)`
    ${css`
      padding: ${Theme.spacing.medium} 0;
    `};
  `,
  ShowMoreButton: styled(Button)`
    border-color: ${Theme.colours.singtelred};
    background-color: ${Theme.colours.singtelred};
    color: ${Theme.colours.white};
  `
};
