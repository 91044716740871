import styled from 'styled-components';

import { Theme } from '@lux/components';

export default {
  AutocompleteWrapper: styled.div`
    max-width: 514px;
    margin: auto;
    position: relative;
    font-size: 16px;

    input {
      margin-bottom: 0;
      font-size: 1rem;
      padding: 8px 12px;
    }
  `,
  IDDServicesWrapper: styled.div`
    margin: 30px 0;
    width: 100%;
  `,
  IDDIntroText: styled.p`
    font-family: ${Theme.fonts.families.LatoBold};
    text-align: center;
  `,
  ErrorMessage: styled.p`
    text-align: center;
    margin: auto;
    display: block;
    font-style: italic;
  `
};
