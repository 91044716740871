import React, { Component } from 'react';
import IconChevronUp from '../../assets/svgs/chevron-up.svg';
import IconChevronDown from '../../assets/svgs/chevron-down.svg';
import htmlStringToReactComponent from '@lux/components/src/helpers/htmlStringToReactComponent';
import PropTypes from 'prop-types';
import SC from './styles';
import {
  Grid,
  Column,
  Row,
  Button,
  Anchor
} from '@lux/components';

class PricePlanCalculator extends Component {

  state = {
    activeOption: {},
    activeAddOns: [],
    activePlanOption: {},
    selectedPlans: {},
    totalPrice: 0,
    isPanelHidden: true
  }

  handlePlanSelect = activePlanOption => {

    const { planOptions } = this.props;

    this.setState({
      activeAddOns: planOptions.planOptions.filter(plan => { return plan.value === activePlanOption.value }),
      activePlanOption
    })
  }

  handleAddOnSelect = option => {

    const { activePlanOption, selectedPlans } = this.state;

    if(selectedPlans[activePlanOption.value] === option) {
      selectedPlans[activePlanOption.value] = {
        optionName: '',
        optionPrice: 0
      }
    } else {
        selectedPlans[activePlanOption.value] = option
    }

    this.setState({
      selectedPlans
    }, () => {
      this.computeTotalPrice();
    })
  }

  handleReset = () => {
    this.setState({
      activePlanOption: {},
      selectedPlans: {},
      activeAddOns: [],
      totalPrice: 0
    })
  }

  computeTotalPrice() {
    const { selectedPlans } = this.state;

    let totalPrice = 0.00;

    Object.keys(selectedPlans).forEach(plan => {
      totalPrice += selectedPlans[plan]['optionPrice']
    })

    this.setState({
      totalPrice: totalPrice
    })
  }

  handleAccordionTitleClick = () => {
    this.setState({
      isPanelHidden: !this.state.isPanelHidden
    })
  }

  checkifPlanOptionIsActive = planOptionName => {
    const optionPrice = this.state.selectedPlans[planOptionName]
                          ? this.state.selectedPlans[planOptionName]['optionPrice']
                          : null;

    return optionPrice && optionPrice !== 0 ? true : false;
  }

  checkifAddOnIsActive = addOn => {
    const { activePlanOption, selectedPlans } = this.state;
    return selectedPlans[activePlanOption.value] === addOn;
  }

  render() {
    const {
      activeAddOns,
      totalPrice,
      isPanelHidden,
      selectedPlans,
      activePlanOption
    } = this.state;

    const {
      planName,
      planDesc,
      addOnsHeader,
      addOnsDesc,
      planOptions,
      resetButtonLabel,
      confirmButtonLabel,
      confirmButtonBaseUrl
    } = this.props;

    return (
      <Grid>
        <SC.AccordionTitle onClick={() => this.handleAccordionTitleClick()}>
          <Grid>
            <Row>
              <SC.ColumnZeroPadding sm={12} lg={4}>
                <SC.AccordionHeader>
                  <SC.VerticalAlignMiddle>
                    {planName}
                  </SC.VerticalAlignMiddle>
                </SC.AccordionHeader>
              </SC.ColumnZeroPadding>
              <SC.ColumnZeroPadding sm={12} lg={8}>
                <SC.LightSection>
                  <SC.VerticalAlignMiddle>
                    <SC.AccordionPlanDetailsWrapper>
                      { htmlStringToReactComponent(planDesc) }
                    </SC.AccordionPlanDetailsWrapper>
                  </SC.VerticalAlignMiddle>

                  <SC.AccordionIcon>
                    {
                      isPanelHidden
                      ? <IconChevronUp/>
                      : <IconChevronDown/>
                    }
                  </SC.AccordionIcon>

                </SC.LightSection>
              </SC.ColumnZeroPadding>
            </Row>
          </Grid>
        </SC.AccordionTitle>
        {
          !isPanelHidden
          ? <SC.AccordionPanel>
            <SC.CalculatorWrapper>
              <Row>
                <SC.ColumnZeroPadding sm={12} md={3}>
                  <SC.LightSection>
                    <SC.LargerFont>{addOnsHeader}</SC.LargerFont>
                    <span>{addOnsDesc}</span>
                  </SC.LightSection>
                </SC.ColumnZeroPadding>
                <SC.ColumnZeroPadding sm={12} md={9}>
                  <SC.CalculatorInnerWrapper>
                    <SC.PlanTypes>
                      <Row>
                        {
                          planOptions.planOptions.map((addOn, index) => {
                            return (
                              <Column sm={12} lg={4} key={index}>
                                <SC.TabButton
                                  isPlanOption
                                  role="tab"
                                  isSelected={addOn.value === activePlanOption.value}
                                  isActive={this.checkifPlanOptionIsActive(addOn.value)}
                                  onClick={() => this.handlePlanSelect(addOn)}
                                  >
                                  <span>{addOn.display}</span>
                                </SC.TabButton>
                                <SC.OptionDetails>
                                  {
                                    selectedPlans[addOn.value] ? selectedPlans[addOn.value]['optionName'] : ''
                                  }
                                </SC.OptionDetails>
                              </Column>
                            )
                          })
                        }
                      </Row>
                    </SC.PlanTypes>

                    <SC.ActivePlan>{activePlanOption.display}</SC.ActivePlan>

                    <Row>
                      {
                        activeAddOns.length > 0
                        ? activeAddOns[0].options.map((option, index) => {
                            return (
                              <SC.FlexColumn key={index} >
                                <SC.TabButton role="tab" tabIndex="0"
                                  isActive={this.checkifAddOnIsActive(option)}
                                  onClick={() => this.handleAddOnSelect(option)} >
                                  <span>{option.optionName}</span>
                                  <SC.OptionPrice>{(parseFloat(option.optionPrice)).toFixed(2)}</SC.OptionPrice>
                                </SC.TabButton>
                              </SC.FlexColumn>
                            )
                          })
                        : null
                      }
                    </Row>

                  </SC.CalculatorInnerWrapper>
                </SC.ColumnZeroPadding>
              </Row>
              <SC.PlanSummary>
                <Row>
                  <SC.ColumnZeroPadding sm={12} lg={3}>
                    <SC.VerticalAlignMiddle>
                      <SC.LightSection>
                        <SC.Total>
                          Total:
                        </SC.Total>
                      </SC.LightSection>
                    </SC.VerticalAlignMiddle>
                  </SC.ColumnZeroPadding>
                  <SC.ColumnZeroPadding sm={12} lg={3}>
                    <SC.CalculatorInnerWrapper>
                      <SC.VerticalAlignMiddle>
                        <SC.TotalPrice>
                          { (parseFloat(totalPrice)).toFixed(2) }
                        </SC.TotalPrice>
                      </SC.VerticalAlignMiddle>
                    </SC.CalculatorInnerWrapper>
                  </SC.ColumnZeroPadding>
                  <SC.ColumnZeroPadding sm={12} lg={6}>
                    <SC.PlanSummaryButtonGroupWrapper>
                      <SC.VerticalAlignMiddle>
                        <SC.PlanSummaryButtonGroup>
                          <Anchor onClick={this.handleReset}>{resetButtonLabel}</Anchor>
                          <Button href={confirmButtonBaseUrl}>{confirmButtonLabel}</Button>
                        </SC.PlanSummaryButtonGroup>
                      </SC.VerticalAlignMiddle>
                    </SC.PlanSummaryButtonGroupWrapper>
                  </SC.ColumnZeroPadding>
                </Row>
              </SC.PlanSummary>
            </SC.CalculatorWrapper>
          </SC.AccordionPanel>
          : null
        }
      </Grid>
    );
  }
}

PricePlanCalculator.defaultProps = {
  resetButtonLabel: 'Reset all',
  confirmButtonLabel: 'Confirm all'
}

PricePlanCalculator.propTypes = {
  resetButtonLabel: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  confirmButtonBaseUrl: PropTypes.string.isRequired,
  planOptions: PropTypes.shape({
    planOptions: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            optionName: PropTypes.string.isRequired,
            optionPrice: PropTypes.number.isRequired
          })
        ).isRequired
      })
    )
  }).isRequired,
  addOnsDesc: PropTypes.string.isRequired,
  addOnsHeader: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  planDesc: PropTypes.string.isRequired
};

export default PricePlanCalculator;
