import styled from 'styled-components';
import {
    Column,
    Theme
} from '@lux/components';
import BusinessTheme from '../../config';

export default {
  Container: styled.div`
    border-radius: 4px;
    ${Theme.styles.panelDropShadow};
    background-color: ${Theme.colours.white};
  `,
  ProductListColumn: styled(Column)`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Image: styled.img`
    border: none;
    width: 100%;
    height: 100%;
  `,
  Title: styled.h3`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${Theme.fonts.sizes.xlarge};
    color: ${BusinessTheme.colours.bodycopy};
    margin: 10px 0 15px;
  `,
  Description: styled.p`
    border: none;
  `,
  Link: styled.p`
    border: none;
  `
};
