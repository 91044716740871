import styled, { css } from 'styled-components';

import { Ribbon, Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

import CategoryImageBlack from '../../assets/svgs/price-tag_black.svg';

export default {
  Ribbon: styled(Ribbon)`
    top: ${Theme.spacing.small};
  `,
  CatalogueCard: styled.div`
    padding-bottom: 1.25rem;
    height: 100%;
  `,
  Card: styled.div`
    min-height: 200px;
    height: 100%;
    ${Theme.styles.panelDropShadow};
    border-radius: 4px;
    background-color: ${Theme.colours.white};
    position: relative;
  `,
  Image: styled.img`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: ${Theme.colours.grey_f5};
    width: 100%;
  `,
  Content: styled.div`
    padding: ${Theme.spacing.medium} ${Theme.spacing.small};
    text-align: left;
    display: block;
    flex: 1 1 auto;
  `,
  Divider: styled.hr`
    border-color: ${Theme.colours.grey_e5};
    margin: ${Theme.spacing.xsmall} 0;
  `,
  Title: styled.div`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${Theme.fonts.sizes.xlarge};
    margin-top: 0;
    margin-bottom: ${Theme.spacing.medium};
  `,
  Description: styled.div`
    margin-top: 0;
  `,
  PrePrice: styled.div`
    ${Theme.fonts.styles.SubscriptBadge};
    line-height: ${Theme.spacing.xsmall};
    margin-top: ${Theme.spacing.medium};
  `,
  Price: styled.div`
    ${Theme.fonts.styles.Header2};
    line-height: 3rem;
    padding: ${Theme.spacing.xsmall} 0;
  `,
  PostPrice: styled.div`
    text-align: center;
    font-size: ${Theme.fonts.sizes.regular};
    line-height: 1rem;
    margin-bottom: ${Theme.spacing.medium};
  `,
  TC: styled.div`
    ${Theme.fonts.styles.FinePrintCopy};
  `,
  CTA: styled.div`
    display: flex;
    bottom: 0;
    flex: 0 1 auto;
    align-items: left;
    justify-content: left;
    padding: 0 0 ${remCalc(20)} ${remCalc(20)};
  `,
  ImageContainer: styled.div`
    position: relative;
  `,
  Topics: styled.div`
    &::before {
      margin-right: 10px;
      position: absolute;
      left: 0;
      width: ${remCalc(20)};
    }
  `,
  CategoryIcon: styled(CategoryImageBlack)`
    margin-right: ${remCalc(5)};

    ${props => {
      if (props.theme === 'dark') {
        return css`
          path {
            stroke: ${Theme.colours.white};
          }

          circle {
            stroke: ${Theme.colours.white};
          }
        `;
      }
    }};
  `,
  Details: styled.div`
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-family: ${Theme.fonts.families.LatoBold};
    font-size: ${Theme.fonts.sizes.small};
    color: ${Theme.colours.white};

    ${props => {
      if (props.theme === 'light') {
        return css`
          color: ${Theme.colours.darkslate};
        `;
      }else {
        return css`
          color: ${Theme.colours.white};
        `;
      }
    }};
  `,
  Anchor: styled.a`
    width: 38px;
    height: 38px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  `,
  ShareIcons: styled.div`
    display: none;

    img {
      position: absolute;
      width: 38px;
      height: 38px;
      cursor: pointer;
    }

    &.is-active {
      display: block;
    }
  `,
  IconLinkedIn: styled.img`
    top: 10px;
    right: 70px;
  `,
  IconFacebook: styled.img`
    top: 55px;
    right: 55px;
  `,
  IconTwitter: styled.img`
    top: 70px;
    right: 10px;
  `
};
