import React, { Component } from 'react';
import { Row, Column, Grid } from '@lux/components';
import SC from './styles';
import PropTypes from 'prop-types';

class SpringboardAudioPlayer extends Component {
  render() {
    const { source, autoPlay, errorMessage } = this.props;
    return (
      <Grid>
        <Row center="sm">
          <Column sm={12} md={12} lg={12}>
            <SC.Container>
              <audio
                controls={!autoPlay}
                autoPlay={autoPlay}
                controlsList="nodownload"
              >
                <track kind="captions" />
                <source src={source} type="audio/ogg" />
                <source src={source} type="audio/mpeg" />
                {errorMessage}
              </audio>
            </SC.Container>
          </Column>
        </Row>
      </Grid>
    );
  }
}

SpringboardAudioPlayer.defaultProps = {
  source: '',
  autoPlay: false,
  errorMessage: 'Your browser does not support the audio element.'
};

SpringboardAudioPlayer.propTypes = {
  /** Source */
  source: PropTypes.string,
  /** Auto Play */
  autoPlay: PropTypes.bool,
  /** Error Message */
  errorMessage: PropTypes.string
};
export default SpringboardAudioPlayer;
