import styled from 'styled-components';

import { Theme, Column } from '@lux/components';

export default {
  ArticleDetailBannerCon: styled.div`
    background-color: ${Theme.colours.grey};
    padding: 50px 0;
  `,
  ArticleColumn: styled(Column)`
    display: ${props => (props.mobileDisplay ? 'none' : 'flex')};
    ${Theme.media.md`
    display: ${props => (props.desktopDisplay ? 'none' : 'flex')};
    `};
  `,
  Image: styled.img`
    border: none;
    width: 100%;
  `,
  ArticleDetailTextContainer: styled.div`
    padding-right: 0;
    ${Theme.media.lg`
      padding-right: 20px;
    `};

    p {
      font-size: ${Theme.fonts.sizes.large};
    }
  `,
  ArticleDetailBannerTitle: styled.h1`
    ${Theme.fonts.styles.Header1};
    color: ${Theme.colours.singtelred};
    line-height: normal;

    ${Theme.media.lg`
      line-height: 55px;
    `};
  `,
  ArticleDetailBannerSocial: styled.div`
    margin-bottom: 30px;
    ${Theme.media.lg`
      margin-bottom: 0;
    `};

    img {
      max-width: 50px;
      margin-right: 20px;
      border-radius: 78px;
    }
  `
};
