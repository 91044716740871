import React, { Component } from 'react';
import {
  Column,
} from '@lux/components';

import IDDCard from '../IDDCard';
import { isNarrowViewport } from '@lux/components/src/helpers';
import Equalizer from 'react-equalizer';
import PropTypes from 'prop-types';

class IDDCardList extends Component {

  refCards = [];

  enableEqualizer() {
    return !isNarrowViewport();
  }

  getNodes() {
    return this.refCards.map(card => { return card.cardRef });
  }

  render() {
    const { selectedCountryData } = this.props;

    let colOffset = selectedCountryData.length === 1 ? 3 : 0;

    return (
      selectedCountryData.map((serviceData, index) => {
        return (
          <Column key={index} lgOffset={colOffset} mdOffset={colOffset} sm={12} md={6}>
            <Equalizer
              enabled={ this.enableEqualizer }
              nodes={ this.getNodes.bind(this) } >
              <IDDCard
                serviceName={ serviceData.serviceName }
                availableRates={ serviceData.availableRates }
                ref={ node => (this.refCards[index] = node) }
              />
            </Equalizer>
          </Column>
        );
      })
    );
  }
}

IDDCardList.defaultProps = {
  selectedCountryData: []
}

IDDCardList.propTypes = {
  selectedCountryData: PropTypes.array,
};

export default IDDCardList;
