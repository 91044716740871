import styled from 'styled-components';
import { Ribbon, Theme } from '@lux/components';
import BusinessTheme from '../../config';
import { remCalc } from '@lux/helpers';

export default {
  CatalogueCard: styled.div`
    padding-bottom: 1.25rem;
    height: 100%;
  `,
  Ribbon: styled(Ribbon)`
    top: ${Theme.spacing.small};
  `,
  Image: styled.img`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: ${Theme.colours.grey_f5};
    width: 100%;
  `,
  Card: styled.div`
    min-height: 200px;
    height: 100%;
    ${Theme.styles.panelDropShadow};
    border-radius: 4px;
    background-color: ${Theme.colours.white};
    position: relative;
  `,
  Divider: styled.hr`
    border-color: ${Theme.colours.grey_e5};
    margin: ${Theme.spacing.xsmall} 0;
  `,
  Content: styled.div`
    padding: ${Theme.spacing.medium} ${Theme.spacing.small};
    text-align: left;
    display: block;
    flex: 1 1 auto;
  `,
  Title: styled.div`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${Theme.fonts.sizes.xlarge};
    margin-top: 0;
    margin-bottom: ${Theme.spacing.medium};
    color: ${BusinessTheme.colours.bodycopy};
  `,
  Description: styled.div`
    margin-top: 0;
    font-family: ${Theme.fonts.families.LatoRegular};
    color: ${BusinessTheme.colours.bodycopy};
  `,
  PrePrice: styled.div`
    ${Theme.fonts.styles.SubscriptBadge};
    line-height: ${Theme.spacing.xsmall};
    margin-top: ${Theme.spacing.medium};
  `,
  Price: styled.div`
    ${Theme.fonts.styles.Header2};
    line-height: 3rem;
    padding: ${Theme.spacing.xsmall} 0;
  `,
  PostPrice: styled.div`
    text-align: center;
    font-size: ${Theme.fonts.sizes.regular};
    line-height: 1rem;
    margin-bottom: ${Theme.spacing.medium};
  `,
  TC: styled.div`
    ${Theme.fonts.styles.FinePrintCopy};
  `,
  ImageContainer: styled.div`
    position: relative;
  `,
  CTA: styled.div`
    display: flex;
    bottom: 0;
    flex: 0 1 auto;
    align-items: left;
    justify-content: left;
    padding: 0 0 ${remCalc(20)} ${remCalc(20)};
  `,
  Topics: styled.div`
    padding-left: 25px;

    &::before {
      content: url(src/assets/images/dev-only/tag_icon.png);
      margin-right: 10px;
      position: absolute;
      left: 0;
    }
  `,
  Details: styled.div`
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-family: ${Theme.fonts.families.LatoBold};
    font-size: ${Theme.fonts.sizes.small};
    color: ${Theme.colours.white};
  `,
  Anchor: styled.a`
    width: 38px;
    height: 38px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  `,
  ShareIcons: styled.div`
    display: none;

    img {
      position: absolute;
      width: 38px;
      height: 38px;
      cursor: pointer;

      &#linkedin {
        top: 10px;
        right: 70px;
      }

      &#fb {
        top: 55px;
        right: 55px;
      }

      &#twitter {
        top: 70px;
        right: 10px;
      }
    }

    &.is-active {
      display: block;
    }
  `
};
