import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@lux/components';
import SC from './styles';

class Autocomplete extends Component {

  state = {
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: ''
  };

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    let regex = new RegExp(userInput.toLowerCase(), "gmi");

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().match(regex)
    ).sort(function(a, b) {
      /*
        Matched items must be grouped by search string occurrence position in alphabetical order
      */
      let groupA = a.substr(0, userInput.length).toLowerCase();
      let groupB = b.substr(0, userInput.length).toLowerCase();

      if (groupA == userInput.toLowerCase()) {
          if (groupB != userInput.toLowerCase()) return -1;
      } else if (groupB == userInput.toLowerCase()) return 1;

      return a < b ? -1 : (a > b ? 1 : 0);
    });

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    this.props.handleSelect(e.currentTarget.innerText);
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { numSuggestions } = this.props;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });

      this.props.handleSelect(filteredSuggestions[activeSuggestion]);
    }
    else if (e.keyCode === 38) {
      if (activeSuggestion !== 0) {
        this.setState({
          activeSuggestion: activeSuggestion - 1
        });
      }
    }
    else if (e.keyCode === 40) {

      const limit = filteredSuggestions.slice(0, numSuggestions).length;

      if (activeSuggestion < limit - 1) {
        this.setState({
          activeSuggestion: activeSuggestion + 1
        });
      }
    }
  };

  onMouseEnter = index => {
    this.setState({
      activeSuggestion: index
    })
  };

  onBlur = () => {
    setTimeout(
      () => this.setState({
        showSuggestions: false
      }),
      50
    )
  };

  render() {

    const {
      onChange,
      onClick,
      onKeyDown,
      onBlur,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <SC.SuggestionList className="suggestions">
            {filteredSuggestions.slice(0, this.props.numSuggestions).map((suggestion, index) => {
              let className;

              if(index === activeSuggestion) className = 'suggestion-active'

              return (
                <SC.SuggestionItem
                  role="menuitem"
                  className={className}
                  key={suggestion}
                  onMouseDown={onClick}
                  onKeyDown={onKeyDown}
                  onMouseEnter={(index) => this.onMouseEnter(index)}
                >
                  {suggestion}
                </SC.SuggestionItem>
              );
            })}
          </SC.SuggestionList>
        );
      }
    }

    return (
      <SC.Autocomplete>
        <TextField
          type="text"
          className="autocomplete__input"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={this.props.placeholder}
          onBlur={onBlur}
          value={userInput || ''}
          showClear={false}
        />
        { suggestionsListComponent }
      </SC.Autocomplete>
    );
  }
}

Autocomplete.defaultProps = {
  suggestions: [],
  numSuggestions: 10,
};

Autocomplete.propTypes = {
  /* a list of suggestions */
  suggestions: PropTypes.array,
  /* number of suggestions to display */
  numSuggestions: PropTypes.number,
  /* a function to set parent state */
  handleSelect: PropTypes.func.isRequired,
  /* placeholder text */
  placeholder: PropTypes.string
};

export default Autocomplete;
