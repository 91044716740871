import React, { Component } from "react";
import PropTypes from "prop-types";

import SC from "./styles";

class SpringboardHeadingText extends Component {
  renderComponent = (checkType) => {
    switch (checkType) {
      case "renderText":
        return (
          <SC.StyledText
            textStyle={this.props.textStyle}
            theme={this.props.theme}
            dangerouslySetInnerHTML={{
              __html: this.props.text,
            }}
          />
        );
      case "renderHeading":
        return (
          <SC.StyledHeading
            type={this.props.headingTagElement || "h2"}
            headingstyle={this.props.headingStyle}
            theme={this.props.theme}
          >
            {this.props.heading}
          </SC.StyledHeading>
        );
    }
  };

  render() {
    const {
      text,
      theme,
      linkText,
      link,
      leftSpacing,
      rightSpacing,
      topSpacing,
      bottomSpacing,
      headingAlignment,
      textAlignment,
      textLeftSpacing,
      textRightSpacing,
      textTopSpacing,
      textBottomSpacing,
      headingStyle,
    } = this.props;

    return (
      <SC.StyledContainer theme={theme}>
        <SC.StyledGrid
          textLeftSpacing={leftSpacing}
          textRightSpacing={rightSpacing}
          textTopSpacing={topSpacing}
          textBottomSpacing={bottomSpacing}
        >
          <SC.StyledRowAlign
            align={headingAlignment || ""}
            headingStyle={headingStyle}
          >
            {this.renderComponent("renderHeading")}
          </SC.StyledRowAlign>
        </SC.StyledGrid>
        {text && (
          <SC.StyledGrid
            textLeftSpacing={textLeftSpacing}
            textRightSpacing={textRightSpacing}
            textTopSpacing={textTopSpacing}
            textBottomSpacing={textBottomSpacing}
          >
            <SC.StyledRowAlign align={textAlignment || ""}>
              {this.renderComponent("renderText")}
            </SC.StyledRowAlign>
          </SC.StyledGrid>
        )}
        {linkText && (
          <SC.StyledLinkRow theme={theme}>
            <SC.StyledAnchor theme={theme} href={link}>
              {linkText}
            </SC.StyledAnchor>
          </SC.StyledLinkRow>
        )}
      </SC.StyledContainer>
    );
  }
}

SpringboardHeadingText.defaultProps = {
  theme: "",
  heading: "",
  headingTagElement: "h2",
  text: "",
  linkText: "",
  link: "",
  leftSpacing: "",
  rightSpacing: "",
  topSpacing: "",
  bottomSpacing: "",
  headingAlignment: "",
  textAlignment: "",
  textLeftSpacing: "",
  textRightSpacing: "",
  textTopSpacing: "",
  textBottomSpacing: "",
  headingStyle: "main-header",
  textStyle: "",
};

SpringboardHeadingText.propTypes = {
  /** Theme */
  theme: PropTypes.string,
  /** Header Text */
  heading: PropTypes.string,
  /** Header Tag */
  headingTagElement: PropTypes.string,
  /** Header Description */
  text: PropTypes.string,
  /** Link text*/
  linkText: PropTypes.string,
  /** link*/
  link: PropTypes.string,
  /**Heading left spacing  */
  leftSpacing: PropTypes.string,
  /**Heading style  */
  headingStyle: PropTypes.string,
  /** Heading right spacing */
  rightSpacing: PropTypes.string,
  /**Heading top spacing  */
  topSpacing: PropTypes.string,
  /**Heading bottom spacing  */
  bottomSpacing: PropTypes.string,
  /**Heading alignment  */
  headingAlignment: PropTypes.string,
  /**Text alignment  */
  textAlignment: PropTypes.string,
  /**Text left spacing  */
  textLeftSpacing: PropTypes.string,
  /**Text right spacing  */
  textRightSpacing: PropTypes.string,
  /**Text top spacing  */
  textTopSpacing: PropTypes.string,
  /**Text bottom spacing  */
  textBottomSpacing: PropTypes.string,
  /**Text description style */
  textStyle: PropTypes.string,
};
export default SpringboardHeadingText;
