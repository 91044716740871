import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Column, Button } from '@lux/components';
import { getUrlParams } from '@lux/helpers';
import SC from './styles';

export const MSG = {
  NOTFOUND: 'Sorry! There are no products to show.',
  WENTWRONG:
    'Sorry we’re experiencing an error with the recommendation results. Please try again later.'
};

class RecommendedProducts extends Component {
  state = {
    products: [],
    showProducts: parseInt(this.props.itemPerGroup),
    isMax: false,
    error: {
      msg: '',
      show: false
    }
  };

  componentDidMount() {
    const hasParams = getUrlParams(window.location.href);
    if (hasParams) {
      const q1 = (hasParams.s1 === true || hasParams.s1 === 'true') ? hasParams.q1 : undefined;
      const q2 = (hasParams.s2 === true || hasParams.s2 === 'true') ? hasParams.q2 : undefined;
      const q3 = (hasParams.s3 === true || hasParams.s3 === 'true') ? hasParams.q3 : undefined;
      const q4 = (hasParams.s4 === true || hasParams.s4 === 'true') ? hasParams.q4 : undefined;
      const q5 = (hasParams.s5 === true || hasParams.s5 === 'true') ? hasParams.q5 : undefined;
      this.fetchProducts(q1, q2, q3, q4, q5);
    } else {
      //Don't call api
      this.setState({
        error: {
          msg: MSG.WENTWRONG,
          show: true
        },
        products: [],
        isMax: false
      });
    }
  }

  /**
   * Fetch products based on the answer of list question
   */
  fetchProducts = (...qArr) => {
    const { allProductsApi } = this.props;
    let qValue = '';
    qArr.filter((el) => el !== undefined).forEach((el, index) => {
      qValue = qValue + 'q' + (index + 1) + '=' + el + '&';
    });
    if (qValue && qValue.charAt(qValue.length - 1) === '&') {
      qValue = qValue.slice(0, -1);
    }
    const qstring = window.btoa(qValue),
      apiParams = `${allProductsApi}.${qstring}.products.json`;
    return fetch(apiParams, {
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(MSG.WENTWRONG);
        }
      })
      .then(allProducts => {
        //We have products
        if (allProducts.length > 0) {
          const sortedProducts = this.prepareProducts(allProducts);
          this.setState({
            products: sortedProducts,
            isMax: sortedProducts.length <= this.state.showProducts || false
          });
        } else {
          //Response is empty
          throw new Error(MSG.NOTFOUND);
        }
      })
      .catch(err => {
        this.setState({
          error: {
            msg: err.message,
            show: true
          },
          products: [],
          isMax: false
        });
      });
  };

  /**
   * Return sorted products so that *boosted/featured* ones goes up the list
   */
  prepareProducts = data => {
    let sortedProducts = [];
    let sortedData = data.sort((a, b) => {
      return Date.parse(b.publishedDate) - Date.parse(a.publishedDate);
    });
    const hasFeatured = sortedData.filter(item => item.isFeatured);
    const allProducts = sortedData.filter(item => !item.isFeatured);

    if (hasFeatured && hasFeatured.length) {
      sortedProducts = hasFeatured.concat(allProducts);
    } else {
      sortedProducts = sortedData;
    }

    return sortedProducts;
  };

  /**
   * Paginate or show link if max
   */
  LoadMore = () => {
    const currentDisplayed = this.state.showProducts,
      allProductCnt = this.state.products.length,
      inc = this.props.itemPerGroup,
      //Check if display count will be incremented or max it
      nextDisplay =
        currentDisplayed < allProductCnt
          ? currentDisplayed + inc
          : allProductCnt,
      //Check if display count is max out or is more than the product count
      isMax =
        nextDisplay === allProductCnt || nextDisplay > allProductCnt
          ? true
          : false;

    this.setState({
      showProducts: nextDisplay,
      isMax: isMax
    });
  };

  render() {
    const {
      buttonLabel,
      maxButtonLabel,
      maxButtonLink,
      isOpenNewTab
    } = this.props;
    const { products, isMax, error } = this.state;

    return (
      <Grid>
        <SC.ProductsWrapper>
          <SC.SectionWrapper>
            <Column>
              <SC.ProductsTitle level={1}>
                Recommended Solutions
              </SC.ProductsTitle>
              <Row>
                {!error.show ? (
                  products && products.length ? (
                    products.map((item, i) => {
                      return (
                        <SC.ProductBox
                          key={i}
                          show={i < this.state.showProducts || false}
                        >
                          <SC.Row>
                            <SC.ProdImgWrapper
                              {...(item.image
                                ? {
                                    sm: 12,
                                    md: 6,
                                    lg: 6
                                  }
                                : {
                                    sm: 0,
                                    md: 0,
                                    lg: 0
                                  })}
                            >
                              {item.image && (
                                <SC.ProductImg
                                  src={item.image}
                                  alt={item.title}
                                />
                              )}
                            </SC.ProdImgWrapper>
                            <SC.DescriptionContainer>
                              <SC.ProductTitle level={6}>
                                {`${item.title.substring(0, 30)}...`}
                              </SC.ProductTitle>
                              {item.description && (
                                <SC.ProductDescription>
                                  {`${item.description.substring(0, 50)}...`}
                                </SC.ProductDescription>
                              )}
                              <SC.Link
                                href={item.ctaUrl}
                                target={isOpenNewTab ? '_blank' : '_self'}
                              >
                                {item.ctaText || `Learn more >`}
                              </SC.Link>
                            </SC.DescriptionContainer>
                          </SC.Row>
                        </SC.ProductBox>
                      );
                    })
                  ) : (
                    <Column>
                      <SC.NoProducts>{error.msg}</SC.NoProducts>
                    </Column>
                  )
                ) : (
                  <Column>
                    <SC.NoProducts>{error.msg}</SC.NoProducts>
                  </Column>
                )}
              </Row>
            </Column>
          </SC.SectionWrapper>
          {!error.show &&
            products.length &&
            (!isMax ? (
              <SC.ButtonContainer>
                <Column>
                  <Button onClick={() => this.LoadMore()}>{buttonLabel}</Button>
                </Column>
              </SC.ButtonContainer>
            ) : (
              <SC.ButtonContainer>
                <Column>
                  <Button href={maxButtonLink}>{maxButtonLabel}</Button>
                </Column>
              </SC.ButtonContainer>
            ))}
        </SC.ProductsWrapper>
      </Grid>
    );
  }
}

RecommendedProducts.propTypes = {
  /** allProductsApi will be appended with base64 encoded string from the query string e.g. 'category=digital&size=smallbiz' */
  allProductsApi: PropTypes.string,
  /** Button label that can paginate */
  buttonLabel: PropTypes.string,
  /** If nothing to paginate, this is the button label*/
  maxButtonLabel: PropTypes.string,
  /** If nothing to paginate, this is the url the button will call*/
  maxButtonLink: PropTypes.string,
  /** Items to show per paginate*/
  itemPerGroup: PropTypes.number,
  /** Product CTA to open new tab or same */
  isOpenNewTab: PropTypes.bool
};

export default RecommendedProducts;
