import React, { Component } from 'react';
import SC from './styles';
import IconClose from '../../../../../assets/svgs/close.svg';
import PropTypes from 'prop-types';
import { noop } from '@lux/helpers';

import {
  Button
} from '@lux/components';

class GoogleMapsInfoCard extends Component {

  handleClick = () => {
    this.props.toggleLightBox();
  };

  render() {

    return (
        <SC.OfficeInfoCard
          isOfficeViewActive={this.props.isActive}
          isListViewActive={this.props.isListViewActive}
          isOfficeListViewActive={this.props.isOfficeListViewActive}>

          <SC.OfficeName>{this.props.officeName}</SC.OfficeName>

          <div>
            {
              this.props.address
              ?  this.props.address.map((address, index) => {
                  return <SC.Address key={index}>{address}</SC.Address>
                })
              : null
            }
            <dl>
              {
                  this.props.tel
                  ? (
                  <SC.ContactDetails>
                    <dt>Tel:</dt>
                    <dd>{this.props.tel}</dd>
                  </SC.ContactDetails>
                  )
                  : null
              }

              {
                  this.props.fax
                  ? (
                    <SC.ContactDetails>
                      <dt>Fax:</dt>
                      <dd>{this.props.fax}</dd>
                    </SC.ContactDetails>
                  )
                  : null
              }
            </dl>
            <p>
              <Button target="_blank" onClick={() => this.handleClick()}>Contact us</Button>
            </p>
          </div>
          {
            !(this.props.isListViewActive || this.props.isOfficeListViewActive)
            ? <SC.CloseButton>
                <IconClose onClick={this.props.onClose} />
              </SC.CloseButton>
            : null
          }
        </SC.OfficeInfoCard>
    );
  }
}

GoogleMapsInfoCard.propTypes = {
  /* office address */
  address: PropTypes.array,
  /* office name */
  officeName: PropTypes.string,
  /* office telephone number */
  tel: PropTypes.string,
  /* office fax number */
  fax: PropTypes.string,
  /* is card active? */
  isActive: PropTypes.bool,
  /* function that defines card behaviour when close button is clicked on */
  onClose: PropTypes.func
};

GoogleMapsInfoCard.defaultProps = {
  onClose: noop
};

export default GoogleMapsInfoCard;
