import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SC from './styles';

import {
  Anchor,
  Grid,
  Column,
  Row,
  ListItem,
  TextField
} from '@lux/components';

import IconHamburger from '../../assets/svgs/hamburger.svg';
import IconHamburgerLine from '../../assets/svgs/hamburger-line.svg';
import IconSearch from '../../assets/svgs/search.svg';
import IconShoppingCartList from '../../assets/svgs/eshop-icon.svg';
import IconChevronUp from '@lux/components/src/assets/svgs/chevron-up.svg';

import IconBack from '../../assets/svgs/back.svg';
import IconClose from '../../assets/svgs/close-with-background.svg';
import IconHome from '../../assets/svgs/home-red.svg';

class BusinessHeader extends Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
      menuFirstLevelHoverKey: -1,
      activeSubMenuItem: -1,
      activeSubSubMenuItem: -1,
      activeMenuItem: -1,
      shoppingCartActive: false,
      searchActive: false,
      searchQuery: ''
    };
  }

  handleFirstLevelMenuMouseEnter = (key, title) => {
    if(key !== this.state.menuFirstLevelHoverKey) {
      this.setState({
        menuFirstLevelHoverKey: key,
        menuFirstLevelHoverTitle: title,
        shoppingCartActive: false
      });
    }
  }

  handleFirstLevelMenuMouseLeave = () => {
    this.setState({
      menuFirstLevelHoverKey: -1,
      menuSecondLevelHoverKey: -1,
    });
  }

  handleSecondLevelMenuMouseEnter = (key, title) => {
    if(key !== this.state.menuSecondLevelHoverKey) {
      this.setState({
        menuSecondLevelHoverKey: key,
        menuSecondLevelHoverTitle: title,
        menuSecondLevelSetActiveClass: 1
      });
    }
  }

  handleShoppingCart = () => {
    this.setState(prevState => ({
      shoppingCartActive: !prevState.shoppingCartActive
    }));
  }

  handleSiteSearch = () => {
    this.setState(prevState => ({
      searchActive: !prevState.searchActive
    }));
  }

  handleBackClick = () => {
    if(this.state.activeSubSubMenuItem !== -1) {
      this.setState({
        activeSubSubMenuItem: -1
      });
    }else {
      this.setState({
        activeSubMenuItem: -1
      });
    }
  }

  handleMenuClick = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }));

    // Added to disable page scrolling when hamburger menu is open
    document.getElementsByTagName('html')[0].style = this.state.isMenuOpen ? '' : 'overflow: hidden;';
    document.getElementsByTagName('body')[0].style = this.state.isMenuOpen ? '' : 'overflow: hidden;';

    this.handleBackClick();
  }

  handleMenuItemClick = (menuKey, subKey, level) => {
    if(level === 2) {
      this.setState(prevState => {
        return {
          activeMenuItem: menuKey,
          activeSubMenuItem: prevState.activeSubMenuItem !== subKey ? subKey : -1
        };
      });
    }else {
      this.setState(prevState => {
        return {
          activeSubSubMenuItem: prevState.activeSubSubMenuItem !== subKey ? subKey : -1
        };
      });
    }
  }

  handleSearch = (e) => {

    const { searchQuery } = this.state;

    if(e.key === 'Enter' /*&& searchQuery != ''*/) {
      window.location = this.props.searchUrl + '?rinput=' + searchQuery;
    }
  }

  handleSearchChange = (e) => {
    this.setState({
      searchQuery: e.target.value
    });
  }

  render() {
    const {
      logo,
      showHamburger,
      siteList,
      navigation,
      shoppingCartList,
      showSearchBox,
      showEshopButton
    } = this.props;

    const { isMenuOpen, activeMenuItem, activeSubMenuItem, activeSubSubMenuItem } = this.state;

    const HamburgerMenu = () => {
      return (
        <SC.Hamburger onClick={this.handleMenuClick}>
          <IconHamburger size={22} />
        </SC.Hamburger>
      );
    };

    const HamburgerMenuLine = () => {
      return (
        <SC.HamburgerLine onClick={this.handleMenuClick}>
          <IconHamburgerLine size={22} />
        </SC.HamburgerLine>
      );
    };

    const ShoppingCartDropDown = () => {
      return shoppingCartList.map((cartItem, key) => {
        return (
          <ListItem key={key}>
            <SC.AnchorDarkSlateSmall href={cartItem.link} target="_blank">
              {cartItem.text}
            </SC.AnchorDarkSlateSmall>
          </ListItem>
        );
      });
    };

    const ShoppingCart = () => {
      return (
        <SC.AnchorWhite onClick={this.handleShoppingCart}
        >
          <IconShoppingCartList size={38} />
        </SC.AnchorWhite>
      );
    };

    const LogoImage = () => {
      return (
        <SC.LogoAnchor href={logo.linkURL}>
          <SC.LogoImg src={logo.image} alt={logo.alt} />
        </SC.LogoAnchor>
      );
    };

    const LogoImageDesktop = () => {
      return (
        <Anchor href={logo.linkURL}>
          <SC.LogoImg src={logo.image} alt={logo.alt} />
        </Anchor>
      );
    };

    const TopRightPrimaryMenuItems = () => {
      return siteList.map((menuItem, key) => {
        return (
          <ListItem key={key}>
            {menuItem.active ? (
              <SC.AnchorWhiteSmall href={menuItem.link}>
                {menuItem.text}
              </SC.AnchorWhiteSmall>
            ) : (
              <SC.AnchorWhiteSmall secondary href={menuItem.link}>
                {menuItem.text}
              </SC.AnchorWhiteSmall>
            )}
          </ListItem>
        );
      });
    };

    const MenuDesktop = () => {
      return (
        navigation
          .map((menuItem, key) => {
            return (
              <div
                key={key}
                onMouseEnter={() => this.handleFirstLevelMenuMouseEnter(key, menuItem.navItem.title)}
                onMouseLeave={this.handleFirstLevelMenuMouseLeave}
              >
                <ListItem key={key}>
                  {/* Primary navigation items */}
                  <SC.AnchorWhitePrimary
                    href={((menuItem.subMenu || []).length === 0 ? menuItem.navItem.linkURL : '#')}
                    active={this.state.menuFirstLevelHoverKey === key ? true : menuItem.navItem.active}
                  >
                    {menuItem.navItem.title}
                  </SC.AnchorWhitePrimary>
                </ListItem>

                {/* When user hovers over a primary navigation item */}
                {this.state.menuFirstLevelHoverKey === key && (menuItem.subMenu || []).length > 0 && (
                  <SC.SecondayMenuDesktop>
                    <Grid>
                      <Row>
                        <Column sm={12} md={2} lg={2}>
                          <SC.SecondaryTitle>{this.state.menuFirstLevelHoverTitle}</SC.SecondaryTitle>
                        </Column>
                        <SC.SecondaryMenuListWrapper sm={12} md={3} lg={3}>
                          {/* Loop through second level items */}
                          {(menuItem.subMenu || []).map((subMenuItem, key) => {
                            return (
                              <SC.SecondaryMenuDesktopColumn
                                key={key}
                                onMouseEnter={() => this.handleSecondLevelMenuMouseEnter(key, subMenuItem.navItem.title)}
                              >
                                <SC.AnchorDarkSlate
                                  href={subMenuItem.navItem.linkURL}
                                  hovered={this.state.menuSecondLevelHoverKey === key ? true : ''}
                                >
                                  {subMenuItem.navItem.title}
                                  {(subMenuItem.subMenu || []).length > 0 && (
                                    <IconChevronUp sm={24}
                                      hovered={this.state.menuSecondLevelHoverKey === key ? true : ''}
                                     />
                                  )}
                                </SC.AnchorDarkSlate>
                                {this.state.menuSecondLevelHoverKey === key && (subMenuItem.subMenu || []).length > 0 && (
                                  <SC.ThirdLevelList noPadding showBullets={false}>
                                    <SC.ThirdLevelTitle>{this.state.menuSecondLevelHoverTitle}</SC.ThirdLevelTitle>
                                    {/* Loop through third level items */}
                                    {(subMenuItem.subMenu || []).map(
                                      (subSubMenuItem, key) => {
                                        return (
                                          <SC.SecondaryMenuListThirdWrapper key={key}>
                                            <ListItem>
                                              <SC.AnchorDarkSlate
                                                href={subSubMenuItem.navItem.linkURL}
                                              >
                                                {subSubMenuItem.navItem.title}
                                              </SC.AnchorDarkSlate>
                                            </ListItem>
                                          </SC.SecondaryMenuListThirdWrapper>
                                        );
                                      }
                                    )}
                                  </SC.ThirdLevelList>
                                )}
                              </SC.SecondaryMenuDesktopColumn>
                            );
                          })}
                        </SC.SecondaryMenuListWrapper>
                        <Column sm={12} md={4} lg={4}></Column>
                        <SC.FeaturedContentWrapper sm={12} md={3} lg={3}>
                          {(menuItem.subMenu || []).map((subMenuItem, key) => {
                            return (
                              this.state.menuSecondLevelHoverKey === key && (
                                <SC.SecondaryMenuDesktopColumn
                                  key={key}
                                >
                                  {subMenuItem.navItem.image !== null && (
                                    <img src={subMenuItem.navItem.image} alt={subMenuItem.navItem.description} />
                                  )}
                                  <p>{subMenuItem.navItem.description}</p>
                                </SC.SecondaryMenuDesktopColumn>
                              )
                            );}
                          )}
                        </SC.FeaturedContentWrapper>
                      </Row>
                    </Grid>
                  </SC.SecondayMenuDesktop>
                )}
              </div>
            );
          })
      );
    };

    const MenuMobile = () => {
      return siteList
              .map((siteItem, menuKey) => {
        return (
          <SC.MobileNavigationRow key={menuKey} middle="sm" isActive={this.state.activeSubMenuItem !== -1}>
            {/* First level menu items */}
            <SC.ColumnPrimaryMenuItem sm={12}>
              <SC.AnchorWhiteMenuTitle href={siteItem.link}>
                {siteItem.text}
              </SC.AnchorWhiteMenuTitle>
            </SC.ColumnPrimaryMenuItem>
            {/* Second level menu items */}
            {/* <SC.SecondLevelMenuWrapper> */}
              {siteItem.active === true && (
                navigation.map((subMenuItem, subKey) => {
                  return (
                    <Column key={subKey} sm={12}>
                      <SC.ColumnSubMenuItem sm={12}>
                        {/* We display accordion style if submenus exists */}
                        {(subMenuItem.subMenu || []).length > 0 && (
                          <SC.AnchorWhiteMenuItem
                            secondary
                            active={subKey === activeSubMenuItem}
                            onClick={() =>
                              this.handleMenuItemClick(menuKey, subKey, 2)
                            }
                          >
                            {subMenuItem.navItem.title}
                            <IconChevronUp sm={24} />
                          </SC.AnchorWhiteMenuItem>
                        )}
                        {/* If no submenu is found, its a html href link */}
                        {(subMenuItem.subMenu || []).length === 0 && (
                          <SC.AnchorWhite secondary href={subMenuItem.navItem.linkURL}>
                            {subMenuItem.navItem.title}
                          </SC.AnchorWhite>
                        )}
                      </SC.ColumnSubMenuItem>

                      <SC.ThirdMenuWrapper selected={this.state.activeSubMenuItem === subKey ? true : ''}>
                        {/* Third level menu items */}
                        <SC.ThirdLevelTitleColumnWrapper active={this.state.activeSubSubMenuItem === -1 ? true : ''}>
                          {menuKey === activeMenuItem &&
                            subKey === activeSubMenuItem &&
                            (subMenuItem.subMenu || []).length > 0 && (
                              <SC.AnchorWhiteMenuTitle href={subMenuItem.navItem.linkURL}>
                                {subMenuItem.navItem.title}
                              </SC.AnchorWhiteMenuTitle>
                            )
                          }
                        </SC.ThirdLevelTitleColumnWrapper>
                        {menuKey === activeMenuItem &&
                          subKey === activeSubMenuItem &&
                          subMenuItem.subMenu.map((subSubMenuItem, subSubKey) => {
                            return (
                              <Column key={subSubKey} sm={12}>
                                <SC.ThirdLevelColumnWrapper active={this.state.activeSubSubMenuItem === -1 ? true : ''}>
                                  {/* We display accordion style if submenus exists */}
                                  {(subSubMenuItem.subMenu || []).length > 0 && (
                                    <SC.ColumnSubMenuItem 
                                      key={subSubKey}
                                      sm={12}
                                    >
                                      <SC.AnchorWhiteMenuItem
                                        key={subSubKey}
                                        secondary
                                        active={subSubKey === activeSubSubMenuItem}
                                        onClick={() =>
                                          this.handleMenuItemClick(menuKey, subSubKey, 3)
                                        }
                                      >
                                        {subSubMenuItem.navItem.title}
                                        <IconChevronUp sm={24} />
                                      </SC.AnchorWhiteMenuItem>
                                    </SC.ColumnSubMenuItem>
                                  )}
                                  {/* If no submenu is found, its a html href link */}
                                  {(subSubMenuItem.subMenu || []).length === 0 && (
                                    <SC.ColumnSubMenuItem 
                                      key={subSubKey}
                                      sm={12}
                                    >
                                      <SC.AnchorWhite secondary href={subSubMenuItem.navItem.linkURL}>
                                        {subSubMenuItem.navItem.title}
                                      </SC.AnchorWhite>
                                    </SC.ColumnSubMenuItem>
                                  )}
                                </SC.ThirdLevelColumnWrapper>

                                <SC.FourthMenuWrapper selected={this.state.activeSubSubMenuItem === subSubKey ? true : ''}>
                                  {/* Fourth level menu items */}
                                  {subSubKey === activeSubSubMenuItem &&
                                    (subSubMenuItem.subMenu || []).length > 0 && (
                                      <SC.AnchorWhiteMenuTitle href={subSubMenuItem.navItem.linkURL}>
                                        {subSubMenuItem.navItem.title}
                                      </SC.AnchorWhiteMenuTitle>
                                    )
                                  }
                                  {subSubKey === activeSubSubMenuItem &&
                                    subSubMenuItem.subMenu.map((subSubSubMenuItem, subSubSubKey) => {
                                      return (
                                        <SC.ColumnSubSubMenuItem key={subSubSubKey} sm={12}>
                                          <SC.AnchorWhite
                                            secondary
                                            href={subSubSubMenuItem.navItem.linkURL}
                                          >
                                            {subSubSubMenuItem.navItem.title}
                                          </SC.AnchorWhite>
                                        </SC.ColumnSubSubMenuItem>
                                      );
                                    })
                                  }
                                </SC.FourthMenuWrapper>
                              </Column>
                            );
                          })
                        }
                      </SC.ThirdMenuWrapper>

                    </Column>
                  );
                })
              )}
            {/* </SC.SecondLevelMenuWrapper> */}
          </SC.MobileNavigationRow>
        );
      });
    };

    /**
      React Headroom
      https://github.com/KyleAMathews/react-headroom
      React Headroom is a React Component to hide/show your header on scroll.
      When you scroll down, it slides out of view and slides back in when scrolling up.
    */

    return (
      <React.Fragment>
        <SC.Headroom
          disableInlineStyles={true}
        >
          <SC.HeaderWrapper>
              <SC.HeaderContainer>
                <SC.HeaderPlaceholder></SC.HeaderPlaceholder>
                <SC.Header>
                  <SC.GridMobile>
                    <Row>
                      <Column sm={4}>
                        <SC.HamburgerTabletWrapper>
                          {/* Hamburger Icon top left corner */}
                          {showHamburger && <HamburgerMenuLine />}
                        </SC.HamburgerTabletWrapper>
                      </Column>
                      <Column sm={4}>
                        {/* Logo in the middle */}
                        <LogoImage />
                      </Column>
                      <Column sm={4}>
                        {/* RHS controls */}
                        <SC.RightHandColumn>
                          {shoppingCartList && showEshopButton && <ShoppingCart />}
                          {this.state.shoppingCartActive && (
                            <SC.CartDropDownList>
                              {<ShoppingCartDropDown />}
                            </SC.CartDropDownList>
                          )}
                        </SC.RightHandColumn>
                      </Column>
                    </Row>
                  </SC.GridMobile>
                  <SC.GridDesktop>
                    <Row>
                      <Column sm={6} />
                      <Column sm={6}>
                        <SC.MenuItemList isInline={true}>
                          {/* Top right hand corner primary menu items */}
                          <TopRightPrimaryMenuItems menuItems={navigation} />
                        </SC.MenuItemList>
                      </Column>
                    </Row>
                    <Row>
                      <Column sm={8}>
                        <SC.MenuItemListPrimary isInline={true}>
                          <ListItem>
                            {/* Desktop logo */}
                            <LogoImageDesktop />
                          </ListItem>
                          {/* Primary menu items in desktop */}
                          <MenuDesktop />
                        </SC.MenuItemListPrimary>
                      </Column>
                      <Column sm={4}>
                        <SC.MenuItemList isInline={true}>
                          <ListItem>
                            <SC.RightHandColumn>
                              <SC.SearchWrapper>
                                {this.state.searchActive ? (
                                  <SC.SearchFieldWrapper>
                                    <TextField
                                      placeholder={'Search'}
                                      onBlur={this.handleSiteSearch}
                                      onChange={this.handleSearchChange}
                                      onKeyUp={this.handleSearch}
                                      value={this.state.searchQuery}
                                    />
                                  </SC.SearchFieldWrapper>
                                ) : (
                                  (
                                    showSearchBox && (
                                      <SC.SearchAnchorWhite onClick={this.handleSiteSearch}>
                                        <IconSearch size={38} />
                                      </SC.SearchAnchorWhite>
                                    )
                                  )
                                )}
                              </SC.SearchWrapper>
                              {shoppingCartList && showEshopButton && <ShoppingCart /> }
                              {this.state.shoppingCartActive && (
                                <SC.CartDropDownList>
                                  {<ShoppingCartDropDown />}
                                </SC.CartDropDownList>
                              )}
                            </SC.RightHandColumn>
                          </ListItem>
                        </SC.MenuItemList>
                      </Column>
                    </Row>
                  </SC.GridDesktop>
                </SC.Header>
              </SC.HeaderContainer>
          </SC.HeaderWrapper>
        </SC.Headroom>

        {/* The mobile menu that toggles when clicking hamburger icon */}
        <SC.HamburgerGrid isMenuOpen={isMenuOpen}>
          <SC.HamburgerContent>
            <SC.MobileNavigationRowItem>
              <Column sm={12}>
                {/* Loop through all menu items */}

                {
                  showSearchBox && (
                    <SC.SearchFieldWrapper>
                      <TextField
                        placeholder={'Search'}
                        onBlur={this.handleSiteSearch}
                        onChange={this.handleSearchChange}
                        onKeyUp={this.handleSearch}
                        value={this.state.searchQuery}
                      />
                    </SC.SearchFieldWrapper>
                  )
                }

                <SC.MobileNavigationListing>
                  <MenuMobile menuItems={navigation} />
                </SC.MobileNavigationListing>
                {/* Mobile Navigation buttons */}
                <SC.MobileNavigationIconWrapper>
                  <SC.MobileNavigationIcons>
                    <SC.MobileNavigationListItem><IconBack onClick={this.handleBackClick} /></SC.MobileNavigationListItem>
                    <SC.MobileNavigationListItem><IconClose onClick={this.handleMenuClick} /></SC.MobileNavigationListItem>
                    <SC.MobileNavigationListItem><Anchor href={logo.linkURL}><IconHome /></Anchor></SC.MobileNavigationListItem>
                  </SC.MobileNavigationIcons>
                </SC.MobileNavigationIconWrapper>
              </Column>
            </SC.MobileNavigationRowItem>
          </SC.HamburgerContent>
        </SC.HamburgerGrid>

        <SC.HamburgerWrapper>
          {/* Hamburger Icon on bottom center */}
          {showHamburger && <HamburgerMenu />}
        </SC.HamburgerWrapper>
      </React.Fragment>
    );
  }
}

BusinessHeader.defaultProps = {
  logo: {
    image: '',
    alt: '',
    linkURL: '#'
  },
  navigation: [],
};

BusinessHeader.propTypes = {
  /** Logo */
  logo: PropTypes.object,
  /** Search URL */
  searchUrl: PropTypes.string,
  /** Show hamburger menu */
  showHamburger: PropTypes.bool,
  /** Site List */
  siteList: PropTypes.array,
  /** Navigation items */
  navigation: PropTypes.array,
  /** Shopping Cart List */
  shoppingCartList: PropTypes.array,
  /** Show search box? */
  showSearchBox: PropTypes.bool,
  /** Show eShop button? */
  showEshopButton: PropTypes.bool,
};

export default BusinessHeader;
