import styled from 'styled-components';

import {
  Theme
} from '@lux/components';

export default {
  PromotionTileImage: styled.div`
    padding: 0;
    position: relative;

    img {
      object-fit: cover;
      height: 100%;
      width: inherit;
    }
  `,
  PromotionTileWrapper: styled.div`
    position: relative;
    padding: 10px;
  `,
  PromotionTile: styled.div`
    min-height: 200px;
    height: 100%;
    ${Theme.styles.panelDropShadow};
    border-radius: 4px;
    background-color: ${Theme.colours.white};
    position: relative;
    padding: 20px;
    margin-bottom: 1rem;
  `,
  PromotionTileBodyWrapper: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  PromotionTileBody: styled.div`
    font-size: 25px;

    a {
      background: none;
      color: ${Theme.colours.singtelred};
      border: 2px solid;
    }
  `,
  PromotionCTAWrapper: styled.div`
    width: 50%;
  `,
  PromotionTitle: styled.h3`
    font-family: ${Theme.fonts.families.Museo300};
    margin-top: 0.5rem;

    @media screen and (min-width: 40em) {
      margin-top: 0;
    }
  `,
  PromotionPricePlan: styled.p`
    font-weight: bold;
    margin-bottom: 1.25rem;
  `,
  Stardeal: styled.div`
    position: absolute;
    top: 20px;
    left: 0;
    background: ${Theme.colours.singtelred};
    padding: 7px 10px 6px 10px;
    z-index: 50;
    color: ${Theme.colours.white};
    font-weight: bold;
    min-width: 115px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 12px;

    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 0;
      border-color: transparent ${Theme.colours.singtelred_d1} transparent transparent;
      content: '';
      left: 0;
      position: absolute;
      bottom: -10px;
    }
  `,
  LoadMoreWrapper: styled.div`
    text-align: center;
    margin: auto;
    width: 50%;

    a {
      background: none;
      color: ${Theme.colours.singtelred};
      border: 2px solid;
    }
  `
};
