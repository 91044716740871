import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Column,
  Heading,
  Button,
  Row,
  Label,
  TextField,
  RadioButtonGroup
} from '@lux/components';

import sc, { Overlay, Preloader } from './styles';
import { isValidMobileLandNumber, isValidEmail } from '@lux/helpers/';
import ErrorMessage from './ErrorMessage';

class GECheckUsageWidget extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      errorMsg: '',
      dataUsage: {},
      modalValue: false,
      unit: '',
      checkboxChangeValue: 'single',
      brn: '',
      svcNo: '',
      multiSvcNo: '',
      email: '',
      hasMadeChanges: false,
      postErrorMessage: '',
      updateResponse: '',
      errors: {},
      captchaReady: false,
      blockUI: false
    };
  }

  handleClick() {
    if (this.isFormValid()) {
      this.setState({
        errorMsg: '',
        postErrorMessage: '',
        errors: {},
        blockUI: true
      });
      window.grecaptcha.execute();
    }
  }

  fetchData(recaptchaResponse) {
    switch (this.state.checkboxChangeValue) {
      case 'single':
        this.fetchDataUsage(recaptchaResponse);
        break;
      case 'multiple':
        this.postMultiSvnNoData(recaptchaResponse);
        break;
    }
  }

  handleRadioButtonOnChange(typeValue) {
    this.setState({
      checkboxChangeValue: typeValue,
      modalValue: false,
      svcNo: '',
      multiSvcNo: '',
      email: ''
    });
  }

  handleInputChange = input => event => {
    this.setState({
      [input]: event.target.value
    });
  };

  checkBRN(brn) {
    let error = '';
    if (!brn || brn === '') {
      error = 'Please enter BRN.';
    }
    return error;
  }

  checkSvcNo(svcNo) {
    let error = '';
    if (!svcNo || svcNo === '') {
      error = 'Please enter service number.';
    } else if (!isValidMobileLandNumber(svcNo)) {
      error = 'Please enter a valid service number.';
    }
    return error;
  }

  checkMultiSvcNo(multiSvcNo) {
    let error = '';
    if (!multiSvcNo || multiSvcNo === '') {
      error = 'Please enter multiple service number.';
    } else {
      let tmpSvcNumber = multiSvcNo.split(';', -1);
      for (var i = 0; i < tmpSvcNumber.length; i += 1) {
        if (!isValidMobileLandNumber(tmpSvcNumber[i]) && tmpSvcNumber[i]) {
          error =
            'Please enter valid service number and separate by semicolon (;).';
          break;
        }
      }
    }
    return error;
  }

  checkEmail(email) {
    let error = '';
    if (!email || email === '') {
      error = 'Please enter email address.';
    } else if (!isValidEmail(email)) {
      error = 'Please enter a valid email address.';
    }
    return error;
  }

  handleOnBlur = input => () => {
    let errors = Object.assign({}, this.state.errors);
    switch (input) {
      case 'brn':
        errors.brn = this.checkBRN(this.state.brn);
        break;
      case 'svcNo':
        errors.svcNo = this.checkSvcNo(this.state.svcNo);
        break;
      case 'multiSvcNo':
        errors.multiSvcNo = this.checkMultiSvcNo(this.state.multiSvcNo);
        break;
      case 'email':
        errors.email = this.checkEmail(this.state.email);
        break;
    }
    this.setState({ errors });
  };

  fetchDataUsage(recaptchaResponse) {
    let postBody = {
      postData: {
        brn: this.state.brn && this.state.brn.trim(),
        svcNumbers: this.state.svcNo && this.state.svcNo.trim(),
        captcha: recaptchaResponse
      }
    };
    return fetch(this.props.checkUsageUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ postBody })
    })
      .then(response => response.json())
      .then(response => {
        if (response.result.status === '200') {
          this.setState({
            dataUsage: response.result,
            modalValue: true
          });
        } else {
          const errorMsgReturn = ['442', '400'].includes(response.result.status)
            ? response.result.message
            : 'Request failed, please try later.';
          this.setState({
            errorMsg: errorMsgReturn,
            modalValue: true
          });
        }
        window.grecaptcha.reset();
        this.setState({ blockUI: false });
      })
      .catch(() => {
        this.setState({
          modalValue: true,
          errorMsg: 'Request failed, please try later.'
        });
        window.grecaptcha.reset();
        this.setState({ blockUI: false });
      });
  }

  postMultiSvnNoData(recaptchaResponse) {
    let postBody = {
      postData: {
        brn: this.state.brn && this.state.brn.trim(),
        svcNumbers: this.state.multiSvcNo && this.state.multiSvcNo.trim(),
        email: this.state.email && this.state.email.trim(),
        captcha: recaptchaResponse
      }
    };
    return fetch(this.props.postDataUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ postBody })
    })
      .then(response => response.json())
      .then(response => {
        if (response.result.status === '200') {
          this.setState({
            updateResponse: response.result,
            modalValue: true,
            postErrorMessage: ''
          });
        } else {
          this.setState({
            updateResponse: response.result,
            modalValue: true,
            postErrorMessage: 'Request failed, please try again.'
          });
        }
        window.grecaptcha.reset();
        this.setState({ blockUI: false });
      })
      .catch(() => {
        this.setState({
          modalValue: true,
          postErrorMessage: 'Request failed, please try again.'
        });
        window.grecaptcha.reset();
        this.setState({ blockUI: false });
      });
  }

  renderContent() {
    let { errors } = this.state;

    switch (this.state.checkboxChangeValue) {
      case 'single':
        return (
          <sc.RowWrapper>
            <Column sm={12}>
              <Label htmlFor="svcNo">Service Number</Label>
              <TextField
                id="svcNo"
                value={this.state.svcNo}
                maxLength={8}
                placeholder="eg. 12345678"
                onChange={this.handleInputChange('svcNo')}
                onBlur={this.handleOnBlur('svcNo')}
                error={!!(errors.svcNo && errors.svcNo !== '')}
              />
              <ErrorMessage> {errors.svcNo} </ErrorMessage>
            </Column>
          </sc.RowWrapper>
        );
      case 'multiple':
        return (
          <sc.RowWrapper>
            <Column sm={12}>
              <Label htmlFor="multiSvcNo">
                Please use semicolon (;) to separate numbers, Max 50 numbers
              </Label>
              <TextField
                id="multiSvcNo"
                placeholder="eg. 12345678;87654321"
                value={this.state.multiSvcNo}
                maxLength={450}
                onChange={this.handleInputChange('multiSvcNo')}
                onBlur={this.handleOnBlur('multiSvcNo')}
                error={!!(errors.multiSvcNo && errors.multiSvcNo !== '')}
              />
              <ErrorMessage> {errors.multiSvcNo} </ErrorMessage>
              <Label htmlFor="email">
                Please provide the email address to receive result file{' '}
              </Label>
              <TextField
                id="email"
                placeholder="eg. abc@mail.com"
                value={this.state.email}
                onChange={this.handleInputChange('email')}
                onBlur={this.handleOnBlur('email')}
                error={!!(errors.email && errors.email !== '')}
              />
              <ErrorMessage> {errors.email} </ErrorMessage>
            </Column>
          </sc.RowWrapper>
        );
    }
  }

  injectReCaptcha = () => {
    window.onRecaptchaSubmit = function(reCaptchaResponse) {
      this.fetchData(reCaptchaResponse);
    }.bind(this);

    window.onloadCallback = function() {
      this.setState({
        captchaReady: true
      });
    }.bind(this);

    const script = document.createElement('script');

    script.async = true;
    script.defer = true;
    script.src =
      'https://www.google.com/recaptcha/api.js?onload=onloadCallback';

    if (document.head) {
      document.head.appendChild(script);
    }
  };

  removeReCaptcha() {
    while (this.recaptchaRef.firstChild) {
      this.recaptchaRef.removeChild(this.recaptchaRef.firstChild);
    }
    if (window.grecaptcha) {
      window.grecaptcha.reset();
      delete window['onRecaptchaSubmit'];
      delete window['onloadCallback'];
    }
  }

  componentDidMount() {
    // Inject recapctha
    this.injectReCaptcha();
  }

  componentWillUnmount() {
    //Remove recaptcha before unmounting
    this.removeReCaptcha();
  }

  isFormValid() {
    let { brn, svcNo, multiSvcNo, email, checkboxChangeValue } = this.state;

    let errors = {};
    errors.brn = this.checkBRN(brn);
    if (checkboxChangeValue === 'single') {
      errors.svcNo = this.checkSvcNo(svcNo);
    } else {
      errors.multiSvcNo = this.checkMultiSvcNo(multiSvcNo);
      errors.email = this.checkEmail(email);
    }
    this.setState({ errors });

    return Object.values(errors).filter(e => e.length !== 0).length === 0;
  }

  renderInteractionBlocker() {
    if (this.state.blockUI) {
      return (
        <Overlay>
          <Preloader show={true} />
        </Overlay>
      );
    }
  }
  render() {
    let { errors } = this.state;
    const ModalContent = () => {
      switch (this.state.checkboxChangeValue) {
        case 'single':
          if (this.state.errorMsg.length > 0) {
            return (
              <sc.RowWrapper>
                <Column sm={12}>
                  <ErrorMessage>{this.state.errorMsg}</ErrorMessage>
                </Column>
              </sc.RowWrapper>
            );
          } else {
            return (
              <React.Fragment>
                <sc.RowWrapper>
                  <Column sm={12}>
                    <Row>
                      <Column sm={3}>
                        <Heading level={6}>Last Update</Heading>
                      </Column>
                      <Column sm={3}>
                        <Heading level={6}>Data Usage</Heading>
                      </Column>
                      <Column sm={3}>
                        <Heading level={6}>Roaming Data Usage</Heading>
                      </Column>
                      <Column sm={3}>
                        <Heading level={6}>Next Bill</Heading>
                      </Column>
                    </Row>
                  </Column>
                </sc.RowWrapper>
                <sc.RowWrapper>
                  <Column sm={12}>
                    <Row>
                      <Column sm={3}>{this.state.dataUsage.lastupdated}</Column>
                      <Column sm={3}>{this.state.dataUsage.datausage}</Column>
                      <Column sm={3}>
                        {this.state.dataUsage.roamingdatausage}
                      </Column>
                      <Column sm={3}>{this.state.dataUsage.nextbill}</Column>
                    </Row>
                  </Column>
                </sc.RowWrapper>
              </React.Fragment>
            );
          }
        case 'multiple':
          if (this.state.postErrorMessage.length > 0) {
            return (
              <sc.RowWrapper>
                <Column sm={12}>
                  <ErrorMessage>{this.state.postErrorMessage}</ErrorMessage>
                </Column>
              </sc.RowWrapper>
            );
          } else {
            return (
              <sc.RowWrapper>
                <Column sm={12}>
                  <Label>
                    Usage Details file will send to {this.state.email} in a few
                    minutes.
                  </Label>
                </Column>
              </sc.RowWrapper>
            );
          }
      }
    };

    return (
      <sc.Container>
        <sc.Grid>
          <sc.RowWrapper>
            <Column sm={12}>
              <Heading level={1}>Check Data Usage</Heading>
            </Column>
          </sc.RowWrapper>
          <sc.RowWrapper>
            <Column sm={12}>
              <Heading level={6}>
                For Postpaid Mobile Broadband Data Plan Business customer:
              </Heading>
              <Label>
                Please login with your postpaid data number to check your data
                usage.
              </Label>
            </Column>
          </sc.RowWrapper>
          <sc.RowWrapper>
            <Column sm={12}>
              <Label htmlFor="brn">CRN/BRN/UEN</Label>
              <TextField
                id="brn"
                placeholder="eg. XXXXXXXXXD"
                value={this.state.brn}
                onChange={this.handleInputChange('brn')}
                onBlur={this.handleOnBlur('brn')}
                error={!!(errors.brn && errors.brn !== '')}
              />
              <ErrorMessage> {errors.brn} </ErrorMessage>
            </Column>
          </sc.RowWrapper>
          <sc.RowWrapper>
            <Column sm={12}>
              <RadioButtonGroup
                name="method"
                data={{
                  single: 'Single service number',
                  multiple: 'Multiple service numbers'
                }}
                value={this.state.checkboxChangeValue}
                onChange={value => this.handleRadioButtonOnChange(value)}
              />
            </Column>
          </sc.RowWrapper>
          {this.renderContent()}
          <sc.RowWrapper center="sm">
            <Column sm={12}>
              <Button
                disabled={!this.state.captchaReady}
                onClick={this.handleClick}
              >
                Submit
              </Button>
            </Column>
          </sc.RowWrapper>
          {this.renderInteractionBlocker()}
          {this.state.modalValue && <ModalContent />}
          <sc.RowWrapper>
            <div
              ref={ref => (this.recaptchaRef = ref)}
              className="g-recaptcha"
              data-sitekey={this.props.recaptchaSiteKey}
              data-callback="onRecaptchaSubmit"
              data-size="invisible"
            />
          </sc.RowWrapper>
        </sc.Grid>
      </sc.Container>
    );
  }
}

GECheckUsageWidget.defaultProps = {
  className: 'GECheckUsage'
};

GECheckUsageWidget.propTypes = {
  /** Class name */
  className: PropTypes.node,
  /** Children inside component */
  children: PropTypes.node
};

export default GECheckUsageWidget;
