import styled from 'styled-components';
import { Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  Card: styled.div`
    min-height: 200px;
    ${Theme.styles.panelDropShadow};
    border-radius: 4px;
    position: relative;
    padding: ${remCalc(38)};
    text-align: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    background: ${Theme.colours.white};
    color: ${Theme.colours.black};
  `,
  CardBody: styled.div`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: 1rem;
    line-height: 1.5rem;
    display: block;
    width: 100%;
  `,
  CardHeader__Wrapper: styled.div`
    flex: 1 0 auto;
    margin-bottom: ${remCalc(38)};
    position: relative;
  `,
  CardHeader: styled.div`
    margin-top: 0;
    font-family: ${Theme.fonts.families.Museo300};
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  `,
  SubscriptionPrice: styled.p`
    font-family: ${Theme.fonts.families.Museo500};
    font-size: 2.2rem;
    margin: 0;
    line-height: 1.3;
    display: inline-block;

    span {
      font-size: 1.3rem;
      margin-left: ${remCalc(5)};
    }
  `,
  Label: styled.p`
    font-size: 0.75rem;
    margin-bottom: 0;
    font-family: ${Theme.fonts.families.LatoBold};
  `,
  ExtraDetails: styled.div`
    margin-top: 1.2rem;

    p {
      margin-bottom: 0;
    }
  `
};
