import styled from 'styled-components';
import { Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  FeaturedDealCard: styled.div`
    img {
      height: 0;
    }
  `,
  FilterControls: styled.div`
    padding: ${remCalc(10)};
  `,
  FilterText: styled.p`
    margin-bottom: 5px;
    font-family: ${Theme.fonts.families.LatoBold};
    width: 100%;

    &::after {
      content: ':';
    }
  `,
  FilterButtonWrapper: styled.div`
    display: block;

    a {
      margin-right: 5px;
      background: none;
      border: 2px solid ${Theme.colours.singtelred};
      color: ${Theme.colours.singtelred};
      padding: ${remCalc(8.8)} ${remCalc(20.8)};
      position: relative;

      svg {
        display: none;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 15px;
        top: 38%;
        stroke: ${Theme.colours.white};
        stroke-width: 2;
      }
    }

    .isActive {
      background: ${Theme.colours.singtelred};
      color: ${Theme.colours.white};
      padding: ${remCalc(8.8)} ${remCalc(32)} ${remCalc(8.8)} ${remCalc(16)};

      svg {
        display: block;
      }
    }

    &.dark {
      a {
        color: ${Theme.colours.white};
        border: 2px solid ${Theme.colours.white};

        &:hover {
          border: 2px solid ${Theme.colours.white};
        }
      }
    }
  `,
  LoadMoreWrapper: styled.div`
    text-align: center;
    margin: auto;
  `
};
