import styled from 'styled-components';
import { Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  LightBox: styled.div`
    width: 100%;
    height: ${remCalc(640)};
  `,
  LightBoxWrapper: styled.div`
    background: ${Theme.colours.white};
    width: 80%;
    min-height: ${remCalc(640)};
    top: 50%;
    left: 50%;
    z-index: 102;
    position: fixed;
    transform: translate(-50%, -50%);
  `,
  CloseButton: styled.div`
    position: absolute;
    top: ${remCalc(20)};
    right: -${remCalc(25)};
    ${Theme.media.md`
      right: -${remCalc(40)};  
    `};
    cursor: pointer;
  `,
  iframe: styled.iframe`
    border: none;
  `,
  Overlay: styled.div`
    width: 100vw;
    height: 100vh;
    background: ${Theme.colours.black};
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  `
}
