import styled, { css } from 'styled-components';
import { remCalc } from "@lux/helpers";
import {
  Theme,
  Column
} from '@lux/components';

export default {
  AccordionTitle: styled.a`
    font-family: ${Theme.fonts.families.Museo300};
    font-size: 1.75rem;
    position: relative;
    display: block;
    border: 1px solid ${Theme.colours.grey_e5};
    border-bottom: 0;
    line-height: 1;
    color: initial;
  `,
  ColumnZeroPadding: styled(Column)`
    padding: 0;
  `,
  AccordionIcon: styled.span`
    position: absolute;
    right: ${remCalc(30)};
    top: 45%;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: ${Theme.colours.grey_a1};
      }
    }
  `,
  AccordionPanel: styled.div`
    display: block;
    padding: 1rem;
    border: 1px solid ${Theme.colours.grey_e5};
    background-color: ${Theme.colours.white};

    ${Theme.media.md`
      padding: ${remCalc(40)};
    `};
  `,
  AccordionPlanDetailsWrapper: styled.div`
    padding-right: ${remCalc(45)};

    * {
      font-size: 1rem;
    }

    ul,
    p {
      margin: ${remCalc(15)} 0;
    }

    ul {
      margin: ${remCalc(15)};
    }

    p {
      font-family: ${Theme.fonts.families.LatoBold};
      font-size: 12px;
      letter-spacing: 2px;
    }
  `,
  OptionDetails: styled.span`
    text-align: center;
    display: inline;
    margin-left: 5%;

    ${Theme.media.md`
      display: block;
    `};
  `,
  PlanTypes: styled.div`
    button {
      margin-bottom: ${remCalc(15)};
      width: 60%;

      ${Theme.media.md`
        margin-bottom: ${remCalc(5)};
        width: 100%;
      `};
    }
  `,
  PlanSummaryButtonGroupWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: ${remCalc(30)} 0;
  `,
  PlanSummary: styled.div`
    margin-top: ${remCalc(30)};
  `,
  PlanSummaryButtonGroup: styled.div`
    a {
      display: inline;

      &:first-child {
        margin-right: ${remCalc(30)};
      }
    }
  `,
  Total: styled.p`
    font-size: 1.5625rem;
    font-family: ${Theme.fonts.families.Museo300};
    line-height: 2;
    margin-bottom: 0;
  `,
  LargerFont: styled.p`
    font-family: ${Theme.fonts.families.Museo300};
    font-size: 1.5625rem;
    line-height: 1;
  `,
  TotalPrice: styled.p`
    font-size: 1.5625rem;
    margin: 0;

    &::before {
      content: '$';
    }
  `,
  CalculatorInnerWrapper: styled.div`
    border: 1px solid ${Theme.colours.grey_e5};
    padding: ${remCalc(30)};
    height: 100%;

    p {
      margin-bottom: 0;
    }

    ${Theme.media.md`
      padding: ${remCalc(30)} ${remCalc(55)};
    `};
  `,
  CalculatorWrapper: styled.div`
    padding: 0 0.625rem;
  `,
  LightSection: styled.div`
    background-color: ${Theme.colours.grey_e5};
    padding: ${remCalc(30)};
    height: 100%;
    width: 100%;
    position: relative;

    ${Theme.media.md`
      padding: ${remCalc(30)} ${remCalc(55)};
    `};
  `,
  AccordionHeader: styled.div`
    padding: ${remCalc(85)};
    height: 100%;
    background: ${Theme.colours.grey};
  `,
  VerticalAlignMiddle: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
  `,
  FlexColumn: styled.div`
    flex: 1 1 100%;
    margin: 0.625rem 0.625rem 0 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ${Theme.media.md`
      flex: 1 1 15%;
    `};
  `,
  ActivePlan: styled.p`
    font-family: ${Theme.fonts.families.LatoBold};
    display: block;

    ${Theme.media.md`
      display: none;
    `};
  `,
  TabButton: styled.button`
    border-radius: ${remCalc(4)};
    margin-bottom: ${remCalc(15)};
    font-size: 22px;
    color: ${Theme.colours.darkslate};
    font-family: ${Theme.fonts.families.Museo300};
    padding: 18px;
    text-transform: capitalize;
    width: 100%;
    background: ${Theme.colours.white};
    border: 1px solid ${Theme.colours.grey_d6};

    ${props => {
      return props.isSelected && props.isPlanOption && css`
        border: 2px solid ${Theme.colours.linkblue};
      `
    }}

    ${props => {
      return (props.isSelected || props.isActive) && !props.isPlanOption && css`
        border: 2px solid ${Theme.colours.linkblue};
      `
    }}

    ${props => {
      return props.isActive && !props.isPlanOption && css`
        background: none;
      `
    }}

    p {
      margin: 0;
    }

    &:hover,
    &:active {
      color: ${Theme.colours.black};
      background: ${props => { return (props.isSelected || props.isActive) ? `${Theme.colours.white}` : `${Theme.colours.powderblue}`}};
    }

    &:focus {
      outline: none;
      color: ${Theme.colours.black};
      background: ${Theme.colours.white};
    }
  `,
  OptionPrice: styled.span`
    display: block;
    font-size: 1rem;

    &::before {
      content: '+$';
    }
  `
};
