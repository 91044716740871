import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Column, Heading, Anchor } from '@lux/components';
import { getUrlParams } from '@lux/helpers';
import SC from './styles';

export const MSG = {
  WENTWRONG:
    'Sorry, something went wrong. Please try searching again by clicking Change my selection.'
};

const FilterSettings = props => {

  const handleScreenDataNew = () => {
    const questionsPrams = getUrlParams(window.location.href);
    let questions;
    if (Object.keys(questionsPrams).length === 0) {
      questions = [];
    } else {
      questions = [
        {
          param: questionsPrams.q1 ? questionsPrams.q1.split(',') : [],
          hide: questionsPrams.s1
        },
        {
          param: questionsPrams.q2 ? questionsPrams.q2.split(',') : [],
          hide: questionsPrams.s2
        },
        {
          param: questionsPrams.q3 ? questionsPrams.q3.split(',') : [],
          hide: questionsPrams.s3
        },
        {
          param: questionsPrams.q4 ? questionsPrams.q4.split(',') : [],
          hide: questionsPrams.s4
        },
        {
          param: questionsPrams.q5 ? questionsPrams.q5.split(',') : [],
          hide: questionsPrams.s5
        }
      ]
    }

    let newObj = {};
    if (questions.length > 0) {

      const selectedProfile = matchedLabelsNew(questions, questionsPrams);
      newObj = {
        ...selectedProfile
      };
    } else {
      newObj = {
        error: {
          msg: MSG.WENTWRONG
        }
      };
    }
    return newObj;
  };

  const matchedLabelsNew = (questions, questionsPrams) => {
    let qanswer = [];
    const propAnswers = props.profilingFilter.questionItemList.map((listItem) => {
      return listItem.answersList
    })

    qanswer = questions.map((index, i) => {
      return index.param.map((param) => {
        return (propAnswers[i].filter(item => item.value == param))[0];
      })
    })

    /**
     * checking description
     * replace description content with latest matching
     */
    let description;
    questions.forEach((answerItem, index) => {
      if (answerItem.param.length) {
        answerItem.param.forEach((singleAnswer) => {
          const result = props.resultDescriptionList.filter((item) => item.resultDescriptionDependency === singleAnswer);
          if (result.length) {
            description = result;
          }
        })
      } else {
        const result = props.resultDescriptionList.filter((item) => item.resultDescriptionDependency === questions[index].param[0]);
        if (result.length) {
          description = result;
        }
      }
    })

    const selectedProfile = {
      selectedQuestion1: qanswer.length >= 1 && qanswer[0] ? qanswer[0] : {},
      selectedQuestion2: qanswer.length >= 2 && qanswer[1] ? qanswer[1] : {},
      selectedQuestion3: qanswer.length >= 3 && qanswer[2] ? qanswer[2] : {},
      selectedQuestion4: qanswer.length >= 4 && qanswer[3] ? qanswer[3] : {},
      selectedQuestion5: qanswer.length >= 5 && qanswer[4] ? qanswer[4] : {},
      selectedDescription: description.length ? description[0].resultDescription : "",
      url: questionsPrams
    };

    // Return error obj for no match found
    if (
      selectedProfile.selectedQuestion1.length == 0 ||
      selectedProfile.selectedQuestion2.length == 0 ||
      selectedProfile.selectedDescription == ''
    ) {
      return {
        error: {
          msg: MSG.WENTWRONG
        }
      };
    }

    return {
      ...selectedProfile
    };
  };

  const selectedProfile = handleScreenDataNew();
  const {
    changeProfilingUrl,
    questionTitleList,
    resultDescriptionList,
    profilingFilter,
    formLayout,
    hiddenLink,
    hiddenResultDescription
  } = props;

  return (
    <Grid>
      <SC.DetailsWrapperRow>
        <Column>
          {!selectedProfile.error && profilingFilter ? (
            <>
              <SC.SelectedSection id="itemWrapper">
                {selectedProfile.selectedQuestion1.length ?

                  <Column sm={12} md={formLayout ? 12 : 6} >

                    {formLayout ?

                      <SC.LayoutContainer>
                        <SC.Title>{questionTitleList[0]}</SC.Title>
                        <SC.Content>
                          {selectedProfile.selectedQuestion1.map((answerItem) => {
                            return (
                              " " + answerItem.title
                            );
                          })}</SC.Content>
                      </SC.LayoutContainer>
                      :
                      <Column sm={12} md={12}>
                        <Heading level={6}>{questionTitleList[0]}</Heading>
                        {selectedProfile.selectedQuestion1.map((answerItem, itemIndex) => {
                          return (

                            <Heading level={3} key={itemIndex}>
                              {answerItem.title}
                            </Heading>
                          );
                        })}
                      </Column>

                    }

                  </Column>
                  : false}
                {selectedProfile.selectedQuestion2.length > 0 ?
                  <Column sm={12} md={formLayout ? 12 : 6}>
                    {formLayout ?

                      <SC.LayoutContainer>
                        <SC.Title>{questionTitleList[1]}</SC.Title>
                        <SC.Content>
                          {selectedProfile.selectedQuestion2.map((answerItem) => {
                            return (
                              " " + answerItem.title
                            );
                          })}</SC.Content>
                      </SC.LayoutContainer>
                      :
                      <Column sm={12} md={12}>
                        <Heading level={6}>{questionTitleList[1]}</Heading>
                        {selectedProfile.selectedQuestion2.map((answerItem, itemIndex) => {
                          return (

                            <Heading level={3} key={itemIndex}>
                              {answerItem.title}
                            </Heading>
                          );
                        })}
                      </Column>

                    }
                  </Column>
                  : false}
                {selectedProfile.selectedQuestion3.length > 0 ?
                  <Column sm={12} md={formLayout ? 12 : 6}>
                    {formLayout ?

                      <SC.LayoutContainer>
                        <SC.Title>{questionTitleList[2]}</SC.Title>
                        <SC.Content>
                          {selectedProfile.selectedQuestion3.map((answerItem) => {
                            return (
                              " " + answerItem.title
                            );
                          })}</SC.Content>
                      </SC.LayoutContainer>
                      :
                      <Column sm={12} md={12}>
                        <Heading level={6}>{questionTitleList[2]}</Heading>
                        {selectedProfile.selectedQuestion3.map((answerItem, itemIndex) => {
                          return (

                            <Heading level={3} key={itemIndex}>
                              {answerItem.title}
                            </Heading>
                          );
                        })}
                      </Column>

                    }
                  </Column>
                  : false}
                {selectedProfile.selectedQuestion4.length > 0 ?
                  <Column sm={12} md={formLayout ? 12 : 6}>
                    {formLayout ?

                      <SC.LayoutContainer>
                        <SC.Title>{questionTitleList[3]}</SC.Title>
                        <SC.Content>
                          {selectedProfile.selectedQuestion4.map((answerItem) => {
                            return (
                              " " + answerItem.title
                            );
                          })}</SC.Content>
                      </SC.LayoutContainer>
                      :
                      <Column sm={12} md={12}>
                        <Heading level={6}>{questionTitleList[3]}</Heading>
                        {selectedProfile.selectedQuestion4.map((answerItem, itemIndex) => {
                          return (

                            <Heading level={3} key={itemIndex}>
                              {answerItem.title}
                            </Heading>
                          );
                        })}
                      </Column>

                    }
                  </Column>
                  : false}
                {selectedProfile.selectedQuestion5.length > 0 ?
                  <Column sm={12} md={formLayout ? 12 : 6}>
                    {formLayout ?

                      <SC.LayoutContainer>
                        <SC.Title>{questionTitleList[4]}</SC.Title>
                        <SC.Content>
                          {selectedProfile.selectedQuestion5.map((answerItem) => {
                            return (
                              " " + answerItem.title
                            );
                          })}</SC.Content>
                      </SC.LayoutContainer>
                      :
                      <Column sm={12} md={12}>
                        <Heading level={6}>{questionTitleList[4]}</Heading>
                        {selectedProfile.selectedQuestion5.map((answerItem, itemIndex) => {
                          return (

                            <Heading level={3} key={itemIndex}>
                              {answerItem.title}
                            </Heading>
                          );
                        })}
                      </Column>

                    }
                  </Column>
                  : false}
              </SC.SelectedSection>
              <Row>
                <Column>
                  {hiddenResultDescription ? false :
                    <p>{resultDescriptionList.length > 0 ? selectedProfile.selectedDescription : ''}</p>
                  }
                  <p>
                    {hiddenLink ? false :
                      <Anchor id="anchor"
                        href={`${changeProfilingUrl}?${
                          selectedProfile.url.q1 ? 'q1=' + selectedProfile.url.q1 : ''
                          }${
                          selectedProfile.url.q2 ? '&q2=' + selectedProfile.url.q2 : ''}${
                          selectedProfile.url.q3 ? '&q3=' + selectedProfile.url.q3 : ''}${
                          selectedProfile.url.q4 ? '&q4=' + selectedProfile.url.q4 : ''}${
                          selectedProfile.url.q5 ? '&q5=' + selectedProfile.url.q5 : ''}`}
                      >
                        Change my selection
                        </Anchor>
                    }
                  </p>
                </Column>
              </Row>
            </>
          ) : (
              <Row>
                <SC.ErrorContainer id="errorWrapper">
                  <p>{selectedProfile.error.msg}</p>
                  <p>
                    {hiddenLink ? false :
                      <Anchor href={changeProfilingUrl}>Change my selection</Anchor>
                    }
                  </p>
                </SC.ErrorContainer>
              </Row>
            )}
        </Column>
      </SC.DetailsWrapperRow>
    </Grid>

  );
};

FilterSettings.propTypes = {
  /** Description below the selected profile 
   * [{
      resultDescriptionDependency:setup-new-office,
      resultDescription:Based on your selection above, result description 1;
    }]
  */
  resultDescriptionList: PropTypes.array,
  /** Profiling URL */
  changeProfilingUrl: PropTypes.string,
  /** Description List */
  profilingFilter: PropTypes.object,
  /** QuestionTitleList */
  questionTitleList: PropTypes.array,
  /** formLayout */
  formLayout: PropTypes.bool,
  /** hiddenLink */
  hiddenLink: PropTypes.bool,
  /** hiddenResultDescription */
  hiddenResultDescription: PropTypes.bool
};

export default FilterSettings;
