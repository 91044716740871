import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SC from './styles';

class RoamingRatesTable extends Component {

  render() {

    const TableRows = rowData => {
      return rowData.row.map((cell, colIndex) => {
        return (
          !(this.props.hideCols || []).includes(colIndex)
            ? <SC.StyledTD isPreferredOperator={rowData.isPreferredOperator} key={colIndex}>
                {
                  typeof cell === 'boolean'
                    ? cell ? 'Y' : 'N'
                    : cell
                }
              </SC.StyledTD>
            : null
        )
      })
    };

    return (
      <SC.Table>
        {
          <thead>
            <tr>
              {
                this.props.tableHeaders ?
                  this.props.tableHeaders.map((header, colIndex) => {
                    return (
                      !(this.props.hideCols || []).includes(colIndex) ?
                        <SC.TableHead key={colIndex}>{header}</SC.TableHead>
                      : null
                    )
                  })
                : null
              }
            </tr>
          </thead>
        }
        <tbody>
          {
            this.props.tableRows.map((rowData) => {

              const rowHeader = rowData.rowHeader;
              const rows = rowData.rows;

              return (

                rows.map((row, rowIndex) => {

                  const mergedRowLength = rowData.rows.length;
                  const isPreferredOperator = row[3];

                  return (
                    <tr key={rowIndex}>
                      {
                        rowIndex === 0 && rowHeader
                        ?
                          <React.Fragment>
                            <td rowSpan={mergedRowLength}>{rowHeader}</td>
                            <TableRows row={row} isPreferredOperator={isPreferredOperator}/>
                          </React.Fragment>
                        :
                          <TableRows row={row} isPreferredOperator={isPreferredOperator}/>
                      }
                    </tr>
                  )

                })

              )
            })
          }
        </tbody>
      </SC.Table>
    )
  }
}

export default RoamingRatesTable;

RoamingRatesTable.propTypes = {
  /* array of table rows */
  tableRows: PropTypes.array,
  /* array of table headers */
  tableHeaders: PropTypes.array,
  /* table columns that should be hidden
  e.g. [0, 1, 3] => columns first, second and fourth column will be hidden */
  hideCols: PropTypes.array
};
