import styled, { css } from "styled-components";

import { Row, Grid, Theme } from "@lux/components";
import { Anchor } from "@lux/components";
import Heading from "./Heading";
import { remCalc } from "@lux/helpers";
import BusinessTheme from "../../config";

const widthLength = {
  small: remCalc(5),
  medium: remCalc(10),
  large: remCalc(40),
};

const textAlignRow = {
  left: "start",
  center: "center",
  right: "end",
};

const justifyContentRow = {
  left: "flex-start",
  center: "center",
  right: "flex-end;",
};
export default {
  StyledLinkRow: styled(Row)`
    margin-left: ${(props) => widthLength[props.leftSpacing] || 0};
    justify-content: center;
    ${Theme.media.md`
    justify-content: flex-start;
    `}
  `,
  StyledRowAlign: styled(Row)`
    justify-content: center;
    text-align: center;
    ${Theme.media.md`
    text-align: ${(props) =>
      props.align ? textAlignRow[props.align] : "start"};
    justify-content: ${(props) =>
      props.align ? justifyContentRow[props.align] : "flex-start"};

    `}
  `,
  StyledAnchor: styled(Anchor)`
    cursor: pointer;
    ${(props) => {
      return (
        props.theme === "dark" &&
        css`
          color: ${Theme.colours.white};

          &:not([disabled]) {
            &:hover {
              opacity: 0.7;
              color: ${Theme.colours.white};
            }
          }
        `
      );
    }};
  `,
  StyledHeading: styled(Heading)`
    line-height: 1;

    color: ${(props) =>
      props.theme === "dark"
        ? Theme.colours.white
        : BusinessTheme.colours.bodycopy};
    ${(props) => {
      switch (props.headingstyle) {
        case "product-title":
          return `font-family: ${Theme.fonts.families.Museo300};
                font-size:${remCalc(22)};
                @media screen and (max-width: 48em) {
                  font-size:${remCalc(21)};
                }
                letter-spacing: .01rem;
                `;
        case "main-dollar":
          return `font-family: ${Theme.fonts.families.Museo500};
                  font-size: 3rem;
                  @media screen and (max-width: 48em) {
                    font-size:2rem;
                  }`;
        case "hero-text":
          return `font-family: ${Theme.fonts.families.Museo100};
                  font-size: 3rem;
                  @media screen and (max-width: 48em) {
                    font-size:2rem;
                  }`;
        case "section-header":
          return `font-family: ${Theme.fonts.families.Museo300};
                  line-height: ${remCalc(28)};
                  letter-spacing: .01rem;
                  font-size:${remCalc(28)};
                  @media screen and (max-width: 48em) {
                    font-size:${remCalc(21)};
                  }
                  `;
        case "sub-section-header":
          return `font-family: ${Theme.fonts.families.LatoBold};
                  line-height: ${remCalc(28)};
                  font-size: ${remCalc(18)};
                  @media screen and (max-width: 48em) {
                    font-size:${remCalc(16)};
                  }`;
        case "badges-text":
          return `font-family: ${Theme.fonts.families.LatoBold};
                  text-decoration: none;
                  text-transform: uppercase;
                  font-size: ${remCalc(12)};
                  line-height: 1.3;
                  `;
        default:
          return `font-family: ${Theme.fonts.families.Museo300};
                  line-height: 1;
                  font-size: 3rem;
                  @media screen and (max-width: 48em) {
                    font-size:2rem;
                  }
                  margin: 0;
                          `;
      }
    }};
  `,

  StyledGrid: styled(Grid)`
    margin-left: ${(props) => widthLength[props.textLeftSpacing] || 0};
    margin-right: ${(props) => widthLength[props.textRightSpacing] || 0};
    margin-top: ${(props) => widthLength[props.textTopSpacing] || 0};
    margin-bottom: ${(props) => widthLength[props.textBottomSpacing] || 0};
  `,
  StyledText: styled.div`
    ul {
      padding-left: ${remCalc(27)};

      li {
        list-style-type: disc;
      }
    }
    color: ${(props) =>
      props.theme === "dark"
        ? Theme.colours.white
        : BusinessTheme.colours.bodycopy};

    a {
      cursor: pointer;

      ${(props) => {
        return css`
          color: ${props.theme === "dark"
            ? Theme.colours.white
            : Theme.colours.linkblue};

          :hover {
            opacity: ${props.theme === "dark" ? 0.7 : 1};
            color: ${props.theme === "dark"
              ? Theme.colours.white
              : Theme.colours.powderblue_ee};
          }
        `;
      }}
    }
    ${(props) => {
      switch (props.textStyle) {
        case "product-title":
          return `font-family: ${Theme.fonts.families.Museo300};
                `;
        case "sub-section-header":
          return `font-family: ${Theme.fonts.families.LatoBold};
          letter-spacing: .015em;
                `;
        case "badges-text":
          return `font-family: ${Theme.fonts.families.LatoBold};
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: .15em;
                `;
        case "body-copy-text":
          return ` font-size: ${remCalc(16)};
                font-family: ${Theme.fonts.families.LatoRegular};
                letter-spacing: 0.01rem;
                line-height: 1.6;
                `;
        case "subscript":
          return `font-family: ${Theme.fonts.families.LatoBold};
                font-size: .75rem;;
                text-transform: uppercase;
                letter-spacing: .15em;`;
        case "fine-prints":
          return `
                  font-family: ${Theme.fonts.families.LatoRegular};
                  letter-spacing: 0.01rem;
                  font-size: 1rem;
                  line-height: 1.6;`;
        default:
          return `font-family: ${Theme.fonts.families.Museo300};
                `;
      }
    }};
  `,
  StyledContainer: styled(Grid)`
    color: ${(props) =>
      props.theme === "dark"
        ? Theme.colours.white
        : BusinessTheme.colours.bodycopy};
  `,
};
