import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Equalizer from 'react-equalizer';

import { isNarrowViewport } from '@lux/components/src/helpers';
import RelatedProductCard from '../RelatedProductCard';
import { Grid, Row, Column } from '@lux/components';

class RelatedProductList extends Component {

  refCards = [];

  getCardTitleNodes() {
    return this.refCards.map(card => { return card.refTitle });
  }

  getCardDescriptionNodes() {
    return this.refCards.map(card => { return card.refDescription });
  }

  enableEqualizer() {
    return !isNarrowViewport();
  }

  render() {

    return (
      <Grid>
        <Row>{
          this.props.productList.map((item, key) => {
              return (
                <Column sm={12} md={4} lg={4} key={key}>
                  <Equalizer
                    enabled={this.enableEqualizer}
                    nodes={this.getCardTitleNodes.bind(this)}
                  >
                    <Equalizer
                      enabled={this.enableEqualizer}
                      nodes={this.getCardDescriptionNodes.bind(this)}
                    >
                      <RelatedProductCard
                        index={key}
                        {...item}
                        ref={node => {(this.refCards[key] = node)}}
                      />
                    </Equalizer>
                  </Equalizer>
                </Column>
              )
            })
        }</Row>
      </Grid>
    );
  }
}

RelatedProductList.defaultProps = {
  productList: []
};

RelatedProductList.propTypes = {
  productList: PropTypes.array,
};

export default RelatedProductList;
