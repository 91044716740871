import styled from 'styled-components';
import {
  Theme
} from '@lux/components';

export default {
  ListViewWrapper: styled.div`
    display: block;

    &:last-child {
      border-bottom: 1px solid ${Theme.colours.grey_c6};
    }

    ${Theme.media.md`
      display: none;
    `};
  `,
  OfficeListWrapper: styled.div`
    background: ${Theme.colours.white};
  `,
  AccordionPanel: styled.div`
    height: auto;
    opacity: 1;
    overflow: auto;
    transition: opacity 1s, transform 0.5s;
    width: 100%;
    border: 1px solid ${Theme.colours.grey_c6};
  `,
  AccordionHeader: styled.button`
    display: block;
    appearance: none;
    width: 100%;
    text-align: left;
    padding: 1em 2em;
    position: relative;
    background: ${Theme.colours.white};
    color: ${Theme.colours.black};
    border: 1px solid ${Theme.colours.grey_c6};
    border-bottom: none;
    cursor: pointer;
    margin-bottom: 0;

    svg {
      position: absolute;
      right: 15px;
    }

    &:focus {
      outline: none;
      background: ${Theme.colours.grey_c6};
    }
  `
}
