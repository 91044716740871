import styled from 'styled-components';
import { Theme } from '@lux/components';
import SolutionsCategoryColors from '../../config';

export default {
  SolutionsCategoryContainer: styled.div`
    width: 100%;
    overflow: hidden;
  `,
  SolutionsCategoryWrapper: styled.section`
    display: block;
    width: 100%;

    ${Theme.media.lg`
      display: flex;
    `};

    a {
      height: auto;
      width: 100%;
      padding: 50px 30px;
      position: relative;

      ${Theme.media.md`
        width: 50%;
        height: 300px;
        float: left;
      `};

      ${Theme.media.lg`
        height: 500px;
      `};

      &::before {
        top: 0;
        left: 0;
        z-index: 1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${Theme.colours.black};
        opacity: 0.1;
      }

      &:hover {
        &::before {
          opacity: 0.3;
        }
      }
    }

    &:hover {
      a:not(:hover) {
        &::before {
          opacity: 0.67;
        }
      }
    }
  `,
  SolutionsCategoryColumn: styled.a`
    height: 500px;
    width: 20%;
    flex: 1;
    transition: flex 0.3s;
    padding: 2%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &.red {
      background-color: ${SolutionsCategoryColors.colours.red};
    }

    &.orange {
      background-color: ${SolutionsCategoryColors.colours.orange};
    }

    &.blue {
      background-color: ${SolutionsCategoryColors.colours.blue};
    }

    &.light-blue {
      background-color: ${SolutionsCategoryColors.colours.lightBlue};
    }

    &.grey {
      background-color: ${SolutionsCategoryColors.colours.grey};
    }

    &.dark-grey {
      background-color: ${SolutionsCategoryColors.colours.darkGrey};
    }

    ${Theme.media.lg`
      &:hover {
        flex: 1.5;

        h4 {
          min-height: auto;
        }

        p {
          display: block;
        }
      }
    `};

    &.showImage {
      img {
        display: none;
      }

      &:hover {
        img {
          display: inline-block;
          margin-bottom: 0;
        }
      }

      div {
        align-self: flex-end;
      }
    }
  `,
  Image: styled.img`
    vertical-align: top;
    border: none;
    max-width: 6.25rem;
    margin-bottom: 10px;

    ${Theme.media.lg`
      margin-bottom: 40px;
    `};
  `,
  SolutionsCategoryContent: styled.div`
    vertical-align: bottom;
    z-index: 2;
  `,
  SolutionsCategoryTitle: styled.h4`
    font-family: ${Theme.fonts.families.Museo300};
    font-size: ${Theme.fonts.sizes.xxlarge};
    line-height: 35px;
    margin-bottom: 15px;
    min-height: auto;
    color: ${Theme.colours.white};

    ${Theme.media.lg`
      min-height: 70px;
    `};
  `,
  SolutionsCategoryDescription: styled.p`
    font-family: ${Theme.fonts.families.LatoRegular};
    color: ${Theme.colours.white};
    display: block;

    ${Theme.media.lg`
      display: none;
    `};
  `
};
