import styled from 'styled-components';
import { Row, Grid, Panel, Theme, Spinner } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  RowWrapper: styled(Row)`
    margin-top: ${remCalc(10)};
  `,
  Grid: styled(Grid)`
    margin: ${remCalc(10)};
    padding-right: ${remCalc(20)};
  `,
  Container: styled(Panel)`
    max-width: 560px;
    margin: 0 auto;
    padding: 8px;
    position: relative;

    ${Theme.media.sm`
      min-height: 100vh;
      max-width: 100vw;
    `};

    ${Theme.media.md`
      min-height: auto;
      max-width: 560px;
      margin-top: 20px;
      padding: 20px;
    `};
  `
};
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
`;

export const Preloader = styled(Spinner)`
  height: ${remCalc(48)};
  width: ${remCalc(48)};
`;
