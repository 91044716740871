import styled from 'styled-components';
import { Theme, Column, Row, Anchor, Heading } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  ProductsWrapper: styled(Row)`
    background: ${Theme.colours.white};
    padding: ${Theme.spacing.small};
  `,
  SectionWrapper: styled(Row)`
    width: 100%;
  `,
  ProductsTitle: styled(Heading)`
    text-align: center;
    padding: ${Theme.spacing.small} 0 ${Theme.spacing.medium};
  `,
  ProductBox: styled.div`
    ${Theme.media.md`
      width: 48%;
      max-width: 100%;
      margin: 0 ${Theme.spacing.xsmall} ${Theme.spacing.small} 0;
    `};
    display: ${prop => (prop.show ? 'inline' : 'none')};
    background: ${Theme.colours.grey_f5};
    margin: 0 auto ${Theme.spacing.small};
    padding: 0;
    width: 100%;
    max-width: ${remCalc(380)};
  `,
  Row: styled(Row)`
    margin: 0;
  `,
  ProductTitle: styled(Heading)`
    padding: ${Theme.spacing.small} 0 ${Theme.spacing.xsmall};
  `,
  ProdImgWrapper: styled(Column)`
    ${Theme.media.md`
      width: 100%;
      height: ${remCalc(217)};
    `} padding: 0;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: ${remCalc(247)};
  `,
  ProductImg: styled.img`
    ${Theme.media.md`
      width: auto;
      height: ${remCalc(217)};
    `} width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: ${remCalc(247)};
  `,
  DescriptionContainer: styled(Column)`
    padding: ${Theme.spacing.small} ${Theme.spacing.small}
      ${Theme.spacing.medium};
    position: relative;
    text-align: left;
  `,
  Link: styled(Anchor)`
    position: absolute;
    bottom: ${Theme.spacing.small};
    left: ${Theme.spacing.small};
  `,
  ProductName: styled(Heading)`
    margin-bottom: 0;
  `,
  ProductDescription: styled.p`
    margin-top: 0;
  `,
  NoProducts: styled.p`
    padding: 0;
    text-align: center;
  `,
  ButtonContainer: styled(Row)`
    text-align: center;
    width: 100%;
  `
};
