import styled from 'styled-components';
import { Theme } from '@lux/components';

export default {
  Autocomplete: styled.div`
    max-width: 514px;
    margin: auto;
    position: relative;
    font-size: 16px;

    .autocomplete__input {
      margin: 0;
      font-size: 1rem;
      padding: 8px 12px;
    }

    .suggestion-active {
      background: ${Theme.colours.grey_f5};
    }
  `,
  SuggestionList: styled.ul`
    margin: 0;
    z-index: 2;
    list-style: none;
    width: 100%;
    position: absolute;
    ${Theme.styles.panelDropShadow};
  `,
  SuggestionItem: styled.li`
    padding: 0.75rem;
    cursor: pointer;
    background: ${Theme.colours.white};
    color: ${Theme.colours.black};

    &:hover {
      background: ${Theme.colours.grey_f5};
    }
  `
};
