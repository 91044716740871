import styled from 'styled-components';
import {
  Theme
} from '@lux/components';
import {
  remCalc
} from '@lux/helpers';

export default {
  MapWrapper: styled.div`
    position: relative;
  `,
  MapListToggle: styled.a`
    position: absolute;
    top: ${remCalc(15)};
    right: ${remCalc(30)};
    z-index: 1;
    background: ${Theme.colours.white};
    border-radius: ${remCalc(2.5)};
    border: 1px solid ${Theme.colours.grey_c6};
    font-family: ${Theme.fonts.families.LatoRegular};
    padding: ${remCalc(8)} ${remCalc(12)};
    height: ${remCalc(40)};
    font-size: ${remCalc(16)};
    color: ${Theme.colours.black};

    &:hover {
      background: ${Theme.colours.grey_c6};
      color: ${Theme.colours.white};
    }
  `,
  GoogleMapsWrapper: styled.div`
    display: none;

    .googleMap {
      > div {
        position: absolute;
        top: 0;
        left: 0;
      }

      &::before {
        content: '';
        width: 100%;
        padding-bottom: 50%;
        display: block;
      }
    }

    ${Theme.media.md`
      display: block;
    `};
  `
};
