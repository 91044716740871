import React from 'react';
import styled from 'styled-components';
import { Theme } from '@lux/components';

const StyledError = styled.div`
  font-family: ${Theme.fonts.families.LatoRegular};
  color: ${Theme.colours.rederror};
  font-size: ${Theme.fonts.sizes.small};
  margin-bottom: 5px;
`;
const ErrorMessage = props => {
  return <StyledError>{props.children}</StyledError>;
};

export default ErrorMessage;
