import React, { Component } from 'react';
import {
  Grid,
  Column,
  Row,
  CatalogueCard,
  Button } from '@lux/components';
import Equalizer from 'react-equalizer';
import PropTypes from 'prop-types';
import IconClose from '../../assets/svgs/close.svg';
import SC from './styles';
import { isNarrowViewport } from '@lux/components/src/helpers';

class FeaturedBusinessDeals extends Component {

  refCards = [];

  state = {
    businessDeals: [],
    activeFilters: []
  };

  componentDidMount() {
    const { businessDeals, numDealsOnLoad } = this.props;

    this.setState({
      totalDeals: businessDeals.deals.length,
      filteredDeals: businessDeals.deals.slice(0, numDealsOnLoad),
      activeFilters: []
    })
  }

  resetFilters() {
    const { businessDeals, numDealsOnLoad } = this.props;

    this.setState({
      totalDeals: businessDeals.deals.length,
      filteredDeals: businessDeals.deals.slice(0, numDealsOnLoad)
    })
  }

  handleFilter = filter => {

    const { numDealsOnLoad } = this.props;
    const activeFilters = [...this.state.activeFilters];

    this.setState({
      activeFilters: activeFilters.includes(filter)
                      ? activeFilters.filter(x => { return x !== filter })
                      : [...this.state.activeFilters, filter]
    }, () => {
      if(this.state.activeFilters.length === 0) {

        this.resetFilters();

      } else {

        this.filterDeals(numDealsOnLoad)

      }
    })
  }

  filterDeals = dealsToRender => {
    const { businessDeals } = this.props;
    const { activeFilters } = this.state;


    let filteredDeals;

    if(activeFilters.length > 0) {
      filteredDeals = businessDeals.deals
                      .filter((deals) => deals.categories
                      .some((category) => activeFilters.includes(category)))
    } else {
      filteredDeals = businessDeals.deals
    }


    this.setState({
      totalDeals: filteredDeals.length,
      filteredDeals: filteredDeals.slice(0, dealsToRender)
    })
  }

  loadMoreDeals = () => {
    const { filteredDeals } = this.state;
    const { dealsToLoadOnClick } = this.props;

    this.filterDeals(filteredDeals.length + dealsToLoadOnClick);
  }

  getCardTitleNodes() {
    return this.refCards.filter(card => { return card }).map(card => { return card.refTitle });
  }

  getCardDescriptionNodes() {
    return this.refCards.filter(card => { return card }).map(card => { return card.refDescription });
  }

  enableEqualizer() {
    return !isNarrowViewport();
  }

  render() {

    const {
      filterLabel,
      loadMoreText,
      theme,
      filters
    } = this.props;

    const {
      filteredDeals,
      activeFilters,
      totalDeals
    } = this.state;

    if (!filteredDeals) return null;

    return (
      <Grid>
        <Row>
          <SC.FilterControls>
            <SC.FilterText className={theme}>{filterLabel}</SC.FilterText>
            <SC.FilterButtonWrapper className={theme}>
              {
                filters.filters.map((button, index) => {
                  return (
                    <Button className={activeFilters.includes(button.name) ? 'isActive' : ''} key={index} onClick={this.handleFilter.bind(this, button.name)}>
                      {button.title}
                      <IconClose/>
                    </Button>
                  )
                })
              }
            </SC.FilterButtonWrapper>
          </SC.FilterControls>
        </Row>
        <Row>
          {
            filteredDeals.map((deal, index) => {
              return (
                <Column key={index} sm={12} md={4} lg={4}>
                  <Equalizer
                    enabled={this.enableEqualizer}
                    nodes={this.getCardTitleNodes.bind(this)}
                  >
                    <Equalizer
                      enabled={this.enableEqualizer}
                      nodes={this.getCardDescriptionNodes.bind(this)}
                    >
                    <SC.FeaturedDealCard>
                      <CatalogueCard
                        title={deal.title}
                        description={deal.description}
                        ribbonText={deal.hasRibbon ? deal.ribbonText : ''}
                        ctaType='anchor'
                        ctaText={deal.ctaText}
                        ctaUrl={deal.ctaUrl}
                        ref={node => (this.refCards[index] = node)}
                      />
                    </SC.FeaturedDealCard>
                    </Equalizer>
                  </Equalizer>
                </Column>
              )
            })
          }

        </Row>
        <Row>
          <SC.LoadMoreWrapper>
            {
              filteredDeals.length < totalDeals
                ? <Button onClick={this.loadMoreDeals}>{loadMoreText}</Button>
                : <React.Fragment/>
            }

          </SC.LoadMoreWrapper>
        </Row>
      </Grid>
    );
  }
}

FeaturedBusinessDeals.defaultProps = {
  filterText: 'Filter by',
  numDealsOnLoad: 9,
  dealsToLoadOnClick: 3
}

FeaturedBusinessDeals.propTypes = {
  /* an object that contains a list of business deals */
  businessDeals: PropTypes.object,
  /* an object that contains an array of filters */
  filters: PropTypes.object,
  /* a string that specifies if a dark or light theme is used */
  theme: PropTypes.string,
  /* a string that represents the filter label e.g. Filter by: */
  filterLabel: PropTypes.string,
  /* a string that represents load more button text */
  loadMoreText: PropTypes.string,
  /* the number of extra deals to load on click */
  dealsToLoadOnClick: PropTypes.number,
  /* the number of deals that should be rendered on page load */
  numDealsOnLoad: PropTypes.number
};

export default FeaturedBusinessDeals;
