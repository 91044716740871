import styled from 'styled-components';
import { Theme } from '@lux/components';

export default {
  Container: styled.div`
    background-color: ${Theme.colours.white};
    width: 100%;
    margin: 10px;
  `,
  Iframe: styled.iframe`
    border: none;
    display: block;
  `
};
