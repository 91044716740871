import styled from "styled-components";
import { Column, Theme, Row } from "@lux/components";
import styles from "../RelatedProductList/styles";

export default {
  ...styles,
  ErrorMsg: styled.p`
    padding: 0;
    margin: 0 auto;
  `,
  StyledColumn: styled(Column)`
    margin-bottom: 0.5rem;
    ${Theme.media.md`
    padding-right: 0.5rem;
    `}
  `,
  StyledRow: styled(Row)`
    justify-content: flex-end;
  `,
};
