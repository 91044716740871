import
  styled
  // { css }
from 'styled-components';
import {
  Theme
} from '@lux/components';

export default {
  OfficeList: styled.div`
    width: auto;
    height: auto;
    background: ${Theme.colours.white};
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 2em 1em;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    transition: transform 1s, opacity 1s, visibility 1s;
    transform: translateX(0%);
    visibility: visible;
    opacity: 1;
    display: block;
  `
}
