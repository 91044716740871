import React, { Component } from 'react';
import { Grid, Column, Row, Anchor } from '@lux/components';

import PropTypes from 'prop-types';
import SC from './styles';

import iconFacebook from '../../assets/images/facebook_icon-big.png';
import iconLinkedIn from '../../assets/images/linkedin_icon-big.png';
import iconTwitter from '../../assets/images/twitter_icon-big.png';

class ArticleDetailBanner extends Component {
  render() {
    const {
      title,
      description,
      ctaUrl,
      image,
      hideThumbnailForMobile,
      hideThumbnailForDesktop,
      imageAltText
    } = this.props;

    return (
      <SC.ArticleDetailBannerCon>
        <Grid>
          <Row>
            <Column>
              <SC.ArticleDetailTextContainer>
                <SC.ArticleDetailBannerTitle>
                  {title}
                </SC.ArticleDetailBannerTitle>
                <p>{description}</p>
              </SC.ArticleDetailTextContainer>
              <SC.ArticleDetailBannerSocial>
                <Anchor
                  href={`https://www.facebook.com/sharer/sharer.php?u=${ctaUrl}`}
                >
                  <img id="fb" src={iconFacebook} alt="Facebook" />
                </Anchor>
                <Anchor
                  href={`https://twitter.com/intent/tweet?text=${title} ${ctaUrl} via @SingtelGE`}
                >
                  <img id="twitter" src={iconTwitter} alt="Twitter" />
                </Anchor>
                <Anchor
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${ctaUrl}&title=${title}&summary=${description}&source=LinkedIn`}
                >
                  <img id="linkedin" src={iconLinkedIn} alt="LinkedIn" />
                </Anchor>
              </SC.ArticleDetailBannerSocial>
            </Column>
            <SC.ArticleColumn
              sm={12}
              md={6}
              lg={6}
              desktopDisplay={hideThumbnailForDesktop}
              mobileDisplay={hideThumbnailForMobile}
            >
              <SC.Image src={image} alt={imageAltText} />
            </SC.ArticleColumn>
          </Row>
        </Grid>
      </SC.ArticleDetailBannerCon>
    );
  }
}

ArticleDetailBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaUrl: PropTypes.string,
  image: PropTypes.string,
  hideThumbnailForMobile: PropTypes.bool,
  hideThumbnailForDesktop: PropTypes.bool,
  imageAltText: PropTypes.string
};

export default ArticleDetailBanner;
