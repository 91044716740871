import styled, { css } from 'styled-components';
import {
  Theme
} from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  OfficeInfoCard: styled.div`
    width: ${remCalc(340)};
    background: ${Theme.colours.white};
    color: ${Theme.colours.black};
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    padding: 2em 1em;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    left: 1em;
    max-height: 80%;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    height: auto;
    transition: transform 1s, opacity 1s, visibility 1s;
    transform: translateX(0%);
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;

    ${props => {
      return props.isListViewActive && css`
        position: relative;
        display: block;
        transform: none;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        left: 0;
        width: 100%;
      `
    }}

    ${props => {
      return props.isOfficeListViewActive && css`
        position: relative;
        display: inline-block;
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        left: 0;
      `
    }}

    ${props => {
      return props.isOfficeViewActive && css`
        visibility: visible;
        opacity: 1;
      `
    }}

    dl {
      margin-top: 15px;
    }
  `,
  OfficeName: styled.p`
    font-size: 1.2rem;
    font-weight: bold;
  `,
  CloseButton: styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  `,
  ContactDetails: styled.div`
    dd,
    dt {
      display: inline-block;
      margin-bottom: 0;
    }

    dd {
      margin-left: 10px;
    }
  `,
  Address: styled.p`
    margin: 0;
  `
}
