import styled, { css } from 'styled-components';
import { Theme } from '@lux/components';

export default {
  RatePeriodLabel: styled.p`
    margin-top: 12px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1;
  `,
  Rate: styled.p`
    margin-bottom: 0;
    line-height: 1;
  `,
  RatePrice: styled.p`
    font-family: ${Theme.fonts.families.Museo500};
    font-size: 2.2rem;
    margin: 0;
    line-height: 1;
  `,
  RateTier: styled.p`
    font-family: ${Theme.fonts.families.LatoBold};
    margin-bottom: 12px;
  `,
  TimePeriod: styled.p`
    margin-bottom: 0;
    font-size: 0.75rem;
  `,
  Card__IDD: styled.div`
    min-height: 200px;
    ${Theme.styles.panelDropShadow};
    border-radius: 4px;
    position: relative;
    padding: 38px;
    text-align: center;
    justify-content: center;
    margin-bottom: 1.25rem;
    background-color: ${Theme.colours.white};
    color: ${Theme.colours.black};
  `,
  CardBody__IDD: styled.div`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: 1rem;
    line-height: 1.5rem;
    display: block;
    width: 100%;

    &:last-child {
      margin-bottom: 1.25rem;

      @media screen and (min-width: 40em) {
        margin-bottom: 0;
      }
    }

    ${props => {
        return (
          props.numCols === 2 &&
          css`
            display: inline-block;
            width: 50%;
          `
        )
      }
    };
  `,
  CardHeader__Wrapper: styled.div`
    flex: 1 0 auto;
    margin-bottom: 38px;
    position: relative;
  `,
  CardHeader__IDD: styled.div`
    margin-top: 0;
    font-family: ${Theme.fonts.families.Museo300};
    font-size: 1.75rem;
    line-height: 2rem;
  `
};
