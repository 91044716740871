import styled, { css } from 'styled-components';
import Headroom from 'react-headroom';
import { remCalc } from '@lux/helpers';

import {
  Grid,
  Row,
  Column,
  Anchor,
  List,
  ListItem,
  Theme
} from '@lux/components';

import IconClose from '@lux/components/src/assets/svgs/close.svg';

const AnchorWhite = styled(Anchor)`
  color: ${Theme.colours.white};
  overflow: hidden;

  &:not([disabled]) {
    &:hover {
      color: ${Theme.colours.white};
    }
  }

  & svg {
    float: right;

    path {
      fill: ${Theme.colours.white};
    }
  }
`;

const AnchorDarkSlate = styled(Anchor)`
  font-family: ${Theme.fonts.families.LatoRegular};
  color: ${Theme.colours.darkslate};
  display: block;
  padding: ${remCalc(8)} ${remCalc(20)} ${remCalc(8)} 0;

  ${props =>
    { return props.hovered &&
    css`
      color: ${Theme.colours.singtelred};
    `}
  };

  &:not([disabled]) {
    &:hover {
      color: ${Theme.colours.singtelred};
    }
  }

  & svg {
    float: right;
    transform: rotate(90deg);
    margin-top: ${remCalc(3)};
    width: ${remCalc(10)};

    ${props =>
      { return props.hovered &&
      css`
        fill: ${Theme.colours.singtelred};
      `}
    };
  }
`;

const AnchorWhiteMenuItem = styled(AnchorWhite)`
  font-size: ${Theme.fonts.sizes.regular};
  display: block;
  width: 100%;

  & svg {
    float: right;
    transform: rotate(90deg);
    margin-top: ${remCalc(3)};
    width: ${remCalc(10)};

    &.active {
      path {
        fill: ${Theme.colours.singtelred};
      }
    }
  }
`;

const ColumnPrimaryMenuItem = styled(Column)`
  padding-left: 0;
`;

export default {
  HeaderWrapper: styled.div`
    overflow: hidden;
  `,

  HeaderContainer: styled.div`
    overflow: hidden;
  `,

  HeaderPlaceholder: styled.div`
    height: ${remCalc(68)};

    ${Theme.media.lg`
      height: ${remCalc(111)};
    `}
  `,

  Header: styled.div`
    background-color: ${Theme.colours.darkslate};
    border-top: ${remCalc(4)} solid ${Theme.colours.singtelred};
    box-shadow: 0 ${remCalc(16)} ${remCalc(30)} 0 rgba(0, 0, 0, 0.55);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  `,

  GridMobile: styled(Grid)`
    ${Theme.media.lg`
      display: none;
    `};
  `,

  GridDesktop: styled(Grid)`
    display: none;
    z-index: 999999;
    ${Theme.media.lg`
      display: block;
    `};
  `,

  HamburgerGrid: styled(Grid)`
    min-width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(29, 52, 68, 0.95);
    top: 0;
    transition: all 400ms ease-out;
    padding: 0 ${remCalc(30)};
    transform: translate(${remCalc(-200)}, 0);
    opacity: 0;
    visibility: hidden;
    align-items: flex-end;
    z-index: 101;

    ${props =>
      { return props.isMenuOpen &&
      css`
        transform: translate(0, 0);
        opacity: 1;
        visibility: visible;
      `}
    };
  `,

  HamburgerWrapper: styled.div`
    position: fixed;
    bottom: ${remCalc(30)};
    width: 100%;
    text-align: center;
    z-index: 10;

    ${Theme.media.lg`
      display: none;
    `};

    ${Theme.media.md`
      display: none;
    `};
  `,

  Hamburger: styled.div`
    margin-top: ${remCalc(26)};
    margin-left: ${remCalc(4)};
    cursor: pointer;
    width: 100%;

    svg {
      fill: ${Theme.colours.white};
      width: ${remCalc(45)};
      height: ${remCalc(45)};
      filter: drop-shadow(${remCalc(2)} ${remCalc(4)} ${remCalc(4)} rgba(0, 0, 0, 0.5));
    }
  `,

  HamburgerTabletWrapper: styled.div`
    display: none;

    ${Theme.media.md`
      display: block;
    `};
  `,

  HamburgerLine: styled.div`
    margin-top: ${remCalc(23)};
    margin-left: ${remCalc(4)};
    cursor: pointer;
    width: 100%;

    svg {
      fill: ${Theme.colours.white};
      width: ${remCalc(22)};
      height: ${remCalc(22)};
    }
  `,

  RightHandColumn: styled.div`
    margin-top: ${remCalc(15)};
    ${Theme.media.lg`
      margin-top: 0;
      margin-bottom: ${remCalc(15)};
      display: inline-flex;
      right: 0;
      float: right;
      position: relative;
    `};
  `,

  AnchorBlack: styled(Anchor)`
    color: ${Theme.colours.black};
    display: block;

    &:not([disabled]) {
      &:hover {
        color: ${Theme.colours.black};
      }
    }

    ${props =>
      {return props.active &&
      css`
        color: ${Theme.colours.singtelred};

        &:not([disabled]) {
          &:hover {
            color: ${Theme.colours.singtelred};
          }
        }
      `}};

    & svg {
      float: right;
    }
  `,

  AnchorWhite: styled(AnchorWhite)`
    display: block;
  `,

  AnchorWhiteSmall: styled(AnchorWhite)`
    font-size: ${Theme.fonts.sizes.small};
  `,

  AnchorWhitePrimary: styled(AnchorWhite)`
    display: block;
    line-height: 4rem;
    margin-right: ${remCalc(20)};
    position: relative;
    border-bottom: ${remCalc(5)} solid ${Theme.colours.darkslate};

    &:hover {
      border-bottom: ${remCalc(5)} solid ${Theme.colours.lightslate};
    }

    &:focus {
      color: ${Theme.colours.white};
    }

    ${props => {
      if (props.active === true) {
        return css`
          border-bottom: ${remCalc(5)} solid ${Theme.colours.lightslate};
        `;
      }
    }};
  `,

  AnchorWhiteMenuItem: styled(AnchorWhiteMenuItem)`
    display: block;
  `,

  AnchorWhiteMenuTitle: styled(AnchorWhiteMenuItem)`
    text-align: center;
    text-transform: uppercase;
    letter-spacing: ${remCalc(1)};
    border-bottom: ${remCalc(1)} solid ${Theme.colours.white};
    padding: ${remCalc(15)} 0;
  `,

  ColumnPrimaryMenuItem: styled(ColumnPrimaryMenuItem)`
    padding-left: 0;
    padding-right: 0;
  `,

  ColumnSubMenuItem: styled(ColumnPrimaryMenuItem)`
    padding-left: ${remCalc(10)};
    margin-top: ${remCalc(10)};
  `,

  HamburgerHeader: styled(Row)`
    margin-bottom: ${remCalc(16)};
    min-height: ${remCalc(67)};
  `,

  ColumnSubSubMenuItem: styled(Column)`
    margin-top: ${remCalc(10)};
  `,

  MenuCloseIcon: styled(IconClose)`
    cursor: pointer;
    float: right;
    margin-top: ${remCalc(30)};
  `,

  Divider: styled.div`
    margin: ${remCalc(16)} ${remCalc(-24)};
    border-bottom: ${remCalc(1)} solid ${Theme.colours.grey_e2};
  `,

  LogoAnchor: styled(Anchor)`
    text-align: center;
    margin-top: ${remCalc(12)};
    margin-bottom: ${remCalc(8)};
    display: block;
  `,

  LogoImg: styled.img`
    height: ${remCalc(44)};
    margin-right: ${remCalc(20)};
  `,

  MenuItemList: styled(List)`
    padding-top: ${remCalc(13)};
    float: right;
  `,

  MenuItemListPrimary: styled(List)`
    li {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  `,

  SecondayMenuDesktop: styled(Grid)`
    width: 100%;
    background: ${'#fff'};
    box-shadow: 0 ${remCalc(16)} ${remCalc(30)} 0 rgba(0, 0, 0, 0.55);
    position: absolute;
    left: 0;
    min-height: ${remCalc(400)};
  `,

  SecondaryTitle: styled.h3`
    font-size: 1.375rem;
    line-height: 1.375rem;
    color: ${Theme.colours.darkslate};
    padding: ${remCalc(30)} 0;
  `,

  ThirdLevelTitle: styled.h4`
    font-size: 1.25rem;
    margin-bottom: ${remCalc(10)};
  `,

  SecondLevelMenuWrapper: styled.div`
    border: ${remCalc(1)} solid ${Theme.colours.singtelred};
    width: 100%;
    margin: ${remCalc(15)} 0;
  `,

  SecondaryMenuListWrapper: styled(Column)`
    border-right: ${remCalc(1)} solid ${Theme.colours.grey_dd};
    position: relative;
    padding: ${remCalc(30)} 0;
    min-height: ${remCalc(400)};
  `,

  FeaturedContentWrapper: styled(Column)`
    margin: ${remCalc(30)} 0;

    p {
      margin-top: ${remCalc(10)};
    }
  `,

  SecondaryMenuListThirdWrapper: styled.div`
    margin-top: 0;
  `,

  SecondaryMenuDesktopColumn: styled.div`
    display: block;
    width: 100%;
  `,

  ThirdLevelList: styled(List)`
    display: block;
    position: absolute;
    left: ${remCalc(330)};
    top: ${remCalc(30)};
    width: 100%;
  `,

  CartDropDownList: styled(List)`
    transition: 0.1s all ease-in;
    position: absolute;
    top: ${remCalc(57)};
    right: ${remCalc(10)};
    background: ${Theme.colours.white};
    text-align: left;
    min-width: ${remCalc(175)};
    border-radius: ${remCalc(5)};
    padding: ${remCalc(10)} 0;

    ${Theme.media.lg`
      right: 0;
    `}

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 ${remCalc(10)} ${remCalc(10)} ${remCalc(10)};
      border-color: transparent transparent ${Theme.colours.white} transparent;
      top: ${remCalc(-10)};
      right: ${remCalc(10)};
    }
  `,

  AnchorDarkSlate: styled(AnchorDarkSlate)`
    font-size: ${Theme.fonts.sizes.small};
    font-family: ${Theme.fonts.families.LatoRegular};
  `,

  AnchorDarkSlateSmall: styled(AnchorDarkSlate)`
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${Theme.fonts.sizes.small};
    padding: ${remCalc(2)} ${remCalc(20)};
  `,

  SearchWrapper: styled.div`
    margin-right: 1.375rem;
  `,

  SearchFieldWrapper: styled.div`
    margin: auto ${remCalc(-15)};

    input[type="text"] {
      margin-bottom: 0;
    }

    ${Theme.media.lg`
      margin: auto ${remCalc(15)};
    `};
  `,

  SearchAnchorWhite: styled(AnchorWhite)`
    overflow: hidden;
  `,

  Headroom: styled(Headroom)`
    .headroom--unfixed {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      transform: translate3d(0, 0, 0);
    }

    .headroom--scrolled,
    .headroom--unpinned {
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      transform: translate3d(0, -150%, 0);
    }

    .headroom--scrolled {
      transition: transform 200ms ease-in-out;
    }

    .headroom--pinned {
      position: fixed;
      transform: translateY(0%);
    }
  `,

  HamburgerContent: styled.div`
    display: flex;
    position: fixed;
    height: 100%;
    width: calc(100% - ${remCalc(60)});
    align-items: flex-end;
  `,

  MobileNavigationListing: styled.div`
    display: block;
  `,

  MobileNavigationIconWrapper: styled(Row)`
    margin: ${remCalc(30)} 0;
  `,

  MobileNavigationIcons: styled(List)`
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
  `,

  MobileNavigationListItem: styled(ListItem)`
    width: 33.33%;
    float: left;
    text-align: center;

    svg {
      display: inline-block;
      width: ${remCalc(35)};
      height: ${remCalc(35)};
      margin: ${remCalc(5)} 0;
      filter: drop-shadow(${remCalc(2)} ${remCalc(4)} ${remCalc(4)} rgba(0, 0, 0, 0.5));
    }

    &:nth-child(2) {
      svg {
        width: ${remCalc(45)};
        height: ${remCalc(45)};
        margin: 0;

        path {
          fill: none;
          stroke: ${Theme.colours.white};
          stroke-width: ${remCalc(2)};
        }
      }
    }
  `,

  MobileNavigationRow: styled(Row)`
    ${props =>
      { return props.isActive &&
      css`
        a {
          display: none;
        }
      `}
    };
  `,

  ThirdMenuWrapper: styled.div`
    ${props =>
      { return props.selected &&
      css`
        a {
          display: block;
        }
      `}
    };
  `,

  FourthMenuWrapper: styled.div`
    ${props =>
      { return props.selected &&
      css`
        a {
          display: block;
        }
      `}
    };
  `,

  ThirdLevelTitleColumnWrapper: styled.div`
    display: none;

    ${props =>
      { return props.active &&
      css`
        display: block;
      `}
    };
  `,
  
  ThirdLevelColumnWrapper: styled.div`
    display: none;

    ${props =>
      { return props.active &&
      css`
        display: block;
      `}
    };
  `,

  MobileNavigationRowItem: styled.div`
    width: 100%;
  `
};
