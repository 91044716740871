import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SC from "./styles"
import IconClose from '../../../../../assets/svgs/close-w.svg';
import { noop } from '@lux/helpers';

class LightBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: props.open
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({open: nextProps.open});
    }
  }

  onClose = () => {
    this.setState({ open: false });
    this.props.toggleLightBox();
  };

  render() {

    return (
      this.state.open && (
        <React.Fragment>
          <SC.LightBoxWrapper>
            <SC.LightBox>
              <SC.iframe
                title='Contact us'
                width='100%'
                height='100%'
                src={this.props.iframeSrc}
              />
            </SC.LightBox>
            <SC.CloseButton>
              <IconClose onClick={() => this.onClose()}/>
            </SC.CloseButton>
          </SC.LightBoxWrapper>
          <SC.Overlay onClick={() => this.onClose()}/>
        </React.Fragment>
      )
    );
  }
}

LightBox.defaultProps = {
  open: false,
  toggleLightBox: noop
};

LightBox.propTypes = {
  /* If modal is open by default */
  open: PropTypes.bool,
  /* iFrame source */
  iframeSrc: PropTypes.string,
  /* function to be called when light box is toggled */
  toggleLightBox: PropTypes.func
};

export default LightBox;
