import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@lux/components';
import SC from './styles';

import iconShare from '../../assets/images/share_icon.png';
import iconLinkedIn from '../../assets/images/linkedin_icon.png';
import iconFacebook from '../../assets/images/FB_icon.png';
import iconTwitter from '../../assets/images/twitter_icon.png';

class ArticleCard extends Component {
  state = {
    isActive: false
  };

  toggleShareIcons() {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  }

  render() {
    const {
      className,
      thumbnail,
      imageAltText,
      categories,
      title,
      description,
      ctaUrl,
      ctaText,
      publishedDate,
      author,
      theme,
      openNewTab
    } = this.props;

    return (
      <SC.CatalogueCard className={className}>
        <SC.Card>
          <SC.ImageContainer>
            <SC.Image src={thumbnail} alt={imageAltText} />
            <SC.Anchor onClick={this.toggleShareIcons.bind(this)}>
              <img alt="Share" src={iconShare} />
            </SC.Anchor>
            <SC.ShareIcons className={this.state.isActive ? 'is-active' : ''}>
              <Anchor
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${ctaUrl}&title=${title}&summary=${description}&source=LinkedIn`}
                target="_blank"
              >
                <SC.IconLinkedIn src={iconLinkedIn} alt="LinkedIn" />
              </Anchor>
              <Anchor
                href={`https://www.facebook.com/sharer/sharer.php?u=${ctaUrl}`}
                target="_blank"
              >
                <SC.IconFacebook src={iconFacebook} alt="Facebook" />
              </Anchor>
              <Anchor
                href={`https://twitter.com/intent/tweet?text=${title} ${ctaUrl} via @SingtelGE`}
                target="_blank"
              >
                <SC.IconTwitter src={iconTwitter} alt="Twitter" />
              </Anchor>
            </SC.ShareIcons>
            {publishedDate && (
              <SC.Details theme={theme}>
                {publishedDate} {author !== '' ? '|' : ''} {author}
                <br />
                <SC.Topics theme={theme}>
                  <SC.CategoryIcon theme={theme} />
                  {categories ? categories.join(', ') : ''}
                </SC.Topics>
              </SC.Details>
            )}
          </SC.ImageContainer>
          <SC.Content>
            <SC.Title
              innerRef={node => {
                this.refTitle = node;
              }}
            >
              {title}
            </SC.Title>

            <SC.Description
              innerRef={node => {
                this.refDescription = node;
              }}
            >
              {description}
            </SC.Description>
          </SC.Content>
          <SC.CTA>
            {openNewTab ? (
              <Anchor href={ctaUrl} target="_blank">
                {ctaText}
              </Anchor>
            ) : (
              <Anchor href={ctaUrl}>{ctaText}</Anchor>
            )}
          </SC.CTA>
        </SC.Card>
      </SC.CatalogueCard>
    );
  }
}

ArticleCard.defaultProps = {
  className: ''
};

ArticleCard.propTypes = {
  /** Class name */
  className: PropTypes.string,
  /** Thumbnail Path */
  thumbnail: PropTypes.string,
  /** Image Alt text */
  imageAltText: PropTypes.string,
  /** Title text */
  title: PropTypes.string,
  /** CTA Url */
  ctaUrl: PropTypes.string,
  /** CTA Text */
  ctaText: PropTypes.string,
  /** Published Date */
  publishedDate: PropTypes.string,
  /** Author */
  author: PropTypes.string,
  /** Topics */
  topics: PropTypes.string,
  /* determines if dark or light font is used */
  theme: PropTypes.string,
  /* open new tab */
  openNewTab: PropTypes.bool
};

export default ArticleCard;
