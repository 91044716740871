import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Heading, Button } from '@lux/components';
import { getUrlParams } from '@lux/helpers';
import SC from './styles';

class ProfilingFilter extends Component {

  state = {
    selectedAnswer: [],
    showButton: false,
    url: null
  };

  componentDidMount() {
    const hasParams = getUrlParams();
    this.checkUrlParams(hasParams);
  }

  componentWillMount() {
    this.createStateQuestions(this.props.questionItemList);
  }

  /**
   * Check if component was a fresh render or triggered from other page
   */
  checkUrlParams = param => {

    const paramQList = Object.values(param);

    if (param.q1) {
      const questioList = this.state.questionList;
      questioList[0].answer = param.q1 ? param.q1.split(',') : [];
      questioList[1].answer = param.q2 ? param.q2.split(',') : [];
      questioList[2].answer = param.q3 ? param.q3.split(',') : [];
      questioList[3].answer = param.q4 ? param.q4.split(',') : [];
      questioList[4].answer = param.q5 ? param.q5.split(',') : [];

      this.setActive(questioList);
      this.setState({
        questionList: questioList,
        param: paramQList,
        selectedIndex: null,
        showButton: true
      });

      this.createUrl()
    } else {
      this.setButton(false);
    }
  };
  setActive = (questionList) => {
    questionList.map((question) => {
      question.active = question.answer.length ? true : false;
    });
  }
  

  createStateQuestions = (list) => {
    const questions = list.map((item, index) => {
      return {
        q: item.questionTitle,
        active: index == 0 ? true : false,
        answersList: item.answersList,
        hideSubmission: item.hideSubmission == true ? false : true,
        multipleSelection: item.multipleSelection,
        questionDependency: item.questionDependency,
        answer: []
      }
    });

    this.setState({
      questionList: questions
    })

  };
  /**
   * Question click event
   * 
   * Collect answer
   * Check question dependency
   * Next question display handle
   * Show button at the last index
   */
  clearBottomQuestion = (index, qlist) => {
    for (let i = index + 1; i < qlist.length; i++) {
      qlist[i].active = null
      qlist[i].answer = []
    }
  }

  /**
   * answer click event
   */
  selectQuestion = (answer, index) => {
    const qlist = this.state.questionList;
    let question = qlist[index]
    let nextQuestion = qlist[index + 1]

    if (question.multipleSelection) {
      if (question.answer.includes(answer.value) || index < this.state.selectedIndex) {
        /**
         * clear botton questions and answers
         */
        this.clearBottomQuestion(index, qlist);

        if (question.answer.includes(answer.value)) {
          this.addToAnswerList(question.answer, answer.value)
          this.createUrl();
          if (nextQuestion) {
            if (question.answer.length == 0) {
              nextQuestion.active = false
            } else {
              nextQuestion.active = true
            }
          } else {
            if (question.answer.length == 0) {
              this.setButton(false);
            } else {
              this.setButton(true);
            }

          }

        } else {
          this.addToAnswerList(question.answer, answer.value)
          if (nextQuestion) {
            nextQuestion.active = true
            nextQuestion.answer = []
            this.setButton(false);
          } else {
            this.setButton(true);
          }
        }
      } else {
        this.addToAnswerList(question.answer, answer.value)
        if (nextQuestion) {
          nextQuestion.active = true
          nextQuestion.answer = []
          this.setButton(false);
        } else {
          this.setButton(true);
        }
      }
    } else {
      question.answer[0] = answer.value;
      this.clearBottomQuestion(index, qlist);
      this.setButton(false);
      if (nextQuestion) {
        nextQuestion.active = true
        nextQuestion.answer = []
      }
      /**
       * checking question dependency on next question
       */
      if (nextQuestion && (nextQuestion.questionDependency.includes(question.answer[0])
        || nextQuestion.questionDependency.length == 0)) {
        nextQuestion.active = true
        this.setButton(false);
      } else if (nextQuestion) {
        nextQuestion.active = false
        if (question.answer.length) {
          this.setButton(true);
        } else {
          this.setButton(false);
        }

      } else {
        if (question.answer.length) {
          this.setButton(true);
        } else {
          this.setButton(false);
        }
      }
    }

    this.setState({
      questionList: qlist,
      selectedIndex: index
    })

  }
  
  /**
   * Create recomended URL base on answer and display status
   */
  createUrl = () => {
    const url = [];
    this.state.questionList.map((item, index) => {
      if (item.answer != null) {
        url.push("q" + (index + 1) + "=" + item.answer + "&s" + (index + 1) + "=" + item.hideSubmission + "&");
      }
    })

    this.setState({
      url: url.join('').slice(0, -1)
    })
  }

  /**
   * 
   * checking answer include status
   */
  addToAnswerList = (list, answer) => {
    if (list.includes(answer)) {
      const index = list.indexOf(answer);
      if (index > -1) {
        list.splice(index, 1);
      }
    } else {
      list.push(answer)
    }
  }

  /**
   * 
   * set button true, false status
   * create url for filter settings
   */
  setButton = (status) => {
    if (status) {
      this.createUrl();
    }
    this.setState({
      showButton: status
    })
  }

  render() {
    const {
      questionItemList,
      buttonLabel,
      redirectUrl
    } = this.props;

    return (
      <Grid>
        {
          questionItemList && this.state.questionList.map((item, index) => {
            return (
              <SC.SizeSection id="sizeSection" show={item.active} key={index}>
                <Row>
                  <SC.SectionHeader >
                    <Heading level={3}>{item.q}</Heading>
                  </SC.SectionHeader>
                </Row>
                <Row>
                  {item.answersList.map((answerItem, answerIndex) => {
                    if (index != 0 && answerItem.answerDependency.includes(this.state.questionList[index - 1].answer[0])
                      || answerItem.answerDependency.length === 0 || this.state.questionList[index - 1].multipleSelection) {
                      return (
                        <SC.BoxContainer id="boxContainer" sm={6} md={4} key={answerIndex}>
                          <SC.CategoryBox id="categoryBox" onClick={() => this.selectQuestion(answerItem, index)} active={item.answer.includes(answerItem.value)}>
                            <SC.RowWrapper>
                              {answerItem.icon && (
                                <SC.FilterIcon src={answerItem.icon} alt={answerItem.title} />
                              )}
                              <Heading level={4}>{answerItem.title}</Heading>
                            </SC.RowWrapper>
                          </SC.CategoryBox>
                        </SC.BoxContainer>
                      );
                    }
                  })}</Row></SC.SizeSection >
            );
          })
        }
        <SC.ButtonWrapper id="buttonWrapper" show={this.state.showButton}>
          <Button
            href={`${redirectUrl}?${this.state.url}`}>
            {buttonLabel}
          </Button>
        </SC.ButtonWrapper>
      </Grid>
    );
  }
}

ProfilingFilter.propTypes = {
  /** Quections list with answers */
  questionItemList: PropTypes.array,

  /** Button Label */
  buttonLabel: PropTypes.string,
  /** Next button URL which will be appended with the selection */
  redirectUrl: PropTypes.string
};

export default ProfilingFilter;