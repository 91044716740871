import React, { Component } from 'react';
import {
  Grid,
  Column,
  Row,
} from '@lux/components';
import PropTypes from 'prop-types';
import SC from './styles';
import IDDCardList from './src/components/IDDCardList';
import Autocomplete from '../Autocomplete';

class IDDWidget extends Component {

  state = {
    countryList: [],
    selectedCountryData: [],
    selectedCountry: '',
    showErrorMessage: false
  };

  componentDidMount() {
    this.getCountryData();
  }

  getCountryData() {
    const { callRatesUrl } = this.props;

    fetch(callRatesUrl)
      .then(res => res.json())
      .then(callRates => {
        this.setCountryData(callRates)
      })
      .catch(err => { throw err });
  }

  setCountryData = callRates => {
    this.setState({
      countryData: callRates.data,
      countryList: callRates.data.map(item => {return item.label})
    });
  };

  handleSelect = selectedCountry => {
    this.setState({
      selectedCountryData: this.state.countryData.filter(country => { return country.label === selectedCountry })[0]
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountryData } = this.state;

    if(prevState.selectedCountryData !== selectedCountryData) {
      this.setState({
        showErrorMessage: !(selectedCountryData && selectedCountryData.services)
      })
    }
  }

  render() {

    const {
      selectedCountryData,
      countryList,
      showErrorMessage
    } = this.state;

    return (
      <Grid>
        <Row>
          <Column sm={12}>
            <Autocomplete
              suggestions={countryList}
              handleSelect={this.handleSelect.bind(this)}
              numSuggestions={this.props.numSuggestions}
              placeholder={this.props.placeholder}
              />
          </Column>
        </Row>

        <SC.IDDServicesWrapper>
          <Row>
            {
              selectedCountryData && selectedCountryData.services
              ? <IDDCardList selectedCountryData={selectedCountryData.services} />
              : null
            }

            {
              showErrorMessage
              ? <SC.ErrorMessage>
                  { this.props.errorMessage }
                </SC.ErrorMessage>
              : null
            }

          </Row>
        </SC.IDDServicesWrapper>
      </Grid>
    );
  }
}

IDDWidget.propTypes = {
  /* endpoint to retrieve IDD data */
  callRatesUrl: PropTypes.string.isRequired,
  /* placeholder text for autocomplete input */
  placeholder: PropTypes.string,
  /* error message that's displayed if there are no matches */
  errorMessage: PropTypes.string,
  /* theme */
  theme: PropTypes.string,
  /* number of autocomplete suggestions to return */
  numSuggestions: PropTypes.number
};

IDDWidget.defaultProps = {
  errorMessage: "Sorry, we couldn't find a match. Please try again.",
  theme: "light",
  numSuggestions: 10
};

export default IDDWidget;
