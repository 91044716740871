const widgetManager = require('@lux/core').widgetManager.default;
const availableComponents = require('./components').default;

require('@lux/components/build/lux-components.css');

// LUX requires to have some basic config to initalise and
// keep reports of this app usage
const options = {
  availableComponents: availableComponents,
  appName: __APP_NAME__,
  appVersion: __APP_VERSION__,
  ga: __GA_TRACKER__
};

// We have found LUX1 on the page, need to check if any LUX2 got affected
// by any rerenders of parent LUX1 components. If we do have an infected
// component we will need to re-render it. Not pretty but this is only
// done on a per component basis if it's found within a LUX1 component.
var checkForLegacyLux = function() {
  // this is a LUX1 global variable
  if (window && window.patternLibraryBaseUrl) {
    // window.jQuery exists on a LUX1 page
    window.jQuery(document).on('allWidgetsInitialized.lux', function() {
      var rw = window.RactiveWidgets.map(function(rw) {
        return rw.instance.ractive.el;
      }).filter(function(el) {
        return el.querySelector('.lux-component-container');
      });
      widgetManager.processWidgets(options, rw);
    });
  }
};

widgetManager.init(options).then(function() {
  checkForLegacyLux();
});
