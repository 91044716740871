import React, { Component } from 'react';
import { Heading } from '@lux/components';
import Modal from './styles';
import PropTypes from 'prop-types';
import CompList from '../../components';

class SpringBoardModal extends Component {
  state = {
    components: []
  };
  componentDidMount() {
    if (this.props.modalInnerComponent) {
      const compConfig = this.props.modalInnerComponent.map(comp => {
        const component = CompList[comp.component] || null;
        const props = this.getProps(comp.props);
        return { component, props };
      });
      this.setState({ components: compConfig });
    }
  }

  /**
   * Converts my-component to myComponent
   * If pascalCase is true, capitalises the first character, eg MyComponent
   */
  toCamelCase(value, pascalCase) {
    const newValue = value
      .replace(/(\w)(\w*)/g, (g0, g1, g2) => {
        return g1.toUpperCase() + g2.toLowerCase();
      })
      .replace(/-/g, '');

    if (!pascalCase) {
      return newValue.substr(0, 1).toLowerCase() + newValue.substr(1);
    } else {
      return newValue;
    }
  }

  getProps(props) {
    const propsFromDataModel = JSON.parse(props.datamodel || '{}');

    // We also support prop-* attributes too
    const propsFromAttributes = Object.keys(props)
      // Filter only attributes that start with prop-*
      .filter(attr => attr.indexOf('prop-') === 0)
      .reduce((accum, attr) => {
        let propName = attr.split('prop-')[1];
        // camel case the prop name
        let camelCasePropName = this.toCamelCase(propName);
        let value;

        // check if the value is a JSON object
        try {
          value = JSON.parse(props[attr]);
        } catch (e) {
          value = props[attr];
        }

        // Set into new object
        accum[camelCasePropName] = value;
        return accum;
      }, {});

    // Props are in priority of prop-* attributes > datamodel attribute
    return Object.assign(propsFromDataModel, propsFromAttributes);
  }

  render() {
    const { open, onClose = () => {}, modalTitle } = this.props;
    return (
      <Modal open={open} onClose={onClose}>
        <Heading level={3}>{modalTitle}</Heading>
        {this.state.components.map((comp, i) => (
          <comp.component key={i} {...comp.props} />
        ))}
      </Modal>
    );
  }
}

SpringBoardModal.propTypes = {
  /**open pmodal */
  open: PropTypes.bool,
  /**will be emitted once close the modal */
  onClose: PropTypes.func,
  /**pmodal title */
  modalTitle: PropTypes.string,
  /**inner component */
  modalInnerComponent: PropTypes.arrayOf(
    PropTypes.shape({
      /**component name */
      component: PropTypes.string,
      /**component props */
      props: PropTypes.object
    })
  )
};

export default SpringBoardModal;
