import React, { Component } from 'react';
import { Grid, Column, Row, Anchor } from '@lux/components';

import PropTypes from 'prop-types';
import SC from './styles';

class RelatedProductsWidget extends Component {
  render() {
    const {
      relatedImage,
      imageAlt,
      productList
    } = this.props;

    return (
      <Grid>
        <Row>
          <Column sm={12}>
            <SC.Container>
              <Row>
                <Column sm={12} md={12} lg={6}>
                  <SC.Image
                    src={relatedImage}
                    alt={imageAlt}
                  />
                </Column>
                <SC.ProductListColumn sm={12} md={12} lg={6}>
                  <Column sm={12}>
                    {
                      productList.map((productData, index) => {
                        return (
                          <Row key={index}>
                            <Column sm={12}>
                              <SC.Title>{productData.title}</SC.Title>
                              <SC.Description>{productData.description}</SC.Description>
                              <SC.Link>
                                <Anchor 
                                  href={productData.url}
                                  target={(productData.newTab === true ? '_blank' : '')}
                                >
                                  {productData.linkText}
                                </Anchor>
                              </SC.Link>
                            </Column>
                          </Row>
                        )
                      })
                    }
                  </Column>
                </SC.ProductListColumn>
              </Row>
            </SC.Container>
          </Column>
        </Row>
      </Grid>
    );
  }
}

RelatedProductsWidget.propTypes = {
  relatedImage: PropTypes.string,
  imageAlt: PropTypes.string,
  productList: PropTypes.array
};

export default RelatedProductsWidget;
