import React, { Component } from 'react';
import {
  Grid,
  Button
} from '@lux/components';

import PropTypes from 'prop-types';
import SC from './styles';
import PromotionTile from '../PromotionTile';

class FlashDeals extends Component {

  static defaultProps = {
    tilesToLoad: 2
  }

  state = {
    flashDeals: {},
    maxTilesOnLoad: 6
  }

  componentDidMount() {
    const {
      props: { deals },
      state: { maxTilesOnLoad }
    } = this;

    this.setState({
      flashDeals: deals.deals,
      tilesToRender: deals.deals.slice(0, maxTilesOnLoad),
      numTilesToShow: maxTilesOnLoad,
    })
  }

  loadMore() {
    const {
      state: { flashDeals },
      props: { tilesToLoad }
    } = this;

    this.setState(prevState => ({
      numTilesToshow: prevState.numTilesToShow += tilesToLoad,
      tilesToRender: flashDeals.slice(0, this.state.numTilesToShow)
    }))
  }

  render() {

    const {
      state: { tilesToRender, flashDeals },
      props: { loadMoreText }
    } = this;

    if (!tilesToRender) return null;

    return (
      <Grid>
        {
          tilesToRender.map((deal, index) => {
            return (
              <PromotionTile key={index}
                isStarDeal={deal.isStarDeal}
                promotionImageURL={deal.promotionImageURL}
                promotionImageAltText={deal.promotionImageAltText}
                promotionTitle={deal.promotionTitle}
                promotionPricePlan={deal.promotionPricePlan}
                promotionDescription={deal.promotionDescription}
                ctaUrl={deal.ctaUrl}
                ctaText={deal.ctaText}
                newTab={deal.newTab}
                ribbonText={deal.ribbonText}
              />
            )
          })
        }

        <SC.LoadMoreWrapper>
          {
            tilesToRender.length >= flashDeals.length
              ? <React.Fragment/>
              : <Button onClick={this.loadMore.bind(this)}>{loadMoreText}</Button>
          }
        </SC.LoadMoreWrapper>
      </Grid>
    );
  }
}

FlashDeals.propTypes = {
  deals: PropTypes.object,
  loadMoreText: PropTypes.string,
  tilesToLoad: PropTypes.number
};

export default FlashDeals;
