import styled from 'styled-components';
import { Theme } from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  Anchor: styled.a`
    margin-bottom: 2rem;
    display: block;
  `,
  AutocompleteWrapper: styled.div`
    max-width: ${remCalc(514)};
    margin: auto;
    position: relative;
    font-size: 1rem;

    input {
      font-size: 1rem;
      padding: ${remCalc(8)} ${remCalc(12)};
    }
  `,
  FontBold : styled.p`
    font-family: ${Theme.fonts.families.LatoBold};
    color: inherit;
  `,
  SearchText: styled.p`
    margin-bottom: ${remCalc(20)};
    color: inherit;
    font-family: ${Theme.fonts.families.LatoRegular};
    width: 100%;
    text-align: center;
  `,
  ButtonWrapper: styled.div`
    margin-bottom: ${remCalc(30)};
    text-align: center;
    display: inline-block;
    justify-content: space-between;
    align-items: center;

    ${Theme.media.md`
      display: flex;
      `
    }

    a {
      flex: 1;
      background: ${Theme.colours.white};
      border: 1px solid ${Theme.colours.grey_c6};
      border-radius: ${remCalc(4)};
      margin-right: ${remCalc(5)};
      color: ${Theme.colours.black};
      font-family: ${Theme.fonts.families.LatoRegular};

      &:last-child {
        margin-right: 0;
      }

      :not([disabled]):hover {
        background-color: ${Theme.colours.grey_cc};
        border: ${remCalc(1)} solid ${Theme.colours.grey_cc};
      }
    }
  `,
  Header: styled.h3`
    margin-left: 0;
  `,
  SectionHeader: styled.p`
    font-size: ${remCalc(28)};
    font-family: ${Theme.fonts.families.LatoBold};
    padding-top: ${remCalc(66)};
    text-align: center;
  `,
  ErrorMessage: styled.p`
    text-align: center;
    margin: auto;
    display: block;
    font-style: italic;
  `
};
