import { Theme, Modal } from '@lux/components';
import styled from 'styled-components';

export default styled(Modal)`
  text-align: left;

  & > div > div > div:last-child {
    ${Theme.media.md`
        max-width:80vw;
        min-width:30vw;
        width:auto;
    `};
  }
`;
