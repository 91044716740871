import styled from 'styled-components';
import { Column, SelectBox, Theme } from '@lux/components';
import { remCalc } from '../../../node_modules/@lux/helpers';

export default {
  BoxContainer: styled(Column)`
    margin-bottom: ${Theme.spacing.small};
  `,
  RowWrapper: styled.div`
    width: 100%;
    text-align: center;
    margin: 0;
  `,
  SectionHeader: styled(Column)`
    text-align: center;
    padding: ${Theme.spacing.small} 0;
  `,
  CategoryBox: styled(SelectBox)`
    padding: ${Theme.spacing.small};
    text-align: center;
  `,
  SizeSection: styled.div`
    display: ${p => (p.show ? 'block' : 'none')};
  `,
  ButtonWrapper: styled.div`
    display: ${p => (p.show ? 'block' : 'none')};
    padding: ${Theme.spacing.small} 0;
    text-align: center;
  `,
  FilterIcon: styled.img`
    height: ${remCalc(100)};
    max-width: ${remCalc(100)};
    width: 100%;
    padding: ${Theme.spacing.xsmall};
  `
};
