import styled, { css } from 'styled-components';

export default {
  Table: styled.table`
    width: 100%;
    background: inherit;

    thead,
    tbody {
      background: inherit;
    }

    tr td,
    tr th {
      padding-left: 0;
      color: inherit;

      p {
        margin-bottom: 0;
        display: inline-block;
      }
    }
  `,
  TableHead: styled.th`
    font-size: 1rem;

    &:first-child {
      padding-left: 0;
    }
  `,
  StyledTD: styled.td`
    &:first-child::after {
      ${props => {
        return props.isPreferredOperator && css`
          content: '*';
        `
      }}
    }
  `
};
