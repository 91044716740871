import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SC from './styles';
import { Grid, Row } from '@lux/components';

class SpringBoardIframe extends Component {
  render() {
    const {
      id,
      width,
      height,
      name,
      alignment,
      src,
      allowfullscreen,
      responsive
    } = this.props;

    return (
      <Grid>
        <Row>
          <SC.Container sm={6} md={4} id={id}>
            <SC.Iframe
              title="iFrame"
              width={width}
              height={height}
              name={name}
              alignment={alignment}
              allowFullScreen={allowfullscreen ? 1 : 0}
              src={src}
              responsive={responsive ? 1 : 0}
            />
          </SC.Container>
        </Row>
      </Grid>
    );
  }
}

SpringBoardIframe.defaultProps = {
  className: 'SpringBoardIframe'
};

SpringBoardIframe.propTypes = {
  /** fullscreen */
  allowfullscreen: PropTypes.bool,
  responsive: PropTypes.bool
};

export default SpringBoardIframe;
