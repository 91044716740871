import React, { Component } from 'react';
import {
  Grid,
  Column,
  Row,
  Button
} from '@lux/components';
import PropTypes from 'prop-types';
import SC from './styles';
import Autocomplete from '../Autocomplete';
import RoamingRatesCardList from './src/components/RoamingRatesCardList';
import RoamingRatesTable from './src/components/RoamingRatesTable';

class RoamingRatesSearchTool extends Component {

  state = {
    selectedDestination: '',
    selectedDestinationData: {},
    plans: undefined,
    showErrorMessage: false
  };

  componentDidMount() {
    this.getDestinations();
  }

  getDestinations = () => {
    const { plansUrl } = this.props;

    fetch(plansUrl)
      .then(res => res.json())
      .then(plans => {
        this.setState({
          plans
        })
      })
  };

  handleSelect = selectedDestination => {
    const { plans } = this.state;

    this.setState({
      selectedDestination,
      selectedDestinationData: plans.plans.filter(plan => { return plan.countryName === selectedDestination })[0]
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedDestinationData } = this.state;

    if(prevState.selectedDestinationData !== selectedDestinationData) {
      this.setState({
        showErrorMessage: !(selectedDestinationData && selectedDestinationData.plans)
      })
    }
  }

  render() {

    const {
      plans,
      selectedDestination,
      selectedDestinationData,
      showErrorMessage
    } = this.state;
    
    if (!plans) return null;

    return (
      <Grid>

        <Row>
          <Column>
            <SC.SearchText>{this.props.description}</SC.SearchText>
          </Column>
        </Row>

        <Row>
          <Column>
            <Autocomplete
              suggestions={
                plans.plans.map(plan => { return plan.countryName })
              }
              handleSelect={this.handleSelect.bind(this)}
              numSuggestions={this.props.maxNumSuggestions}
              placeholder={this.props.inputFieldPlaceholder}
              />
          </Column>
        </Row>

        <Row>
          <Column>
            <SC.FontBold>{this.props.popularDestinationsDescription}</SC.FontBold>
          </Column>
        </Row>

        <Row>
          <Column sm={12}>
            <SC.ButtonWrapper>
              {
                this.props.popularDestinations.map((destination, index) => {
                  return (
                    <Button key={index} onClick={() => this.handleSelect(destination.display)}>
                      { destination.display }
                    </Button>
                  )
                })
              }
            </SC.ButtonWrapper>
          </Column>
        </Row>

        {
          selectedDestinationData && Object.keys(selectedDestinationData).length > 0
            ? <React.Fragment>

                <SC.SectionHeader>Plans in {selectedDestination}</SC.SectionHeader>
                {
                  selectedDestinationData.plans.length > 0
                    ?
                      <React.Fragment>
                        <Row>
                          <RoamingRatesCardList plans={selectedDestinationData.plans}/>
                        </Row>

                        <SC.Anchor href="#operators">View our preferred operators in {selectedDestination}</SC.Anchor>
                      </React.Fragment>

                    : <SC.ErrorMessage>There are no plans available for {selectedDestination}.</SC.ErrorMessage>
                }

                <SC.SectionHeader>Pay-per-use rates in {selectedDestination}</SC.SectionHeader>
                {
                  selectedDestinationData.payPerUse.tableRows
                  ?
                    <RoamingRatesTable
                        tableRows={selectedDestinationData.payPerUse.tableRows}
                    />
                  : <SC.ErrorMessage>There are no pay-per-use rates available for {selectedDestination}.</SC.ErrorMessage>
                }

                <SC.SectionHeader id="operators">Operators in {selectedDestination}</SC.SectionHeader>
                {
                  selectedDestinationData.networkOperators.tableHeaders
                      ?
                      <React.Fragment>
                        <RoamingRatesTable
                            tableHeaders={selectedDestinationData.networkOperators.tableHeaders}
                            tableRows={selectedDestinationData.networkOperators.tableRows}
                            hideCols={[3]}
                        />
                        <p> * indicates Preferred Operators. Note: roamers who are subscribed to Enterprise EasyData Roam (Worldwide) plans or choose to roam on pay per use rates is allowed to connect to any operators in each destination. </p>
                      </React.Fragment>
                  : <SC.ErrorMessage>There are no network operators available for {selectedDestination}.</SC.ErrorMessage>
                }
              </React.Fragment>
            : null
        }

        {
          showErrorMessage
            ? <SC.ErrorMessage>{this.props.errorMessage}</SC.ErrorMessage>
            : null
        }

      </Grid>
    );
  }
}

RoamingRatesSearchTool.propTypes = {
  /* placeholder text for autocomplete input */
  inputFieldPlaceholder: PropTypes.string,
  /* max number of suggestions for autocomplete */
  maxNumSuggestions: PropTypes.number,
  /* endpoint for retrieving roaming rate data */
  plansUrl: PropTypes.string.isRequired,
  /* component description */
  description: PropTypes.string.isRequired,
  /* description for popular destinations button group */
  popularDestinationsDescription: PropTypes.string.isRequired,
  /* array of popular destinations */
  popularDestinations: PropTypes.array.isRequired
};

RoamingRatesSearchTool.defaultProps = {
  maxNumSuggestions: 10,
  inputFieldPlaceholder: 'Destination',
  errorMessage: 'Sorry, we couldn\'t find a match. Please try again.'
};

export default RoamingRatesSearchTool;
