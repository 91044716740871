import React, { Component } from 'react';
import {
  Column,
  Row,
  Button
} from '@lux/components';
import htmlStringToReactComponent from '@lux/components/src/helpers/htmlStringToReactComponent';
import SC from './styles';
import PropTypes from 'prop-types';

class PromotionTile extends Component {
  render() {

    const {
      isStarDeal,
      promotionImageURL,
      promotionImageAltText,
      promotionTitle,
      promotionPricePlan,
      promotionDescription,
      ctaUrl,
      ctaText,
      newTab,
      ribbonText
    } = this.props;

    return (
      <SC.PromotionTileWrapper>
        {
          isStarDeal
            ? <SC.Stardeal>{ribbonText}</SC.Stardeal>
            : <React.Fragment/>
        }
        <SC.PromotionTile>
          <Row>
            <Column sm={12} md={3} lg={3}>
              <SC.PromotionTileImage>
                <img src={promotionImageURL} alt={promotionImageAltText}/>
              </SC.PromotionTileImage>
            </Column>
            <Column sm={12} md={9} lg={9}>
              <SC.PromotionTileBodyWrapper>
                <SC.PromotionTileBody>
                  <SC.PromotionTitle>{promotionTitle}</SC.PromotionTitle>
                  <SC.PromotionPricePlan>{promotionPricePlan}</SC.PromotionPricePlan>
                  <p>{ htmlStringToReactComponent(promotionDescription) }</p>
                  <SC.PromotionCTAWrapper>
                    <Button
                      href={ctaUrl}
                      target={newTab === true ? '_blank' : ''}
                    >
                      {ctaText}
                    </Button>
                  </SC.PromotionCTAWrapper>
                </SC.PromotionTileBody>
              </SC.PromotionTileBodyWrapper>
            </Column>
          </Row>
        </SC.PromotionTile>
      </SC.PromotionTileWrapper>
    );
  }
}

PromotionTile.propTypes = {
  isStarDeal: PropTypes.bool,
  promotionImageURL: PropTypes.string,
  promotionImageAltText: PropTypes.string,
  promotionTitle: PropTypes.string,
  promotionPricePlan: PropTypes.string,
  promotionDescription: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  newTab: PropTypes.bool,
  ribbonText: PropTypes.string
};

export default PromotionTile;
