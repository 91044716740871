import React, { Component } from 'react';
import {
  Column,
} from '@lux/components';

import RoamingRatesCard from '../RoamingRatesCard';
import { isNarrowViewport } from '@lux/components/src/helpers';
import Equalizer from 'react-equalizer';
import PropTypes from 'prop-types';

class RoamingRatesCardList extends Component {

  refCards = [];

  enableEqualizer() {
    return !isNarrowViewport();
  }

  getCardNodes() {
    return this.refCards.filter(card => { return card }).map(card => { return card.refCard });
  }

  getCardTitleNodes() {
    return this.refCards.filter(card => { return card }).map(card => { return card.refTitle });
  }

  render() {
    const { plans } = this.props;

    return (
      plans.map((plan, index) => {
        return (
            <Column key={index} sm={12} md={6} lg={4}>
              <Equalizer
                enabled={ this.enableEqualizer }
                nodes={ this.getCardTitleNodes.bind(this) }
              >
                <Equalizer
                  enabled={ this.enableEqualizer }
                  nodes={ this.getCardNodes.bind(this) }
                >
                  <RoamingRatesCard
                    ref={ node => (this.refCards[index] = node) }
                    bundledValue={plan.bundledValue}
                    excessUsage={plan.excessUsage}
                    oneTimeRegistration={plan.oneTimeRegistration}
                    planName={plan.planName}
                    serviceCoverage={plan.serviceCoverage}
                    subscription={plan.subscription}
                    subscriptionFrequency={plan.subscriptionFrequency}
                    instructions={plan.instructions}
                    ctaUrl={plan.ctaUrl}
                    ctaText={plan.ctaText}
                  />
                </Equalizer>
              </Equalizer>
            </Column>
        );
      })
    );
  }
}

RoamingRatesCardList.defaultProps = {
  plans: []
}

RoamingRatesCardList.propTypes = {
  /* an array of country roaming rates */
  plans: PropTypes.array,
};

export default RoamingRatesCardList;
