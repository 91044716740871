import React, { Component } from "react";
import PropTypes from 'prop-types';
import GoogleMapsInfoCard from '../GoogleMapsInfoCard';
import SC from './styles';
import IconChevronUp from '../../../../../assets/svgs/chevron-up.svg';
import IconChevronDown from '../../../../../assets/svgs/chevron-down.svg';

class GoogleMapsListView extends Component {
  state = {
    activeIndex: -1,
    regions: []
  };

  componentDidUpdate(prevProps) {
    const {
      listViewData,
    } = this.props;

    if(prevProps.listViewData !== listViewData) {

      let map = new Map();

      listViewData.offices.map(office => {
          map.set(office.regionId, {
            regionName: office.regionName,
            regionId: office.regionId,
            offices: listViewData.offices.filter(o => { return o.regionId === office.regionId })
          })
      });

      const regions = Array.from(map.values());

      this.setState({
        regions
      })
    }
  }

  toggleIndex = index => {
    const { activeIndex } = this.state;
    const newIndex = index === activeIndex ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex, regions } = this.state;

    return (
      <SC.ListViewWrapper>
        {
          regions.map((region, index) => {
              return (
                <SC.OfficeListWrapper key={index}>

                  <SC.AccordionHeader onClick={() => this.toggleIndex(region.regionId)}>
                    {region.regionName}
                    {
                      activeIndex === region.regionId
                      ? <IconChevronUp />
                      : <IconChevronDown />
                    }
                  </SC.AccordionHeader>
                  {
                    activeIndex === region.regionId
                    ? <SC.AccordionPanel>
                      {
                        region.offices.map((office, index) => {
                          return (
                            <GoogleMapsInfoCard key={index}
                              officeName={office.officeName}
                              address={office.address}
                              tel={office.tel}
                              fax={office.fax}
                              isListViewActive={activeIndex === region.regionId}
                              isActive={true}
                              contactUsUrl={this.props.contactUsUrl}
                              toggleLightBox={this.props.toggleLightBox}
                            />
                          )
                        })
                      }
                    </SC.AccordionPanel>
                    : null
                  }
                </SC.OfficeListWrapper>
              )
          })
        }
      </SC.ListViewWrapper>
    );
  }
}

export default GoogleMapsListView;

GoogleMapsListView.propTypes = {
  /* object that contains an array of offices */
  listViewData: PropTypes.shape({
    /* an array of offices */
    offices: PropTypes.arrayOf(
      PropTypes.shape({
        /* Office ID */
        officeId: PropTypes.string,
        /* Office name */
        officeName: PropTypes.string,
        /* Office latitude */
        lat: PropTypes.string,
        /* Office longitude */
        lng: PropTypes.string,
        /* Region ID e.g. SEA */
        regionId: PropTypes.string,
        /* Region name e.g. Asia */
        regionName: PropTypes.string,
        /* Country ID e.g. SG */
        countryId: PropTypes.string,
        /* Country name e.g. Singapore */
        countryName: PropTypes.string,
        /* Office address */
        address: PropTypes.array,
        /* Office telephone number */
        tel: PropTypes.string,
        /* Office fax number */
        fax: PropTypes.string
      })
    )
  }),
  /* Endpoint for 'contact us' button */
  contactUsUrl: PropTypes.string
}
