import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@lux/components';

import SC from './styles';

class RelatedProductCard extends Component {

  render() {
    return (
      <Anchor
          href={this.props.ctaUrl}
          target={(this.props.newTab === true ? '_blank' : '')}
      >
        <SC.CatalogueCard className={this.props.className}>
          <SC.Card>
            <SC.ImageContainer>
              <SC.Image src={this.props.image} alt={this.props.imageAlt} />
            </SC.ImageContainer>
            <SC.Content>
              <SC.Title innerRef={node => { (this.refTitle = node)}}>
                {this.props.title}
              </SC.Title>

              <SC.Description innerRef={node => { (this.refDescription = node)}}>
                {this.props.description}
              </SC.Description>
            </SC.Content>
            <SC.CTA>
              <span>
                {this.props.ctaText}
              </span>
            </SC.CTA>
          </SC.Card>
        </SC.CatalogueCard>
      </Anchor>
    );
  }
}

RelatedProductCard.defaultProps = {
  className: '',
  ctaType: 'anchor'
};

RelatedProductCard.propTypes = {
  /** Class name */
  className: PropTypes.node,
  /** Image Path */
  image: PropTypes.string,
  /** Img Alt text */
  imageAlt: PropTypes.string,
  /** Title text */
  title: PropTypes.string,
  /** CTA Secondary Style */
  ctaSecondary: PropTypes.bool,
  /** CTA Url */
  ctaUrl: PropTypes.string,
  /** CTA Text */
  ctaText: PropTypes.string,
  /** Open in new tab */
  newTab: PropTypes.bool
};

export default RelatedProductCard;
