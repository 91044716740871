import React, { Component } from 'react';
import { Grid, Column, Row, Anchor } from '@lux/components';

import PropTypes from 'prop-types';
import SC from './styles';

class TestimonialWidget extends Component {
  render() {
    const {
        testimonialText,
        organisationName,
        testimonialAuthor,
        testimonialDate,
        ctaText,
        ctaUrl,
        logo,
        altText,
        theme
     } = this.props;

    return (
      <Grid>
        <Row>
          <Column sm={12}>
            <SC.TestimonialWrapper className={theme}>
              <SC.Quotes />
              <SC.TestimonialBody>
                <SC.TestimonialBodyText>
                  {testimonialText}
                </SC.TestimonialBodyText>
              </SC.TestimonialBody>
              <SC.TestimonialFooter>
                <SC.TestimonialFooterTextWrapper>
                  <p>
                    {organisationName} {organisationName && testimonialAuthor ? ' | ' : ''}
                    <span>{testimonialAuthor}</span>
                  </p>
                  <p>
                    {testimonialDate}
                    {testimonialDate && ctaText && ctaUrl ? ` | ` : ''}
                    <Anchor href={ctaUrl}>
                      {ctaText}
                    </Anchor>
                  </p>
                </SC.TestimonialFooterTextWrapper>
                <SC.TestimonialLogo
                  src={logo}
                  alt={altText}
                />
                <SC.QuotesInverted />
              </SC.TestimonialFooter>
            </SC.TestimonialWrapper>
          </Column>
        </Row>
      </Grid>
    );
  }
}

TestimonialWidget.defaultProps = {
  textAlign: "left",
  theme: "light"
};

TestimonialWidget.propTypes = {
  /* testimonial copy */
  testimonialText: PropTypes.string.isRequired,
  /* author of testimonial */
  testimonialAuthor: PropTypes.string.isRequired,
  /* organisation from which the testimonial author belongs to */
  organisationName: PropTypes.string,
  /* text alignment direction */
  textAlign: PropTypes.string,
  /* date testimonial was published */
  testimonialDate: PropTypes.string,
  /* CTA text */
  ctaText: PropTypes.string,
  /* url destination */
  ctaUrl: PropTypes.string,
  /* organisation logo */
  logo: PropTypes.string,
  /* image alt text */
  altText: PropTypes.string,
  /* determines if dark or light font is used */
  theme: PropTypes.string
};

export default TestimonialWidget;
