import styled from 'styled-components';
import {
  Theme
} from '@lux/components';
import { remCalc } from '@lux/helpers';

export default {
  FilterWrapper: styled.div`
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 70%;
  `,
  FilterButton: styled.a`
    width: 19%;
    background: ${Theme.colours.white};
    border: 1px solid ${Theme.colours.grey_c6};
    border-radius: ${remCalc(2.5)};
    box-sizing: border-box;
    font-family: ${Theme.fonts.families.LatoRegular};
    font-size: ${remCalc(16)};
    color: ${Theme.colours.black};
    padding: ${remCalc(8)} 0;
    height: ${remCalc(40)};

    &:hover {
      color: ${Theme.colours.white};
      background: ${Theme.colours.grey_c6};
      border: ${Theme.colours.grey_c6};
    }
  `,
  FilterControls: styled.div`
    margin: ${remCalc(30)} 0;
    display: none;

    ${Theme.media.md`
      display: block;
    `};
  `
}
