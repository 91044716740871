import React, { Component } from 'react';
import { Grid } from '@lux/components';
import PropTypes from 'prop-types';
import SC from './styles';
import mapOptions from "./GoogleMapsOptions.json";
import MarkerClusterer from '@google/markerclustererplus';
import GoogleMapFilters from './src/components/GoogleMapsFilters';
import GoogleMapsInfoCard from './src/components/GoogleMapsInfoCard';
import GoogleMapsListView from './src/components/GoogleMapsListView';
import GoogleMapsOfficeListWindow from './src/components/GoogleMapsOfficeListWindow';
import LightBox from './src/components/LightBox';

class GlobalMaps extends Component {

  state = {
    isMapVisible: true,
    activeRegion: null,
    activeCountry: null,
    activeOffice: null,
    activeOffices: [],
    regions: [],
    countries: [],
    map: undefined,
    clusterer: undefined,
    isLightBoxOpen: false
  };

  constructor(props) {
    super(props);

    const script = document.createElement('script');
    this.setGoogleMap();
    script.id = "gmaps";
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      this.props.apiKey
    }&callback=initMap_6ad6e147deac494bdabf8499`;
    script.type = 'text/javascript';

    if(document.head && !document.getElementById('gmaps')) {
      document.head.appendChild(script);
    }

    this.fetchMapData();
  }

  fetchMapData = () => {
    const { dataSourceUrl } = this.props;

    fetch(dataSourceUrl)
      .then(response => { return response.json()})
      .then(sourceData =>
        {
          return (
            this.setState({
              activeOffices: sourceData.offices,
              sourceData: sourceData,
              regions: Array.from(new Set(sourceData.offices.map(office => { return office.regionId })))
                .map(regionId => {
                  return {
                    regionId: regionId,
                    regionName: sourceData.offices.find(office => { return office.regionId === regionId }).regionName
                  }
                }),
              countries: Array.from(new Set(sourceData.offices.map(office => { return office.countryId })))
                .map(countryId => {
                  return {
                    value: countryId,
                    text: sourceData.offices.find(office => { return office.countryId === countryId }).countryName
                  }
                })

            })
          )
        }
      )
  };

  setGoogleMap = () => {
    window.initMap_6ad6e147deac494bdabf8499 = () => {

      const google = window.google;

      const map = new google.maps.Map(
        document.getElementById('map'),
        mapOptions
      );

      const clusterer = new MarkerClusterer(map, [], {
        ignoreHidden: false,
        maxZoom: 15,
        styles: [{
          url: require('../../assets/images/icon-cluster.png'),
          width: 40,
          height: 56,
          anchorIcon: [56, 20],
          anchorText: [-8, 0],
          textSize: 14
        }]
      });

      this.setState({
        map,
        clusterer,
      });

      window.initMap_6ad6e147deac494bdabf8499 = undefined;
    }
  };

  componentDidUpdate(prevProps, prevState) {

    const {
      map,
      activeOffices,
    } = this.state;

    if(prevState.map !== map) {
      this.createMarkerCluster();
    }

    if(prevState.activeOffices !== activeOffices) {
      this.createMarkerCluster();
    }
  }

  createMarkerCluster = () => {
    const { clusterer, map, activeOffices } = this.state;
    const google = window.google;

    this.clearMarkers();

    if(google) {
      const markers = activeOffices.map(office => {

        const marker = new google.maps.Marker({
          map: map,
          position: { lat: +office.lat, lng: +office.lng },
          icon: require('../../assets/images/icon-marker.png')
        });

        google.maps.event.addListener(marker, "click", this.handleMarkerClick.bind(this, { officeId: office.officeId, officeName: office.officeName }));

        return marker;
      });

      clusterer.addMarkers(markers);
      this.setBounds();
    }
  };

  clearMarkers = () => {
    const { clusterer } = this.state;

    if(clusterer) {
      clusterer.clearMarkers();
    }
  };

  onToggleMapView = () => {
    this.setState({
      isMapVisible: !(this.state.isMapVisible)
    });
  };

  setBounds = () => {
    const { map, clusterer } = this.state;

    const newBounds = clusterer.getMarkers().reduce((a, marker) => {
        return a.extend(marker.getPosition())
      }, new window.google.maps.LatLngBounds())

    map.fitBounds(newBounds, 80);
  };

  handleMarkerClick = office => {
    const { activeOffices, map } = this.state;
    const activeOffice = activeOffices.filter(o => { return o.officeId === office.officeId })[0];

    const google = window.google;
    const dataLayer = window.dataLayer;

    if(dataLayer) {
      dataLayer.push({
        event: 'global office map cta',
        location: office.officeName,
      })
    }

    map.setZoom(15);
    map.panTo(new google.maps.LatLng(activeOffice.lat, activeOffice.lng));

    this.setState({
      activeOffice
    })
  };

  onRegionSelect = regionId => {
    const { sourceData } = this.state;

    this.setState({
      activeOffices: sourceData.offices.filter(office => { return office.regionId === regionId }),
      activeOffice: null,
      activeRegion: regionId,
      activeCountry: null
    })
  };

  onCountrySelect = countryId => {
    const { sourceData } = this.state;

    this.setState({
      activeOffices: sourceData.offices.filter(office => { return office.countryId === countryId }),
      activeOffice: null,
      activeRegion: null,
      activeCountry: countryId
    })
  };

  onCardClose = () => {
    const { sourceData } = this.state;

    this.setState({
      activeOffices: sourceData.offices,
      activeOffice: null,
      activeRegion: null,
      activeCountry: null
    });

    this.setBounds();
  };

  toggleLightBox = () => {
    this.setState(prevState => ({
      isLightBoxOpen: !prevState.isLightBoxOpen
    }));
  };

  render() {
    const {
      isMapVisible,
      sourceData,
      activeCountry,
      activeOffice,
      activeOffices,
      regions,
      countries,
      isLightBoxOpen
    } = this.state;

    return (
      <Grid>
        <GoogleMapFilters
          onCountrySelect={this.onCountrySelect}
          onRegionSelect={this.onRegionSelect}
          regions={regions}
          countries={countries}
        />

        <SC.MapWrapper>

          {
            activeCountry
            ? <SC.MapListToggle
                onClick={() => this.onToggleMapView() }
                >
                { isMapVisible ? 'List View' : 'Map View' }
              </SC.MapListToggle>
            : null
          }

          <SC.GoogleMapsWrapper>
            <div className="googleMap" id="map"></div>
          </SC.GoogleMapsWrapper>

          {
            activeOffice
            ? <GoogleMapsInfoCard
                contactUsUrl={this.props.contactUsUrl}
                address={activeOffice['address']}
                contact={activeOffice['contact']}
                officeName={activeOffice['officeName']}
                tel={activeOffice['tel']}
                fax={activeOffice['fax']}
                isActive={true}
                toggleLightBox={this.toggleLightBox}
                onClose={this.onCardClose}
              />
            : null
          }

          {
            activeCountry && !isMapVisible
            ? <GoogleMapsOfficeListWindow
                countryName={activeOffices[0]['countryName']}
                activeOffices={activeOffices}
                contactUsUrl={this.props.contactUsUrl}
                toggleLightBox={this.toggleLightBox}
              />
            : null
          }

          <GoogleMapsListView
            listViewData={sourceData}
            contactUsUrl={this.props.contactUsUrl}
            toggleLightBox={this.toggleLightBox}
          />

        </SC.MapWrapper>

       <LightBox
          iframeSrc={this.props.contactUsUrl}
          toggleLightBox={this.toggleLightBox}
          open={isLightBoxOpen}
        />

      </Grid>
    );
  }
}

GlobalMaps.propTypes = {
  /* API key for Google Maps */
  apiKey: PropTypes.string,
  /* Endpoint for retrieving office data */
  dataSourceUrl: PropTypes.string,
  /* URL for contact us button */
  contactUsUrl: PropTypes.string,
};

export default GlobalMaps;
