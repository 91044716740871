import React, { Component } from 'react';
import SC from './styles';
import PropTypes from 'prop-types';
import { DropDown, Column, Row } from '@lux/components';

class GoogleMapsFilters extends Component {
  handleRegionSelect = regionId => {
    const { onRegionSelect } = this.props;
    onRegionSelect(regionId);
  };

  handleCountrySelect = country => {
    const { onCountrySelect } = this.props;
    onCountrySelect(country.value);
  };

  render() {
    const { countries, regions } = this.props;

    return (
      <SC.FilterControls>
        <Row>
          <Column sm={4}>
            <DropDown
              onChange={this.handleCountrySelect}
              items={countries}
              placeholder="Select Location..."
            />
          </Column>
          <Column sm={8}>
            <SC.FilterWrapper>
              {regions.map((region, index) => {
                return (
                  <SC.FilterButton
                    key={index}
                    onClick={() => this.handleRegionSelect(region.regionId)}
                  >
                    {region.regionName}
                  </SC.FilterButton>
                );
              })}
            </SC.FilterWrapper>
          </Column>
        </Row>
      </SC.FilterControls>
    );
  }
}

export default GoogleMapsFilters;

GoogleMapsFilters.propTypes = {
  /* Array of regions */
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      /* Region ID e.g. AUS */
      regionId: PropTypes.string,
      /* Region name e.g. Australia */
      regionName: PropTypes.string
    })
  ).isRequired,
  /* Array of countries */
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      /* Display name for country e.g. Australia */
      text: PropTypes.string,
      /* Value for country e.g. australia */
      value: PropTypes.string
    })
  ).isRequired,
  /* Function that defines component behaviour when region is selected */
  onRegionSelect: PropTypes.func.isRequired,
  /* Function that defines component behaviour when country is selected */
  onCountrySelect: PropTypes.func.isRequired
};
