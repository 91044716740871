export default {
  // Colours for Solutions Category
  red: '#B91929',
  orange: '#BD6600',
  blue: '#343F58',
  lightBlue: '#006B95',
  grey: '#555252',
  darkGrey: '#393E43',

  // Body color
  bodycopy: '#231F20'
};