import React, { Component } from 'react';
import SC from './styles';
import PropTypes from 'prop-types';
import {
  Button,
} from '@lux/components';

class RoamingRatesCard extends Component {

  render() {

    return (
      <SC.Card innerRef={node => (this.refCard = node)}>
        <SC.CardHeader innerRef={node => (this.refTitle = node)}>
          {this.props.planName}
        </SC.CardHeader>
        <SC.CardBody >
          <SC.Label>Data</SC.Label>
          <p>{this.props.bundledValue}</p>
          <SC.Label>Excess Usage</SC.Label>
          <p>{this.props.excessUsage}</p>
          <SC.Label>One-time registration fee</SC.Label>
          <p>{this.props.oneTimeRegistration}</p>
          <SC.Label>Service Coverage</SC.Label>
          <p>{this.props.serviceCoverage}</p>
          <SC.Label>Subscription</SC.Label>
          <SC.SubscriptionPrice>
            {this.props.subscription}
            <span>{this.props.subscriptionFrequency}</span>
          </SC.SubscriptionPrice>
          {
            this.props.ctaUrl || this.props.instructions
            ?
              <SC.ExtraDetails>
                {
                  this.props.ctaUrl
                    ? <Button href={this.props.ctaUrl}>{this.props.ctaText}</Button>
                    : null
                }
                {
                  this.props.instructions
                    ? <p>{this.props.instructions}</p>
                    : null
                }
              </SC.ExtraDetails>
            : null
          }
        </SC.CardBody>
      </SC.Card>
    );
  }
}

RoamingRatesCard.propTypes = {
  /* Plan name e.g. Enterprise EasyData Roam 137 */
  planName: PropTypes.string,
  /* Bundled value e.g. 500MB Daily (3G/LTE) */
  bundledValue: PropTypes.string,
  /* Excess usage e.g. Unlimited (2G) */
  excessUsage: PropTypes.string,
  /* One time registration fee */
  oneTimeRegistration: PropTypes.string,
  /* Service coverage e.g. Cable & Wireless */
  serviceCoverage: PropTypes.string,
  /* Subscription fee */
  subscription: PropTypes.string,
  /* CTA url */
  ctaUrl: PropTypes.string,
  /* Display text for CTA */
  ctaText: PropTypes.string,
  /* Instructions for activating plan */
  instructions: PropTypes.string,
  /* Subscription frequency e.g. per month, per flight, etc */
  subscriptionFrequency: PropTypes.string
};

export default RoamingRatesCard;
