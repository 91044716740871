import styled from 'styled-components';
import { Theme, Column, Row } from '@lux/components';

export default {
  DetailsWrapperRow: styled(Row)`
    background: ${Theme.colours.grey_f5};
    padding: ${Theme.spacing.small} 0;
  `,
  ProductsWrapper: styled(Row)`
    background: ${Theme.colours.white};
    padding: ${Theme.spacing.small};
  `,
  SelectedSection: styled(Row)`
    padding-bottom: ${Theme.spacing.xsmall};
  `,
  ErrorContainer: styled(Column)`
    padding: 0;
  `,
  LayoutContainer: styled.div`
    float: left;
  `,
  Title: styled.div`
    font-weight: bold;
    float: left;
  `,
  Content: styled.div`
    padding-left: ${Theme.spacing.xsmall};
    float: left;
  `
};
