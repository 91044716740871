import React, { Component } from "react";
import GoogleMapsInfoCard from "../GoogleMapsInfoCard";
import SC from "./styles"
import PropTypes from 'prop-types';

class GoogleMapsOfficeListWindow extends Component {

  render() {
    const {
      activeOffices,
      countryName,
      onClose
    } = this.props;

    return (
        <SC.OfficeList>
          <h3>{countryName}</h3>
            {
              activeOffices.map((office, index) => {

                return (
                  <GoogleMapsInfoCard key={index}
                    officeName={office.officeName}
                    address={office.address}
                    contact={office.contact}
                    tel={office.tel}
                    fax={office.fax}
                    isOfficeListViewActive={true}
                    isActive={true}
                    onClose={onClose}
                    toggleLightBox={this.props.toggleLightBox}
                  />
                )
              })
            }
        </SC.OfficeList>
    );
  }

}

GoogleMapsOfficeListWindow.propTypes = {
  countryName: PropTypes.string,
  activeOffices: PropTypes.arrayOf(
    PropTypes.shape({
      officeName: PropTypes.string,
      address: PropTypes.array,
      contact: PropTypes.object,
      tel: PropTypes.string,
      fax: PropTypes.string
    })
  ),
  onClose: PropTypes.func
}

export default GoogleMapsOfficeListWindow;
