import React, { Component } from 'react';
import { Row } from '@lux/components';

import PropTypes from 'prop-types';
import SC from './styles';

class SolutionsCategories extends Component {

  render() {
    const {
      showBackgroundImage,
      productList
    } = this.props;

    return (
      <SC.SolutionsCategoryContainer>
        <Row>
          <SC.SolutionsCategoryWrapper>
            {
              productList.map((productData, index) => {
                return (
                  <SC.SolutionsCategoryColumn
                    href={productData.linkURL}
                    key={index}
                    className={productData.backgroundColor + (showBackgroundImage === true ? ' showImage' : '')}
                    style={(showBackgroundImage === true ? {backgroundImage: 'url(' + productData.backgroundImage + ')'} : {})}
                  >
                    <SC.SolutionsCategoryContent>
                      {showBackgroundImage !== true && (
                        <SC.Image
                          src={productData.image}
                          alt={productData.imageAlt}
                        />
                      )}
                      <SC.SolutionsCategoryTitle>{productData.title}</SC.SolutionsCategoryTitle>
                      <SC.SolutionsCategoryDescription>{productData.description}</SC.SolutionsCategoryDescription>
                      {showBackgroundImage === true && (
                        <SC.Image
                          src={productData.image}
                          alt={productData.imageAlt}
                        />
                      )}
                    </SC.SolutionsCategoryContent>
                  </SC.SolutionsCategoryColumn>
                );
              })
            }
          </SC.SolutionsCategoryWrapper>
        </Row>
      </SC.SolutionsCategoryContainer>
    );
  }
}

SolutionsCategories.propTypes = {
  /* Flag to enable background image */
  showBackgroundImage: PropTypes.bool,
  /*
    Array of products that contains the following:
    title, description, link, image icon, image icon alt and background image 
  */
  productList: PropTypes.array
};

export default SolutionsCategories;
